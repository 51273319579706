import React, { useState } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';

function AddProjectModal({ open, onClose, onAdd }) {
  const [projectName, setProjectName] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openSnackbar, setOpenSnackbar] = useState(false); // Ensure this is defined

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!projectName.trim()) {
      setSnackbarMessage('Project name cannot be empty');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/project/category`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ project_category_name: projectName }),
      });

      if (response.ok) {
        setSnackbarMessage('Project added successfully');
        setSnackbarSeverity('success');
        onAdd(); // Notify parent to refresh data
        setProjectName(''); // Reset project name
        onClose(); // Close the modal
      } else {
        setSnackbarMessage('Failed to add project');
        setSnackbarSeverity('error');
      }
      setOpenSnackbar(true); // Make sure this is used to show the Snackbar
    } catch (error) {
      setSnackbarMessage('Error occurred');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false); // Ensure Snackbar closes properly
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Project Category</DialogTitle>
      <DialogContent>
        <form onSubmit={handleFormSubmit}>
          <TextField
            label="Project Category Name"
            value={projectName}
            onChange={handleProjectNameChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
            autoFocus
          />
          <Box display="flex" justifyContent="center" marginTop={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Add Project
              </Button>
            )}
          </Box>
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          action={
            <Button color="inherit" onClick={handleSnackbarClose}>
              Close
            </Button>
          }
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddProjectModal;
