import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const EditCompanyNewsPage = () => {
  const { id } = useParams(); // Get the company news ID from the URL params
  const [formData, setFormData] = useState({
    company_news_title: '',
    company_news_description: '',
    company_news_image: null, // Initialize with null for the image
    author_image: null,
    author_name: '',
    company_news_coordinator: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [authorImagePreview, setAuthorImagePreview] = useState('');
  const navigate = useNavigate();

  // Fetch company news details when the component mounts
  useEffect(() => {
    const fetchCompanyNews = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/company_news/${id}`);
        const newsData = response.data.data;
        setFormData({
          company_news_title: newsData.company_news_title,
          company_news_description: newsData.company_news_description,
          company_news_coordinator: newsData.company_news_coordinator,
          company_news_image: newsData.company_news_image, // Keep the existing image URL
          author_image: newsData.author_image,
          author_name: newsData.author_name,
        });
        setImagePreview(newsData.company_news_image); // Set the initial image preview
        setAuthorImagePreview(newsData.author_image); // Set the initial author image preview
      } catch (err) {
        setError('Error fetching company news details');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyNews();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file') {
      if (name === 'company_news_image') {
        setFormData((prevData) => ({
          ...prevData,
          company_news_image: files[0], // Store the file object
        }));
        setImagePreview(URL.createObjectURL(files[0])); // Set the image preview
      } else if (name === 'author_image') {
        setFormData((prevData) => ({
          ...prevData,
          author_image: files[0], // Store the author image file object
        }));
        setAuthorImagePreview(URL.createObjectURL(files[0])); // Set the author image preview
      }
    } else {
      setFormData({
        ...formData,
        [name]: value, // Update form data for text fields
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    for (const key in formData) {
      if (key === 'company_news_image' && formData.company_news_image instanceof File) {
        data.append(key, formData.company_news_image);
      } else if (key === 'author_image' && formData.author_image instanceof File) {
        data.append(key, formData.author_image);
      } else {
        data.append(key, formData[key]);
      }
    }

    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/company_news/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      navigate('/company_news_list'); // Redirect to the company news list page after update
    } catch (err) {
      setError('Error updating company news');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setFormData({
      ...formData,
      company_news_image: null, // Reset to null if removed
    });
  };

  const handleRemoveAuthorImage = () => {
    setAuthorImagePreview(null);
    setFormData({
      ...formData,
      author_image: null, // Reset to null if removed
    });
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Edit Company News 
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            name="company_news_title"
            label="News Title"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.company_news_title} // Populate with existing title
            onChange={handleChange}
            required
          />
          <TextField
            name="company_news_description"
            label="News Description"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={formData.company_news_description}
            onChange={handleChange}
            required
          />
          <input
            name="company_news_image"
            type="file"
            accept="image/*"
            onChange={handleChange}
          />
          {imagePreview && (
            <Box mt={2} position="relative">
              <img
                src={imagePreview}
                alt="Preview"
                style={{ width: '200px', height: 'auto', borderRadius: '4px' }}
              />
              <IconButton
                onClick={handleRemoveImage}
                style={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          {/* <input
            name="author_image"
            type="file"
            accept="image/*"
            onChange={handleChange}
          /> */}
          {/* {authorImagePreview && (
            <Box mt={2} position="relative">
              <img
                src={authorImagePreview}
                alt="Author Preview"
                style={{ width: '200px', height: 'auto', borderRadius: '4px' }}
              />
              <IconButton
                onClick={handleRemoveAuthorImage}
                style={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )} */}
          <TextField
            name="company_news_coordinator"
            label="Coordinator"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.company_news_coordinator} // Populate with existing coordinator
            onChange={handleChange}
            required
          />
          {/* <TextField
            name="author_name"
            label="Author Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.author_name} // Populate with existing author name
            onChange={handleChange}
            required
          /> */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Update Company News'}
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      )}
    </div>
  );
};

export default EditCompanyNewsPage;
