import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const saveContent = async (contentSections, formData) => {
  const formDataToSend = new FormData();

  // Append form data
  Object.keys(formData).forEach((key) => {
    formDataToSend.append(key, formData[key]);
  });

  // Append PDF files
  contentSections.forEach((section) => {
    if (section.pdfFiles && section.pdfFiles.length > 0) {
      section.pdfFiles.forEach((file) => {
        formDataToSend.append("pdfFiles", file);
      });
    }
  });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND}/upload/save`, // Make sure the URL matches your backend endpoint
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log("Success:", response.data);
  } catch (error) {
    console.error("Error:", error);
  }
};

const DynamicContentForm = () => {
  const [contentSections, setContentSections] = useState([]);
  const [schoolCategories, setSchoolCategories] = useState([]);
  const [schoolSubcategories, setSchoolSubcategories] = useState([]);
  const [grades, setGrades] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [formData, setFormData] = useState({
    schoolCategory: "",
    schoolSubcategory: "",
    grade: "",
    course: "",
    chapter: "",
    unitName: "", // Added unitName
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate(); // Assuming navigation is used in your component

  // Fetch school categories
  useEffect(() => {
    const fetchSchoolCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school_category`);
        setSchoolCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching school categories:", error);
      }
    };
    
    fetchSchoolCategories();
  }, []);

  // Fetch school subcategories
  useEffect(() => {
    const fetchSchoolSubcategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sub_school_category`);
        setSchoolSubcategories(response.data.data);
      } catch (error) {
        console.error("Error fetching school subcategories:", error);
      }
    };
    
    fetchSchoolSubcategories();
  }, []);

  // Fetch grades
  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/grade`);
        setGrades(response.data.data);
      } catch (error) {
        console.error("Error fetching grades:", error);
      }
    };
    
    fetchGrades();
  }, []);

  // Fetch courses
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses`);
        setCourseList(response.data.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    
    fetchCourses();
  }, []);

  // Set loading state to false when all fetches are done
  useEffect(() => {
    setIsLoading(false);
  }, [schoolCategories, schoolSubcategories, grades, courseList]);

  useEffect(() => {
    if (formData.schoolCategory) {
      const filtered = schoolSubcategories.filter(
        (subcategory) =>
          subcategory.School_category_id === formData.schoolCategory
      );
      setFilteredSubcategories(filtered);
    } else {
      setFilteredSubcategories([]);
    }
  }, [formData.schoolCategory, schoolSubcategories]);

  useEffect(() => {
    if (formData.course) {
      const fetchChapters = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND}/api/chapters`,
            {
              params: { courseId: formData.course },
            }
          );
          setChapters(response.data.data || []);
        } catch (error) {
          console.error("Error fetching chapters:", error);
        }
      };

      fetchChapters();
    } else {
      setChapters([]);
    }
  }, [formData.course]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleContentTypeChange = (event) => {
    const { value } = event.target;

    if (value === "pdf") {
      setContentSections((prevSections) => [
        ...prevSections,
        { type: value, id: new Date().getTime(), pdfFiles: [] },
      ]);
    }
  };

  const handlePdfUploadChange = (index, event) => {
    const files = Array.from(event.target.files);
    const updatedSections = [...contentSections];
    updatedSections[index].pdfFiles = files;
    setContentSections(updatedSections);
  };

  const handleSave = async (event) => {
    console.log("handle login");
    
    event.preventDefault(); // Prevent default form submission behavior
    setIsSaving(true); // Set saving state to true
  
    try {
      await saveContent(contentSections, formData);
      // Optionally navigate to another page or provide user feedback
      navigate('/unitlist'); // Example navigation; adjust as needed
    } catch (error) {
      console.error("Error saving content:", error);
    } finally {
      setIsSaving(false); // Set saving state back to false
    }
  };

  return (
    <Box padding={2}>
      <Typography variant="h4" gutterBottom>
        Add New Units
      </Typography>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Unit Name"
            variant="outlined"
            fullWidth
            margin="normal"
            name="unitName"
            value={formData.unitName}
            onChange={handleInputChange}
            required
          />

          <TextField
            label="School Category"
            variant="outlined"
            fullWidth
            margin="normal"
            name="schoolCategory"
            select
            value={formData.schoolCategory}
            onChange={handleInputChange}
            required
          >
            {schoolCategories.length > 0 ? (
              schoolCategories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.school_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                No School Categories
              </MenuItem>
            )}
          </TextField>

          <TextField
            label="School Subcategory"
            variant="outlined"
            fullWidth
            margin="normal"
            name="schoolSubcategory"
            select
            value={formData.schoolSubcategory}
            onChange={handleInputChange}
            required
          >
            {filteredSubcategories.length > 0 ? (
              filteredSubcategories.map((subcategory) => (
                <MenuItem key={subcategory._id} value={subcategory._id}>
                  {subcategory.SubCategory_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                No Subcategories
              </MenuItem>
            )}
          </TextField>

          <TextField
            label="Grade"
            variant="outlined"
            fullWidth
            margin="normal"
            name="grade"
            select
            value={formData.grade}
            onChange={handleInputChange}
            required
          >
            {grades.length > 0 ? (
              grades.map((grade) => (
                <MenuItem key={grade._id} value={grade._id}>
                  {grade.gradename}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                No Grades
              </MenuItem>
            )}
          </TextField>

          <TextField
            label="Course"
            variant="outlined"
            fullWidth
            margin="normal"
            name="course"
            select
            value={formData.course}
            onChange={handleInputChange}
            required
          >
            {courseList.length > 0 ? (
              courseList.map((course) => (
                <MenuItem key={course._id} value={course._id}>
                  {course.courses_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                No Courses
              </MenuItem>
            )}
          </TextField>

          <TextField
            label="Chapter"
            variant="outlined"
            fullWidth
            margin="normal"
            name="chapter"
            select
            value={formData.chapter}
            onChange={handleInputChange}
            required
          >
            {chapters.length > 0 ? (
              chapters.map((chapter) => (
                <MenuItem key={chapter._id} value={chapter._id}>
                  {chapter.chapter_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                No Chapters
              </MenuItem>
            )}
          </TextField>

     
        </Box>
      )}

      <Box>
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Choose content</InputLabel>
          <Select label="Choose content" onChange={handleContentTypeChange}>
            <MenuItem value="pdf">PDF</MenuItem>
          </Select>
        </FormControl>

        {contentSections.map((section, index) => {
          if (section.type === "pdf") {
            return (
              <Box key={section.id} margin="normal">
                <TextField
                  name="pdfFiles"
                  type="file"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: "application/pdf", multiple: true }}
                  onChange={(e) => handlePdfUploadChange(index, e)}
                  label={`Upload PDFs for section ${index + 1}`}
                />

              </Box>
            );
          }
          return null;
        })}

      </Box>
           {/* Button to save the form */}
           <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSaving}
            sx={{ mt: 2 }}
            onClick={handleSave}
          >
            {isSaving ? <CircularProgress size={24} /> : "Save"}
          </Button>
    </Box>
  );
};

export default DynamicContentForm;
