import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography,
  CircularProgress, Container, Box, IconButton, Switch, TextField, MenuItem, Grid,
  TablePagination
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios';

const DepartmentList = () => {
  const [departments, setDepartments] = useState([]);
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  const [departmentCategories, setDepartmentCategories] = useState([]);
  const [departmentCategorySubs, setDepartmentCategorySubs] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [departmentToDelete, setDepartmentToDelete] = useState(null);
  const [currentDepartment, setCurrentDepartment] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // Pagination States
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    fetchDepartments();
    fetchDepartmentCategories();
    fetchDepartmentCategorySubs();
  }, []);

  useEffect(() => {
    const filtered = departments.filter(dept =>
      dept?.department_name?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredDepartments(filtered);
  }, [searchQuery, departments]);

  useEffect(() => {
    setPage(0); // Reset to first page when filtered departments change
  }, [filteredDepartments]);

  // Fetch Departments
  const fetchDepartments = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department`);
      setDepartments(response.data.data || []);
    } catch (error) {
      console.error('Error fetching departments:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch Department Categories
  const fetchDepartmentCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department_category`);
      setDepartmentCategories(response.data.data || []);
    } catch (error) {
      console.error("Error fetching department categories", error);
    }
  };

  // Fetch Department Category Subs
  const fetchDepartmentCategorySubs = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department_category_sub`);
      setDepartmentCategorySubs(response.data.data || []);
    } catch (error) {
      console.error("Error fetching department category subs", error);
    }
  };

  // Filter subcategories based on selected category
  useEffect(() => {
    if (currentDepartment.department_category_id) {
      const filtered = departmentCategorySubs.filter(subcategory =>
        subcategory.department_category_id === currentDepartment.department_category_id
      );
      setFilteredSubcategories(filtered);
    } else {
      setFilteredSubcategories([]);
    }
  }, [currentDepartment.department_category_id, departmentCategorySubs]);

  // Handle Save or Update
  const handleSave = async () => {
    try {
      if (isEdit) {
        await axios.put(`${process.env.REACT_APP_BACKEND}/api/department/${currentDepartment._id}`, currentDepartment);
      } else {
        await axios.post(`${process.env.REACT_APP_BACKEND}/api/department`, currentDepartment);
      }
      fetchDepartments();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving department", error);
    }
  };

  // Delete Department
  const handleDelete = async () => {
    if (departmentToDelete) {
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND}/api/department/${departmentToDelete._id}`);
        fetchDepartments();
        handleCloseConfirmDialog();
      } catch (error) {
        console.error("Error deleting department", error);
      }
    }
  };

  // Toggle Status
  const handleToggleStatus = async (id, status) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/department/${id}`, { status: status === 1 ? 0 : 1 });
      fetchDepartments();
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  // Open Dialog for Add/Edit
  const handleOpenDialog = (department = {}) => {
    setCurrentDepartment(department);
    setIsEdit(!!department._id);
    setOpenDialog(true);
  };

  // Close Dialog
  const handleCloseDialog = () => {
    setCurrentDepartment({});
    setOpenDialog(false);
  };

  // Open Confirmation Dialog
  const handleOpenConfirmDialog = (department) => {
    setDepartmentToDelete(department);
    setConfirmDelete(true);
  };

  // Close Confirmation Dialog
  const handleCloseConfirmDialog = () => {
    setDepartmentToDelete(null);
    setConfirmDelete(false);
  };

  // Pagination Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  if (loading) return <CircularProgress />;

  if (error) return <Typography color="error">Error loading data: {error.message}</Typography>;

  // Calculate current page data
  const paginatedDepartments = filteredDepartments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Container>
      <Box display="flex" flexDirection="column" mb={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h4" gutterBottom>
            Department List
          </Typography>
          <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
            Add Department
          </Button>
        </Box>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ alignSelf: 'flex-end' }} // Aligns the search bar to the right
        />
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial Number</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Department Category</TableCell>
              <TableCell>Department Sub Category</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedDepartments.map((department, index) => (
              <TableRow key={department._id}>
                <TableCell>{page * rowsPerPage + index + 1}</TableCell> {/* Serial Number */}
                <TableCell>{department.department_name}</TableCell>
                <TableCell>{departmentCategories.find(cat => cat._id === department.department_category_id)?.department_category_name || 'N/A'}</TableCell>
                <TableCell>{departmentCategorySubs.find(sub => sub._id === department.department_sub_category_id)?.department_sub_category_name || 'N/A'}</TableCell>
                <TableCell>
                  <Switch
                    checked={department.status === 1}
                    onChange={() => handleToggleStatus(department._id, department.status)}
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenDialog(department)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleOpenConfirmDialog(department)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {totalItems > 5 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}

      {/* Dialog for Add/Edit */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{isEdit ? 'Edit Department' : 'Add Department'}</DialogTitle>
        <DialogContent>
        <TextField
            select
            label="Department Category"
            fullWidth
            margin="normal"
            value={currentDepartment.department_category_id || ''}
            onChange={(e) => {
              setCurrentDepartment({ ...currentDepartment, department_category_id: e.target.value });
              setFilteredSubcategories(
                departmentCategorySubs.filter(sub =>
                  sub.department_category_id === e.target.value
                )
              );
            }}
          >
            {departmentCategories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.department_category_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Department Subcategory"
            fullWidth
            margin="normal"
            value={currentDepartment.department_sub_category_id || ''}
            onChange={(e) =>
              setCurrentDepartment({ ...currentDepartment, department_sub_category_id: e.target.value })
            }
          >
            {filteredSubcategories.map((sub) => (
              <MenuItem key={sub._id} value={sub._id}>
                {sub.department_sub_category_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Department Name"
            fullWidth
            margin="normal"
            value={currentDepartment.department_name || ''}
            onChange={(e) =>
              setCurrentDepartment({ ...currentDepartment, department_name: e.target.value })
            }
          />
     
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            {isEdit ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog for Deletion */}
      <Dialog open={confirmDelete} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this department?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default DepartmentList;
