import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Container,
  CircularProgress,
  Alert,
  IconButton
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/AuthUtils';
import { PhotoCamera } from '@mui/icons-material'; // For the file upload icon

const AddSchoolMain = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    School_id: '',
    school_code: '',
    school_category: '',
    school_sub_category: '',
    countryId: '',
    stateId: '',
    districtId: '',
    townId: '',
    place: '',
    school_official_id: '',
    school_name: '',
    school_address: '',
    school_pin_code: '',
    school_website: '',
    school_email: '',
    principal_name: '',
    principal_contact_no: '',
    principal_email: '',
    principal_photo: '',
    admin_name: '',
    admin_contact_no: '',
    admin_email: '',
    total_number_of_students: '',
    robotics_lab_location: '',
    Rob_lab_coordinator: '',
    rob_lab_coordntr_contact_no: '',
    rob_lab_coordntr_whatsapp_no: '',
    rob_lab_coordntr_email: '',
    school_logo: '',
    social_media_linkedin: '',
    social_media_instagram: '',
    social_media_facebook: '',
    status: 1,
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [schoolCategories, setSchoolCategories] = useState([]);
  const [schoolSubCategories, setSchoolSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [principalPhoto, setPrincipalPhoto] = useState(null);
  const [schoolLogo, setSchoolLogo] = useState(null);
  const [principalPhotoPreview, setPrincipalPhotoPreview] = useState('');
  const [schoolLogoPreview, setSchoolLogoPreview] = useState('');

  useEffect(() => {
    fetchCountries();
    fetchSchoolCategories();
  }, []);

  useEffect(() => {
    if (formData.countryId) {
      fetchStates(formData.countryId);
    } else {
      setStates([]);
      setFormData(prev => ({ ...prev, stateId: '', districtId: '', townId: '' }));
    }
  }, [formData.countryId]);

  useEffect(() => {
    if (formData.stateId) {
      fetchDistricts(formData.stateId);
    } else {
      setDistricts([]);
      setFormData(prev => ({ ...prev, districtId: '', townId: '' }));
    }
  }, [formData.stateId]);

  useEffect(() => {
    if (formData.districtId) {
      fetchTowns(formData.districtId);
    } else {
      setTowns([]);
      setFormData(prev => ({ ...prev, townId: '' }));
    }
  }, [formData.districtId]);

  useEffect(() => {
    if (formData.school_category) {
      const filtered = schoolSubCategories.filter(subcategory => 
        subcategory.School_category_id === formData.school_category
      );
      setFilteredSubCategories(filtered);
    } else {
      setFilteredSubCategories([]);
    }
  }, [formData.school_category, schoolSubCategories]);

  useEffect(() => {
    if (principalPhoto) {
      const objectUrl = URL.createObjectURL(principalPhoto);
      setPrincipalPhotoPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [principalPhoto]);

  useEffect(() => {
    if (schoolLogo) {
      const objectUrl = URL.createObjectURL(schoolLogo);
      setSchoolLogoPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [schoolLogo]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country`);
      setCountries(response.data.data);
    } catch (err) {
      console.error('Failed to fetch countries:', err.response?.data?.message || err.message);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state/by-country`, {
        params: { countryId }
      });
      setStates(response.data.data);
    } catch (err) {
      console.error('Failed to fetch states:', err.response?.data?.message || err.message);
    }
  };

  const fetchDistricts = async (stateId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/district/by-state`, {
        params: { stateId }
      });
      setDistricts(response.data.data);
    } catch (err) {
      console.error('Failed to fetch districts:', err.response?.data?.message || err.message);
    }
  };

  const fetchTowns = async (districtId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/town/by-district`, {
        params: { districtId }
      });
      setTowns(response.data.data);
    } catch (err) {
      console.error('Failed to fetch towns:', err.response?.data?.message || err.message);
    }
  };

  const fetchSchoolCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school_category`);
      setSchoolCategories(response.data.data);
      fetchSchoolSubCategories(); // Fetch sub-categories here for initial population
    } catch (err) {
      console.error('Failed to fetch school categories:', err.response?.data?.message || err.message);
    }
  };

  const fetchSchoolSubCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sub_school_category`);
      setSchoolSubCategories(response.data.data);
    } catch (err) {
      console.error('Failed to fetch school sub-categories:', err.response?.data?.message || err.message);
    }
  };

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      if (name === 'principal_photo') {
        setPrincipalPhoto(file);
      } else if (name === 'school_logo') {
        setSchoolLogo(file);
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError('');
    try {
      const token = getToken();
      if (!token) {
        alert("Please Login");
        return;
      }

      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      if (principalPhoto) {
        formDataToSend.append('principal_photo', principalPhoto);
      }
      if (schoolLogo) {
        formDataToSend.append('school_logo', schoolLogo);
      }

      await axios.post(`${process.env.REACT_APP_BACKEND}/api/school`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        }
      });
      navigate('/school_list'); // Redirect to the school list page after adding
    } catch (err) {
      setError('Failed to add school. Please check your inputs and try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ width: "80%", marginLeft:"0px" }}>
      <Typography variant="h5" align="left" gutterBottom sx={{ backgroundColor: "#e4e1de", padding: "5px" }}>
        Add New School
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Box display="flex" flexDirection="column" gap={3}>
        <Grid container spacing={2}>
          {/* General Information */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              General Information
            </Typography>
            <Grid container spacing={2}>
              {/* School Code */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="School Short Name"
                  name="school_code"
                  value={formData.school_code}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {/* School Category */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                    name="school_category"
                    value={formData.school_category}
                    onChange={handleChange}
                    label="Category"
                  >
                    {schoolCategories.map((category) => (
                      <MenuItem key={category._id} value={category._id}>
                        {category.school_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* School Sub-Category */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Sub-Category</InputLabel>
                  <Select
                    name="school_sub_category"
                    value={formData.school_sub_category}
                    onChange={handleChange}
                    label="Sub-Category"
                  >
                    {filteredSubCategories.map((subCategory) => (
                      <MenuItem key={subCategory._id} value={subCategory._id}>
                        {subCategory.SubCategory_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* School Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="School Name"
                  name="school_name"
                  value={formData.school_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {/* School Address */}
              <Grid item xs={12}>
                <TextField
                  label="School Address"
                  name="school_address"
                  value={formData.school_address}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {/* School Pin Code */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="School Pin Code"
                  name="school_pin_code"
                  value={formData.school_pin_code}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                />
              </Grid>
              {/* School Website */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="School Website"
                  name="school_website"
                  value={formData.school_website}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {/* School Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="School Email"
                  name="school_email"
                  value={formData.school_email}
                  onChange={handleChange}
                  fullWidth
                  type="email"
                />
              </Grid>
              {/* Principal Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Principal Name"
                  name="principal_name"
                  value={formData.principal_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {/* Principal Contact No */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Principal Contact No"
                  name="principal_contact_no"
                  value={formData.principal_contact_no}
                  onChange={handleChange}
                  fullWidth
                  type="tel"
                />
              </Grid>
              {/* Principal Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Principal Email"
                  name="principal_email"
                  value={formData.principal_email}
                  onChange={handleChange}
                  fullWidth
                  type="email"
                />
              </Grid>
              {/* Principal Photo */}
              <Grid item xs={12} md={6}>
                <Button variant="contained" component="label">
                  Upload Principal Photo
                  <input
                    type="file"
                    name="principal_photo"
                    accept="image/*"
                    onChange={handleFileChange}
                    hidden
                  />
                </Button>
                {principalPhotoPreview && (
                  <Box mt={2}>
                    <img
                      src={principalPhotoPreview}
                      alt="Principal"
                      style={{ maxWidth: '100%', maxHeight: '150px' }}
                    />
                  </Box>
                )}
              </Grid>
              {/* Admin Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Admin Name"
                  name="admin_name"
                  value={formData.admin_name}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {/* Admin Contact No */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Admin Contact No"
                  name="admin_contact_no"
                  value={formData.admin_contact_no}
                  onChange={handleChange}
                  fullWidth
                  type="tel"
                />
              </Grid>
              {/* Admin Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Admin Email"
                  name="admin_email"
                  value={formData.admin_email}
                  onChange={handleChange}
                  fullWidth
                  type="email"
                />
              </Grid>
              {/* Total Number of Students */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Total Number of Students"
                  name="total_number_of_students"
                  value={formData.total_number_of_students}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                />
              </Grid>
              {/* School Logo */}
              <Grid item xs={12} md={6}>
                <Button variant="contained" component="label">
                  Upload School Logo
                  <input
                    type="file"
                    name="school_logo"
                    accept="image/*"
                    onChange={handleFileChange}
                    hidden
                  />
                </Button>
                {schoolLogoPreview && (
                  <Box mt={2}>
                    <img
                      src={schoolLogoPreview}
                      alt="School Logo"
                      style={{ maxWidth: '100%', maxHeight: '150px' }}
                    />
                  </Box>
                )}
              </Grid>
              {/* Social Media Links */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Social Media LinkedIn"
                  name="social_media_linkedin"
                  value={formData.social_media_linkedin}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Social Media Instagram"
                  name="social_media_instagram"
                  value={formData.social_media_instagram}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Social Media Facebook"
                  name="social_media_facebook"
                  value={formData.social_media_facebook}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Address Information */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Address Information
            </Typography>
            <Grid container spacing={2}>
              {/* Country */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="countryId"
                    value={formData.countryId}
                    onChange={handleChange}
                    label="Country"
                  >
                    {countries.map((country) => (
                      <MenuItem key={country._id} value={country._id}>
                        {country.country_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* State */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>State</InputLabel>
                  <Select
                    name="stateId"
                    value={formData.stateId}
                    onChange={handleChange}
                    label="State"
                  >
                    {states.map((state) => (
                      <MenuItem key={state._id} value={state._id}>
                        {state.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* District */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>District</InputLabel>
                  <Select
                    name="districtId"
                    value={formData.districtId}
                    onChange={handleChange}
                    label="District"
                  >
                    {districts.map((district) => (
                      <MenuItem key={district._id} value={district._id}>
                        {district.district_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Town */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Town</InputLabel>
                  <Select
                    name="townId"
                    value={formData.townId}
                    onChange={handleChange}
                    label="Town"
                  >
                    {towns.map((town) => (
                      <MenuItem key={town._id} value={town._id}>
                        {town.Town_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Place */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="Place"
                  name="place"
                  value={formData.place}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              {/* School Official ID */}
              {/* <Grid item xs={12} md={6}>
                <TextField
                  label="School Official ID"
                  name="school_official_id"
                  value={formData.school_official_id}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Box textAlign="center" mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Add School'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AddSchoolMain;
