import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AddSliderPage = () => {
  const [formData, setFormData] = useState({
    title: '',
    image_url: null,  // Store the file object
    redirected_url: '',  // Change to string to store the URL
    start_date: '',
    end_date: '',
    is_active: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'file') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: e.target.files[0],  // Store the file object
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    // Validation for date
    if (new Date(formData.start_date) > new Date(formData.end_date)) {
      setError("End date must be after start date.");
      setIsLoading(false);
      return;
    }

    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/slider`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      navigate('/slider_list'); // Redirect to the slider list page
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Error adding slider';
      setError(errorMessage);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box padding={2}>
      <Typography variant="h4">Add New Slider</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <input
          type="file"
          name="image_url"
          accept="image/*"
          onChange={handleChange}
          required
        />
        <TextField
          label="Redirected URL"
          name="redirected_url"
          value={formData.redirected_url}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Start Date"
          name="start_date"
          type="date"
          value={formData.start_date}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
        />
        <TextField
          label="End Date"
          name="end_date"
          type="date"
          value={formData.end_date}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
        />
     
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button type="submit" variant="contained" color="primary">
            Add Slider
          </Button>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </form>
    </Box>
  );
};

export default AddSliderPage;
