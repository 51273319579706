import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const EditSliderPage = () => {
  const [formData, setFormData] = useState({
    title: '',
    image_url: '',
    redirected_url: '',
    start_date: '',
    end_date: '',
    is_active: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchSlider = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/slider/${id}`);
        setFormData(response.data.data);
      } catch (err) {
        setError('Error fetching slider');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSlider();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/slider/${id}`, formData);
      navigate('/slider_list'); // Redirect to the slider list page
    } catch (err) {
      setError('Error updating slider');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box padding={2}>
      <Typography variant="h4">Edit Slider</Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Image URL"
            name="image_url"
            value={formData.image_url}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Redirected URL"
            name="redirected_url"
            value={formData.redirected_url}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Start Date"
            name="start_date"
            type="date"
            value={formData.start_date}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            label="End Date"
            name="end_date"
            type="date"
            value={formData.end_date}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
          <Box margin="normal">
            <label>
              <input
                type="checkbox"
                name="is_active"
                checked={formData.is_active}
                onChange={handleChange}
              />
              Active
            </label>
          </Box>
          <Button type="submit" variant="contained" color="primary">
            Update Slider
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </form>
      )}
    </Box>
  );
};

export default EditSliderPage;
