import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const EditNewsletterPage = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    publish_date: '',
    author: '',
    file_url: null, // for new file upload
    status: 1,
    existingFile: '', // for displaying existing file name
  });
  
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Fetch the newsletter data
  const fetchNewsletter = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/newsletter/${id}`);
      setFormData({
        title: response.data.data.title,
        content: response.data.data.content,
        publish_date: response.data.data.publish_date,
        author: response.data.data.author,
        file_url: null, // Reset to null since we're handling it separately
        status: response.data.data.status,
        existingFile: response.data.data.file_url, // Save existing file URL
      });
    } catch (err) {
      setError('Error fetching newsletter data');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNewsletter();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file' && files.length > 0) {
      setFormData({
        ...formData,
        file_url: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    for (const key in formData) {
      // If file_url is null (no new file selected), keep the existing file URL
      if (key === 'file_url' && !formData.file_url) {
        data.append(key, formData.existingFile);
      } else {
        data.append(key, formData[key]);
      }
    }

    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/newsletter/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      navigate('/newsletter_list');
    } catch (err) {
      setError('Error updating newsletter');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Edit Newsletter
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            name="title"
            label="Title"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.title}
            onChange={handleChange}
            required
          />
          <TextField
            name="content"
            label="Content"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={formData.content}
            onChange={handleChange}
            required
          />
          <TextField
            name="publish_date"
            label="Publish Date"
            variant="outlined"
            fullWidth
            margin="normal"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={formData.publish_date}
            onChange={handleChange}
          />
          <TextField
            name="author"
            label="Author"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.author}
            onChange={handleChange}
          />
          <input
            name="file_url"
            type="file"
            accept="application/pdf"
            onChange={handleChange}
          />
          {formData.file_url ? (
            <Typography variant="body2">
              File selected: {formData.file_url.name}
            </Typography>
          ) : (
            formData.existingFile && (
              <Typography variant="body2" color="textSecondary">
                Current file: {formData.existingFile.split('/').pop()}
              </Typography>
            )
          )}
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={formData.status}
              onChange={handleChange}
              label="Status"
            >
              <MenuItem value={1}>Active</MenuItem>
              <MenuItem value={0}>Inactive</MenuItem>
            </Select>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Update Newsletter'}
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      )}
    </div>
  );
};

export default EditNewsletterPage;
