import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
} from '@mui/material';

const Parents = () => {
  const [parents, setParents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchParents = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/list/parent`);
        setParents(response.data.data); // Access the 'data' field from the response
      } catch (err) {
        setError('Error fetching parents');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchParents();
  }, []);

  return (
    <div>
      <h1>Parent List</h1>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial No.</TableCell>
                <TableCell>Parent ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>Relation to Student</TableCell>
                <TableCell>School Short Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parents.length > 0 ? (
                parents.map((parent, index) => (
                  <TableRow key={parent._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{parent.parent_id}</TableCell>
                    <TableCell>{parent.parents_name}</TableCell>
                    <TableCell>{parent.p_email}</TableCell>
                    <TableCell>{parent.contact_num}</TableCell>
                    <TableCell>{parent.relation_to_student}</TableCell>
                    <TableCell>{parent.school_shortName}</TableCell>
                    <TableCell>{parent.status ? 'Active' : 'Inactive'}</TableCell>
                    <TableCell>{new Date(parent.createdAt).toLocaleDateString()}</TableCell>
                    <TableCell>{new Date(parent.updatedAt).toLocaleDateString()}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    No parents found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Parents;
