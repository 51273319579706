import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Grid,
  TableRow,
  Paper,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/AuthUtils';

const SchoolList = () => {
  const [schools, setSchools] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedSchoolId, setSelectedSchoolId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchSchools();
  }, [page, rowsPerPage, searchTerm]);

  const fetchSchools = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school`, {
          params: {
            page: page + 1, // Assuming the API uses 1-based index
            limit: rowsPerPage,
            searchTerm,
          },
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setSchools(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      } else {
        setError('Unauthorized');
        setOpenSnackbar(true);
      }
    } catch (err) {
      setError('Error fetching schools');
      setOpenSnackbar(true);
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setSelectedSchoolId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleDeleteSchool = async (id) => {
    try {
      const token = getToken();
      if (token) {
        await axios.delete(`${process.env.REACT_APP_BACKEND}/api/school/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setOpenDeleteDialog(false);
        fetchSchools();
      }
    } catch (err) {
      setError('Failed to delete school');
      setOpenSnackbar(true);
    }
  };

  const handleNavigateToAddPage = () => {
    navigate('/add-school');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigateToEditPage = (id) => {
    navigate(`/edit-school/${id}`);
  };

  const handleNavigateToViewPage = (id) => {
    navigate(`/view-school/${id}`);
  };

  return (
    <div>
      <h1>School Listing</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleNavigateToAddPage}
        >
          Add School
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>School ID</TableCell>
              <TableCell>School Logo</TableCell>
              <TableCell>Principal Photo</TableCell>
              <TableCell>School Name</TableCell>
              <TableCell>Principal Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schools.length > 0 ? (
              schools.map((school, index) => (
                <TableRow key={school._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{school.school_code}</TableCell>
                  {/* School Logo */}
                  <TableCell>
                    {school.school_logo ? (
                      <img
                        src={school.school_logo}
                        alt="School Logo"
                        style={{ width: 50, height: 50, objectFit: 'cover' }}
                      />
                    ) : (
                      'No Logo'
                    )}
                  </TableCell>
                  <TableCell>
                    {school.principal_photo ? (
                      <img
                        src={school.principal_photo}
                        alt="Principal Photo"
                        style={{ width: 50, height: 50, objectFit: 'cover' }}
                      />
                    ) : (
                      'No Photo'
                    )}
                  </TableCell>
                  <TableCell>{school.school_name}</TableCell>
                  <TableCell>{school.principal_name}</TableCell>
                  <TableCell>{school.school_address}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleNavigateToViewPage(school._id)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => handleNavigateToEditPage(school._id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleOpenDeleteDialog(school._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No schools found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > rowsPerPage && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      {/* Delete Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Delete School</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this school?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button
            onClick={() => handleDeleteSchool(selectedSchoolId)}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar for feedback */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={error ? 'error' : 'success'}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SchoolList;
