import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  Switch,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const PartnerListPage = () => {
  const [partners, setPartners] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchPartners();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm, page, rowsPerPage]);

  const fetchCategoryAndSubCategoryNames = async (partner) => {
    try {
      const categoryResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/category/${partner.partner_category}`);
      const subCategoryResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/sub_category/${partner.partner_sub_category}`);
      
      return {
        ...partner,
        partner_category_name: categoryResponse.data.partner_name,
        partner_sub_category_name: subCategoryResponse.data.SubCategory_name,
      };
    } catch (err) {
      console.error('Error fetching category or subcategory:', err);
      return partner;
    }
  };

  const fetchPartners = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          searchTerm,
        },
      });
      const fetchedPartners = await Promise.all(
        response.data.data.map(async (partner) => {
          return await fetchCategoryAndSubCategoryNames(partner);
        })
      );
      setPartners(fetchedPartners);
      setTotalItems(response.data.pagination.totalItems);
    } catch (err) {
      console.error('Error fetching partners:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddPartner = () => {
    navigate('/partners/add');
  };

  const handleEditPartner = (id) => {
    navigate(`/partners/edit/${id}`);
  };

  const handleDeletePartner = (id) => {
    setSelectedPartnerId(id);
    setOpenConfirmDialog(true);
  };

  const handleToggleStatus = async (id, status) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/partner/${id}`, { status: status === 1 ? 0 : 1 });
      fetchPartners();
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const confirmDeletePartner = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/partner/${selectedPartnerId}`);
      setSnackbarMessage('Partner deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchPartners();
    } catch (err) {
      console.error('Error deleting partner:', err);
      setSnackbarMessage('Failed to delete partner');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setOpenConfirmDialog(false);
    }
  };

  const cancelDeletePartner = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <div>
      <h1>Partners</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddPartner}
        >
          Add Partner
        </Button>
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial No.</TableCell>
                <TableCell>Partner Name</TableCell>    
                <TableCell>Category</TableCell>
                <TableCell>Sub-Category</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partners.length > 0 ? (
                partners.map((partner, index) => (
                  <TableRow key={partner._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{partner.partner_name}</TableCell>
                    <TableCell>{partner.partner_category_name || 'N/A'}</TableCell>
                    <TableCell>{partner.partner_sub_category_name || 'N/A'}</TableCell>
                    <TableCell>
                      <Switch
                        checked={partner.status === 1}
                        onChange={() => handleToggleStatus(partner._id, partner.status)}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton color="primary" onClick={() => handleEditPartner(partner._id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton color="error" onClick={() => handleDeletePartner(partner._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No partners found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={cancelDeletePartner}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this partner? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDeletePartner} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeletePartner} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PartnerListPage;
