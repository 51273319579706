import React, { useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useParams } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Grid,
  Container,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  Paper,
  DialogContent,
  CircularProgress,
  CardMedia,
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import axios from "axios";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from "@mui/icons-material/Close";
import { height } from "@mui/system";
import "./DisplayUnit.css";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function DisplayUnit() {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [error, setError] = useState(null);
  const [timer, setTimer] = useState(0);
  const [unit, setUnit] = useState(null);
  const [contentData, setContentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const containerRef = useRef(null);

  // States for image modal
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    const fetchUnit = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/list/ui/${id}`
        );
        console.log(response.data);

        // Check if the response has unitsPlans property
        if (response.data && response.data.unitsPlans) {
          setContentData(response.data.unitsPlans);
        } else {
          setError("No units plans found");
        }
      } catch (err) {
        setError(err.message || "Failed to fetch unit data");
      } finally {
        setLoading(false);
      }
    };

    fetchUnit();
  }, []);

  const handleClickOpen = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };

  // Function to handle closing the modal
  const handleClose = () => {
    setOpen(false);
    setSelectedImage("");
  };

  const toggleFullScreen = () => {
    if (containerRef.current) {
      if (document.fullscreenElement) {
        setIsFullScreen(false);
        document.exitFullscreen();
      } else {
        containerRef.current
          .requestFullscreen()
          .then(() => {
            setIsFullScreen(true);
          })
          .catch((err) => {
            console.log("hii");
          });
      }
    }
  };

  const CustomCard = styled(Card)(({ theme }) => ({
    maxWidth: "100%",
    maxHeight: { xs: "200px", sm: "250px", md: "300px" },
    textAlign: "center",
    backgroundColor: "#f0f0f0",
    margin: theme.spacing(2),
  }));

  // Styles for the larger image
  const LargeImage = styled("img")({
    width: "70%",
    height: "auto",
    margin: "20px auto",
    display: "block",
  });

  // Button styles
  const ButtonContainer = styled(Box)({
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    marginTop: "20px",
  });

  const TriangleListItem = styled(ListItem)(({ theme }) => ({
    position: "relative",
    paddingLeft: "24px", // Adjust the padding to make room for the triangle
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: "50%",
      borderColor: "#fcd19e",
      transform: "translateY(-50%)",
      width: 0,
      height: 0,
      borderTop: "6px solid transparent",
      borderBottom: "6px solid transparent",
      borderLeft: "10px solid black", // Adjust the color and size of the triangle here
    },
  }));

  const ImageCarousel = styled(Box)(({ theme }) => ({
    display: "flex",
    overflowX: "scroll",
    scrollBehavior: "smooth",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& img": {
      minWidth: { xs: "calc(100% / 3 - 16px)", sm: "calc(100% / 4 - 16px)" },
      maxWidth: { xs: "calc(100% / 3 - 16px)", sm: "calc(100% / 4 - 16px)" },
      height: "auto",
      borderRadius: theme.shape.borderRadius,
      objectFit: "cover",
      cursor: "pointer",
    },
  }));

  const ClockContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: { xs: "120px", md: "150px" },
    height: { xs: "120px", md: "150px" },
    borderRadius: "50%",
    border: "5px solid #e0e0e0",
    textAlign: "center",
    margin: theme.spacing(4),
    position: "relative",
  }));

  const TimeText = styled(Typography)(({ theme }) => ({
    fontSize: { xs: "18px", md: "24px" },
    fontWeight: "bold",
    color: "#333",
  }));

  // Counter styles
  const TimerContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    backgroundColor: "#e0e0e0",
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    justifyContent: { xs: "center", md: "space-between" },
    alignItems: "center",
  }));

  const ImageBox = styled(Box)(({ theme }) => ({
    minWidth: { xs: "100%", sm: "300px" },
    height: { xs: "200px", sm: "250px" },
    backgroundColor: "#e0e0f0",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: theme.shape.borderRadius,
  }));

  const Clock = () => {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
      const interval = setInterval(() => {
        setTime(new Date());
      }, 1000);
      return () => clearInterval(interval);
    }, []);

    const formatTime = (time) => {
      const hours = String(time.getHours()).padStart(2, "0");
      const minutes = String(time.getMinutes()).padStart(2, "0");
      const seconds = String(time.getSeconds()).padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    };

    return (
      <Box
        sx={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          backgroundColor: "#000",
          color: "#00FF00",
          padding: "10px",
          borderRadius: "8px",
          fontSize: "24px",
          fontFamily: "monospace",
          fontWeight: "bold",
          zIndex: 1000,
        }}
      >
        {formatTime(time)}
      </Box>
    );
  };

  const ImagePopupCarousel = () => {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("");

    const handleClickOpen = (imageSrc) => {
      setSelectedImage(imageSrc);
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setSelectedImage("");
    };

    return (
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogContent>
          <img
            src={selectedImage}
            alt="Selected"
            style={{ width: "100%", height: "auto" }}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <Container
        className="Nandhu"
        maxWidth="100%"
        ref={containerRef}
        sx={{
          width: isFullScreen ? "100%" : "100%",
          height: isFullScreen ? "100%" : "",
          backgroundColor: isFullScreen ? "#fff" : "",
          display: isFullScreen ? "flex" : "",
          flexDirection: isFullScreen ? "column" : "",
          justifyContent: isFullScreen ? "flex-start" : "",
          alignItems: isFullScreen ? "center" : "",
          overflowY: isFullScreen ? "auto" : "",
          maxHeight: isFullScreen ? "100%" : "",
          transition: isFullScreen ? "all 0.3s ease-in-out" : "",
          padding: "0px !important",
        }}
      >
        <Box width={"100%"} sx={{ backgroundColor: "#9e503d", height: "auto" }}>
          <Box
            width={"100%"}
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              // height: "auto",
              width: "100%",
              padding: "10px",
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              sx={{ fontSize: "23px", color: "#fff" }}
            >
              <ArrowRightIcon sx={{ color: "#fff" }} /> HOME COURSE 1 GRADE 1
              VOL 1 <ArrowRightIcon sx={{ color: "#fff" }} /> MUSICAL
              PROGRAMMING: COMPOSING WITH MODI MODULES{" "}
              <ArrowRightIcon sx={{ color: "#fff" }} /> PERIOD 1
            </Typography>
          </Box>
        </Box>
        {!contentData ||
        !contentData.units_content ||
        contentData.units_content.length === 0 ? (
          <Box sx={{ textAlign: "center", padding: "20px" }}>
            <CircularProgress />
            <Typography variant="h6" sx={{ marginTop: "10px" }}>
              Loading...
            </Typography>
          </Box>
        ) : (
          contentData.units_content.map((item, index) => {
            switch (item.type) {
              case "unitHeading":
                return (
                  <Box
                    key={index}
                    width={"100%"}
                    sx={{
                      backgroundColor: "#f1f1f1",
                      height: "auto", // Change height to auto to adjust based on the content
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "20px",
                      paddingRight: "20px", // Add some right padding for spacing
                      paddingTop: "10px", // Optional: add padding to avoid text sticking to edges
                      paddingBottom: "10px", // Optional: add padding to avoid text sticking to edges
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Container>
                      <Typography
                        variant="h3"
                        gutterBottom
                        sx={{
                          wordWrap: "break-word", // Ensures long words break into a new line
                          overflow: "hidden", // Prevents overflow
                          textOverflow: "ellipsis", // Adds "..." if text overflows
                          whiteSpace: "normal", // Ensures the text wraps onto the next line
                        }}
                      >
                        {item.data}
                      </Typography>
                    </Container>
                  </Box>
                );
              case "Heading1":
                return (
                  <Box
                    key={index}
                    width={"100%"}
                    sx={{
                      backgroundColor: "#fcd19e",
                      padding: 1,
                      // margin: "20px",
                      textAlign: "center",
                      // borderRadius: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <Typography
                      variant="h3"
                      dangerouslySetInnerHTML={{ __html: item.data }}
                      gutterBottom
                    />
                  </Box>
                );
              case "Heading2":
                return (
                  <Box
                    key={index}
                    width={"100%"}
                    sx={{
                      backgroundColor: "#ffddb4",
                      padding: 1,
                      margin: "20px auto",
                      borderRadius: "10px",
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      dangerouslySetInnerHTML={{ __html: item.data }}
                    />
                  </Box>
                );
              case "Subheading1":
                return (
                  <Box
                    key={index}
                    width={"100%"}
                    sx={{
                      backgroundColor: "#ffebd2",
                      padding: 1,
                      margin: "20px auto",
                      // borderRadius: "10px",
                      marginTop: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      dangerouslySetInnerHTML={{ __html: item.data }}
                      // sx={{ marginBottom: "0px!important" }}
                    />
                  </Box>
                );
              case "Subheading2":
                return (
                  <Box
                    key={index}
                    width={"100%"}
                    sx={{
                      backgroundColor: "#ffd8a84a",
                      padding: 1,
                      margin: "20px auto",
                      // borderRadius: "10px",
                      marginTop: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      dangerouslySetInnerHTML={{ __html: item.data }}
                    />
                  </Box>
                );
              case "paragraph":
                return (
                  <Box
                    key={index}
                    Width={"85%"}
                    sx={{
                      margin: "20px auto",
                      padding: "20px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Typography
                      variant="body2"
                      dangerouslySetInnerHTML={{ __html: item.data }}
                    />
                  </Box>
                );
              case "pdf":
                return (
                  <Box
                    className="nandhu"
                    key={index}
                    width="100%"
                    sx={{ marginleft: "0px !important" }}
                  >
                    <Box sx={{ mb: 4 }}>
                      {item.data.map((url, pdfIndex) => (
                        <Box
                          key={pdfIndex}
                          sx={{
                            mb: 4,
                            p: 2,
                            borderRadius: 2,
                            bgcolor: "#fff",
                            width: "100%",
                            // overflow: "hidden",
                          }}
                        >
                          {/* Error Message */}
                          {error && (
                            <Typography
                              color="error"
                              variant="body2"
                              sx={{ mb: 2 }}
                            >
                              Error: {error.message}
                            </Typography>
                          )}
                          {/* PDF Document */}
                          <Box
                            sx={{
                              maxHeight: "auto",
                              p: 1,
                              width: "100% !important",
                            }}
                          >
                            <Document
                              className="stypdf"
                              file={url}
                              onLoadSuccess={onDocumentLoadSuccess}
                              onLoadError={setError}
                              loading={
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    py: 4,
                                  }}
                                >
                                  <CircularProgress />
                                </Box>
                              }
                              sx={{
                                width: "100% !important",
                                height: "auto",
                              }}
                            >
                              {Array.from(
                                new Array(numPages),
                                (el, pageIndex) => (
                                  <Box
                                    key={`page_${pageIndex + 1}`}
                                    sx={{ mb: 2 }}
                                  >
                                    <Page
                                      pageNumber={pageIndex + 1}
                                      renderTextLayer={false}
                                      renderAnnotationLayer={false}
                                      scale={0.8} // Control zoom level, adjust the scale as needed
                                      width={
                                        document.querySelector("body")
                                          .offsetWidth - 0
                                      } // Adjust to 100% width of the container minus padding
                                    />
                                  </Box>
                                )
                              )}
                            </Document>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                );
                case "gallery":
                  return (
                    <Box
                      key={index}
                      sx={{ width: "100%", maxWidth: "1200px", margin: "0 auto" }}
                    >
                      {item.data.length === 1 ? (
                        <img
                          src={item.data[0]}
                          alt="Gallery"
                          onClick={() => handleClickOpen(item.data[0])}
                          style={{ width: "100%", height: "auto",maxWidth: "1200px" }}
                        />
                      ) : (
                        <ImageCarousel>
                          {item.data.map((url, i) => (
                            <img
                              key={i}
                              src={url}
                              alt="Gallery"
                              onClick={() => handleClickOpen(url)}
                            />
                          ))}
                        </ImageCarousel>
                      )}
                      <Dialog open={open} onClose={handleClose} maxWidth="lg">
                        <DialogContent>
                          <img
                            src={selectedImage}
                            alt="Selected"
                            style={{ width: "100%", height: "auto" }}
                          />
                        </DialogContent>
                      </Dialog>
                    </Box>
                  );
                
              case "videoUpload":
                return (
                  <Box
                    key={index}
                    sx={{
                      maxWidth: "xl",
                      marginTop: 2,
                      margin: "0 auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <video
                      controls
                      style={{
                        width: "100%",
                        height: "700px",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                      }}
                    >
                      <source src={item.data[0]} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Box>
                );
              case "videoUrl":
                return (
                  <Box key={index} marginTop={2}>
                    <Button
                      href={item.videoUrl}
                      target="_blank"
                      variant="contained"
                      startIcon={<AssignmentIcon sx={{ fontSize: "18px" }} />}
                      sx={{
                        borderRadius: "20px",
                        color: "#fcfafad6!important",
                        backgroundColor: "#faac18",
                        fontSize: "18px",
                      }}
                    >
                      {item.data}
                    </Button>
                  </Box>
                );
              default:
                return null;
            }
          })
        )}
      </Container>
      <Container
        maxWidth="xl"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Box>
          <Button
            onClick={toggleFullScreen}
            variant="contained"
            sx={{
              position: "fixed",
              bottom: "15px",
              right: "20px",
              backgroundColor: "#00afd8",
              color: "#fff",
              width: "45px",
              height: "45px",
              minWidth: "auto",
              padding: 0,
              "&:hover": {
                backgroundColor: "#008bb3",
              },
              "& .MuiButton-startIcon": {
                margin: 0,
              },
              "& .MuiButton-label": {
                color: "transparent",
              },
            }}
            startIcon={
              isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />
            }
          />
        </Box>
      </Container>
    </>
  );
}

export default DisplayUnit;
