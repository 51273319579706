import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormControl,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const AssignCourse = () => {
  const [schoolCategoryId, setSchoolCategoryId] = useState("");
  const [schoolSubCategoryId, setSchoolSubCategoryId] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [courseCategoryId, setCourseCategoryId] = useState("");
  const [courseSubCategoryId, setCourseSubCategoryId] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [alreadyAssignedCourses, setAlreadyAssignedCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const [schoolCategories, setSchoolCategories] = useState([]);
  const [schoolSubCategories, setSchoolSubCategories] = useState([]);
  const [schools, setSchools] = useState([]);
  const [courseCategories, setCourseCategories] = useState([]);
  const [courseSubCategories, setCourseSubCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [filteredSchools, setFilteredSchools] = useState([]);
  const [filteredSubSchoolCategories, setFilteredSchoolSubCategories] = useState([]);

  const token = getToken();
  const navigate = useNavigate();

  // Fetch initial school and course categories
  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const [schoolCategoriesResponse, courseCategoriesResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND}/api/school_category`),
          axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category`),
        ]);

        setSchoolCategories(schoolCategoriesResponse.data.data.filter(category => category.status === 1));
        setCourseCategories(courseCategoriesResponse.data.data.filter(category => category.status === 1));
      } catch (error) {
        setError("Failed to fetch data.");
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  // Fetch school subcategories and schools based on selections
  useEffect(() => {
    const fetchDataBasedOnSelections = async () => {
      setLoading(true);
      try {
      // Fetch subcategories based on selected school category
      if (schoolCategoryId) {
        const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sub_school_category`, {
          params: { category_id: schoolCategoryId },
        });
        setSchoolSubCategories(data.data.filter(subCategory => subCategory.status === 1));
      } else {
        setSchoolSubCategories([]);
      }

        // Fetch schools based on selected school subcategory
        if (schoolSubCategoryId) {
          const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school`, {
            params: { subcategory_id: schoolSubCategoryId },
            headers: { Authorization: `Bearer ${token}` },
          });
          setSchools(data.data.filter(school => school.status === 1));
        } else {
          setSchools([]);
        }
      } catch (error) {
        setError("Failed to fetch dependent data.");
        console.error("Error fetching dependent data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDataBasedOnSelections();
  }, [schoolCategoryId, schoolSubCategoryId]);
// Filter subcategories based on the selected school category
useEffect(() => {
  if (schoolCategoryId) {
    const filtered = schoolSubCategories.filter(subcategory =>
      subcategory.School_category_id === schoolCategoryId
    );
    setFilteredSchoolSubCategories(filtered);
  }
}, [schoolCategoryId, schoolSubCategories]);

// Filter schools based on the selected school sub-category
useEffect(() => {
  if (schoolSubCategoryId) {
    const filtered = schools.filter(school =>
      school.school_sub_category === schoolSubCategoryId
    );
    setFilteredSchools(filtered);
  }
}, [schoolSubCategoryId, schools]);
  // Fetch already assigned courses when schoolId or courseSubCategoryId changes
  useEffect(() => {
    const fetchAlreadyAssignedCourses = async () => {
      if (schoolId && courseSubCategoryId) {
        setLoading(true);
        try {
          const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/assign_course/assigned_courses_school/${schoolId}/${courseSubCategoryId}`, {
            // params: { school_id: schoolId, subcategory_id: courseSubCategoryId },
            headers: { Authorization: `Bearer ${token}` },
          });
          setAlreadyAssignedCourses(data.data);          
          setSelectedCourses(data.data.map(course => course.course_id)); // Assuming the API returns an array of courses with course_id
        } catch (error) {
          setError("Failed to fetch assigned courses.");
          console.error("Error fetching assigned courses:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchAlreadyAssignedCourses();
  }, [schoolId, courseSubCategoryId]);

  // Fetch course subcategories and courses based on selections
  useEffect(() => {
    const fetchCourseDataBasedOnSelections = async () => {
      setLoading(true);
      try {
        // Fetch subcategories based on selected course category
        if (courseCategoryId) {
          const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category_sub`, {
            params: { category_id: courseCategoryId },
          });
          setCourseSubCategories(data.data.filter(subCategory => subCategory.status === 1));
        } else {
          setCourseSubCategories([]);
        }

        // Fetch courses based on selected course subcategory
        if (courseSubCategoryId) {
          const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses`, {
            params: { subcategory_id: courseSubCategoryId },
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          setCourses(data.data.filter(course => course.status === 1));
        } else {
          setCourses([]);
        }
      } catch (error) {
        setError("Failed to fetch course data.");
        console.error("Error fetching course data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDataBasedOnSelections();
  }, [courseCategoryId, courseSubCategoryId]);
   // Filter subcategories based on the selected course category
   useEffect(() => {
    if (courseCategoryId) {
      const filtered = courseSubCategories.filter(subcategory =>
        subcategory.course_category_id === courseCategoryId
      );
      setFilteredSubCategories(filtered);
    }
  }, [courseCategoryId, courseSubCategories]);
 
  // Filter courses based on the selected course sub-category
  useEffect(() => {
    if (courseSubCategoryId) {
      const filtered = courses.filter(course =>
        course.course_subcategory_id === courseSubCategoryId
      );
      setFilteredCourses(filtered);
    }
  }, [courseSubCategoryId, courses]);
 
  const handleCourseChange = (courseId) => {
    setSelectedCourses((prev) => {
      if (prev.includes(courseId)) {
        return prev.filter(id => id !== courseId);
      } else {
        return [...prev, courseId];
      }
    });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!schoolCategoryId || !schoolSubCategoryId || !schoolId || !courseCategoryId || !courseSubCategoryId || selectedCourses.length === 0) {
  //     setError("Please fill out all required fields.");
  //     return;
  //   }

  //   const assignData = {
  //     school_category_id: schoolCategoryId,
  //     school_sub_category_id: schoolSubCategoryId,
  //     school_id: schoolId,
  //     course_category_id: courseCategoryId,
  //     course_sub_category_id: courseSubCategoryId,
  //     course_ids: selectedCourses,
  //   };

  //   setLoading(true);

  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/assign_course`, assignData, {
  //       headers: {
  //         'Authorization': `Bearer ${token}`,
  //       },
  //     });
  //     if (response.data) {
  //       setSuccess("Courses assigned successfully!");
  //       setError(null);
  //       // Reset fields after successful submission
  //       setSchoolCategoryId("");
  //       setSchoolSubCategoryId("");
  //       setSchoolId("");
  //       setCourseCategoryId("");
  //       setCourseSubCategoryId("");
  //       setSelectedCourses([]);

  //       // Redirect to the list page
  //       navigate('/partner/assign_courses_list');
  //     } else {
  //       setError(response.data.message || "Failed to assign course.");
  //     }
  //   } catch (error) {
  //     setError("Failed to assign course.");
  //     console.error("Error assigning course:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!schoolCategoryId || !schoolSubCategoryId || !schoolId || !courseCategoryId || !courseSubCategoryId || selectedCourses.length === 0) {
      setError("Please fill out all required fields.");
      return;
    }

    // Filter out already assigned courses
    const filteredSelectedCourses = selectedCourses.filter(courseId => 
      !alreadyAssignedCourses.some(assignedCourse => assignedCourse.course_id === courseId)
    );

    // If no new courses to assign
    if (filteredSelectedCourses.length === 0) {
      setError("No new courses selected to assign.");
      return;
    }

    const assignData = {
      school_category_id: schoolCategoryId,
      school_sub_category_id: schoolSubCategoryId,
      school_id: schoolId,
      course_category_id: courseCategoryId,
      course_sub_category_id: courseSubCategoryId,
      course_ids: filteredSelectedCourses, // Only new courses
    };

    setLoading(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/assign_course`, assignData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.data) {
        setSuccess("Courses assigned successfully!");
        setError(null);
        // Reset fields after successful submission
        setSchoolCategoryId("");
        setSchoolSubCategoryId("");
        setSchoolId("");
        setCourseCategoryId("");
        setCourseSubCategoryId("");
        setSelectedCourses([]);

        // Redirect to the list page
        navigate('/assign_courses_list');
      } else {
        setError(response.data.message || "Failed to assign course.");
      }
    } catch (error) {
      setError("Failed to assign course.");
      console.error("Error assigning course:", error);
    } finally {
      setLoading(false);
    }
};

  const handleSnackbarClose = () => {
    setSuccess(null);
    setError(null);
  };

  return (
    <Container maxWidth="xl" sx={{ padding: "20px", borderRadius: "8px" }}>
      <Typography variant="h4" gutterBottom>
        Assign Course
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {error && (
            <Alert severity="error" sx={{ marginBottom: "20px" }}>
              {error}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select School Category"
                  value={schoolCategoryId}
                  onChange={(e) => {
                    setSchoolCategoryId(e.target.value);
                    setSchoolSubCategoryId(""); // Reset dependent state
                    setSchools([]); // Reset schools
                  }}
                >
                  {schoolCategories.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.school_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select School Subcategory"
                  value={schoolSubCategoryId}
                  onChange={(e) => {
                    setSchoolSubCategoryId(e.target.value);
                    setSchools([]); // Reset schools
                  }}
                >
                  {filteredSubSchoolCategories.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.SubCategory_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
 
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select School"
                  value={schoolId}
                  onChange={(e) => setSchoolId(e.target.value)}
                >
                  {filteredSchools.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.school_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select Course Category"
                  value={courseCategoryId}
                  onChange={(e) => {
                    setCourseCategoryId(e.target.value);
                    setCourseSubCategoryId(""); // Reset dependent state
                    setCourses([]); // Reset courses
                  }}
                >
                  {courseCategories.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.courses_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Select Course Subcategory"
                  value={courseSubCategoryId}
                  onChange={(e) => {
                    setCourseSubCategoryId(e.target.value);
                    setCourses([]); // Reset courses
                  }}
                >
                  {filteredSubCategories.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.courses_sub_category_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Already Assigned Courses</Typography>
                <FormControl fullWidth margin="normal" variant="outlined">
                  {alreadyAssignedCourses.length > 0 ? (
                    alreadyAssignedCourses.map((item) => (
                      <div key={item._id}>
                        <span>Course: {item.course_id.courses_name}</span> {/* Adjust to display relevant course properties */}
                      </div>
                    ))
                  ) : (
                    <p>No courses assigned.</p>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
  <Typography variant="h6">Available Courses</Typography>
  <FormGroup row>
    {filteredCourses
      .filter(course => 
        !alreadyAssignedCourses.some(assignedCourse => assignedCourse.course_id._id === course._id)
      )
      .map(course => (
        <FormControlLabel
          key={course._id}
          control={
            <Checkbox
              checked={selectedCourses.includes(course._id)}
              onChange={() => handleCourseChange(course._id)}
            />
          }
          label={course.courses_name}
        />
      ))}
  </FormGroup>
</Grid>


              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Assign Courses
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}

      <Snackbar open={success !== null} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
          {success}
        </Alert>
      </Snackbar>

      <Snackbar open={error !== null} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AssignCourse;
