import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
  Alert,
  Box,
  TablePagination,
  Switch,
  TextField,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getToken } from "../../utils/AuthUtils";

const ChaptersTable = () => {
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [courseCategories, setCourseCategories] = useState([]);
  const [courseSubCategories, setCourseSubCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteChapterId, setDeleteChapterId] = useState(null);
  
  // State to track which chapters are expanded
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  useEffect(() => {
    fetchChapters();
    fetchCourseCategories();
    fetchCourseSubCategories();
    fetchCourses();
  }, [currentPage, pageSize, searchTerm]);

  const fetchChapters = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}/api/chapters?page=${currentPage + 1}&limit=${pageSize}&search=${searchTerm}`
      );
      const data = await response.json();
      if (data?.data) {
        setChapters(data.data);
        setTotalItems(data.pagination.totalItems);
        setError(null);
      } else {
        setError("No chapters found");
      }
    } catch (err) {
      setError("Failed to fetch chapters");
    } finally {
      setLoading(false);
    }
  };

  const fetchCourseCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/courses_category`);
      const data = await response.json();
      setCourseCategories(data.data || []);
    } catch (err) {
      setError("Failed to fetch course categories");
    }
  };

  const fetchCourseSubCategories = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/courses_category_sub`);
      const data = await response.json();
      setCourseSubCategories(data.data || []);
    } catch (err) {
      setError("Failed to fetch course sub-categories");
    }
  };

  const fetchCourses = async () => {
    try {
      const token = getToken();
      if (token) {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/courses`,{
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setCourses(data.data || []);
    } }catch (err) {
      setError("Failed to fetch courses");
    }
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateChapterStatus(id, newStatus);
  };

  const updateChapterStatus = async (id, status) => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND}/api/chapters/${id}/status_chapter`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
      });
      setSnackbarMessage("Chapter status updated successfully");
      setOpenSnackbar(true);
      fetchChapters();
    } catch (err) {
      setError("Failed to update chapter status");
    }
  };

  const handleOpenDialog = (id) => {
    setDeleteChapterId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteChapterId(null);
  };

  const handleDelete = async () => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND}/api/chapters/${deleteChapterId}`, {
        method: "DELETE",
      });
      setSnackbarMessage("Chapter deleted successfully");
      setOpenSnackbar(true);
      fetchChapters();
    } catch (err) {
      setError("Failed to delete chapter");
    } finally {
      handleCloseDialog();
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
  };

  const handlePageChange = (event, newPage) => {
    
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // Toggle expanded description
  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Rendering chapter description
  const renderDescription = (chapter) => {
    const isExpanded = expandedDescriptions[chapter._id];
    const shortDescription = chapter.chapter_description.substring(0, 100) + (chapter.chapter_description.length > 100 ? '...' : '');
    
    return (
      <div>
        {isExpanded ? chapter.chapter_description : shortDescription}
        {chapter.chapter_description.length > 100 && (
          <Button onClick={() => toggleDescription(chapter._id)} size="small">
            {isExpanded ? 'Read Less' : 'Read More'}
          </Button>
        )}
      </div>
    );
  };

  // Function to get the name based on ID
  const getCategoryName = (id) => {
    const category = courseCategories.find(cat => cat._id === id);
    return category ? category.courses_name : 'Unknown';
  };

  const getSubCategoryName = (id) => {
    const subCategory = courseSubCategories.find(sub => sub._id === id);
    return subCategory ? subCategory.courses_sub_category_name : 'Unknown';
  };

  const getCourseName = (id) => {
    const course = courses.find(c => c._id === id);
    return course ? course.courses_name : 'Unknown';
  };

  return (
    <div>
      <h2>Chapters</h2>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: "300px" }}
        />
        <Button
          component={Link}
          to="/chapters_add"  // Redirects to AddChapter form
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
       Create
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Serial</b></TableCell>
              <TableCell><b>Chapter Name</b></TableCell>
              <TableCell><b>Chapter Description</b></TableCell>
              <TableCell><b>Course Category</b></TableCell>
              <TableCell><b>Course Sub Category</b></TableCell>
              <TableCell><b>Course</b></TableCell>
              <TableCell><b>Status</b></TableCell>
              <TableCell><b>Actions</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <Alert severity="error">{error}</Alert>
                </TableCell>
              </TableRow>
            ) : chapters.length > 0 ? (
              chapters
                .filter(chapter => chapter.chapter_name.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((chapter, index) => (
                  <TableRow key={chapter._id}>
                    <TableCell>{currentPage * pageSize + index + 1}</TableCell>
                    <TableCell>{chapter.chapter_name}</TableCell>
                    <TableCell>{renderDescription(chapter)}</TableCell>
                    <TableCell>{getCategoryName(chapter.course_category_id)}</TableCell>
                    <TableCell>{getSubCategoryName(chapter.course_sub_category_id)}</TableCell>
                    <TableCell>{getCourseName(chapter.course_id)}</TableCell>
                    <TableCell>
                      <Switch
                        checked={chapter.status}
                        onChange={() => handleStatusToggle(chapter._id, chapter.status)}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton color="primary" component={Link} to={`/edit_chapter/${chapter._id}`}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleOpenDialog(chapter._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">No chapters available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
      {totalItems >=10 && (  <TablePagination
          component="div"
          count={totalItems}
          page={currentPage}
          onPageChange={handlePageChange}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handlePageSizeChange}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage=""
          showFirstButton
          showLastButton
        />)}
      </Box>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this chapter? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChaptersTable;
