import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Link,
} from '@mui/material';

const PdfList = () => {
  const [pdfs, setPdfs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPdfs = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/list/unit/`);
        console.log(response);
        
        setPdfs(response.data.data); // Access the 'data' field from the response
      } catch (err) {
        setError('Error fetching PDFs');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPdfs();
  }, []);

  return (
    <div>
      <h1>Unit List</h1>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial No.</TableCell>
                <TableCell>Unit Name</TableCell>
                <TableCell>PDF Links</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pdfs.length > 0 ? (
                pdfs.map((pdf, index) => (
                  <TableRow key={pdf._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{pdf.unitDetails.unit_name}</TableCell>
                    <TableCell>
                      {pdf.units_content.map((url, urlIndex) => (
                        <Box key={urlIndex} mb={1}>
                          <Link href={url} target="_blank" rel="noopener noreferrer">
                            {url}
                          </Link>
                        </Box>
                      ))}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    No PDFs found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default PdfList;
