import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, CircularProgress, Snackbar, Alert, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import axios from 'axios';

function EditSubCategoryModal({ open, onClose, category, onUpdate }) {
  const [name, setName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/category`);
        console.log('Fetched categories:', response.data.data); // Debugging
        setCategories(response.data.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error('Error fetching categories:', err);
      }
    };

    if (category) {
      setName(category.SubCategory_name);
      setSelectedCategory(category.category_id); // Assuming `category_id` is the field for category
    }

    fetchCategories();
  }, [category]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/partner/sub_category/${category._id}`, { 
        SubCategory_name: name,
        category_id: selectedCategory
      });
      onUpdate(); // Callback to refresh the list
      onClose(); // Close the modal on successful update
    } catch (err) {
      setError('Failed to update sub-category');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Sub-Category</DialogTitle>
      <DialogContent>
        {error && (
          <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError(null)}>
            <Alert onClose={() => setError(null)} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}
         <FormControl fullWidth margin="dense" error={false}>
          <InputLabel>Category</InputLabel>
          <Select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            label="Category"
          >
            {categories.map((cat) => (
              <MenuItem key={cat._id} value={cat._id}>
                {cat.partner_name} {/* Ensure this field matches the fetched data */}
              </MenuItem>
            ))}
          </Select>
          {/* Error handling for category field if needed */}
        </FormControl>
        <TextField
          autoFocus
          margin="dense"
          label="Sub Category Name"
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
       
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditSubCategoryModal;
