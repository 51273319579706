import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import axios from 'axios';

function EditCourse({ open, onClose, categoryId, onUpdate }) {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Fetch the school sub-category data by ID
  useEffect(() => {
    if (categoryId) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_BACKEND}/api/courses_category/${categoryId}`)
        .then((response) => {
          setName(response.data.data.courses_name); // Update state with fetched name
        })
        .catch(() => {
          setError('Failed to fetch category data');
        })
        .finally(() => setLoading(false));
    }
  }, [categoryId]);

  // Update the school sub-category
  const handleUpdate = async () => {
    setLoading(true);
    try {
      const updateData = { courses_name: name };
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/courses_category/${categoryId}`, updateData);
      setSuccess(true);
      onUpdate(); // Callback to refresh the list after updating
      onClose();  // Close the modal
    } catch (err) {
      setError('Failed to update category');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit   Category</DialogTitle>
      <DialogContent>
        {error && (
          <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError(null)}>
            <Alert onClose={() => setError(null)} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}
        {success && (
          <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
            <Alert onClose={() => setSuccess(false)} severity="success">
              Category updated successfully
            </Alert>
          </Snackbar>
        )}
        <TextField
          autoFocus
          margin="dense"
          label=" Category Name"
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          disabled={loading} // Disable input while loading
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditCourse;
