import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const AddPartnerPage = () => {
  const [formData, setFormData] = useState({
    partner_name: "",
    contact_person: "",
    mobile: "",
    email: "",
    agreement_start_date: "",
    agreement_end_date: "",
    Partner_Category_id: "",
    Partner_sub_Category_id: "",
    countryId: "",
    stateId: "",
    districtId: "",
    townId: "",
    branch_name: "", // New field
    account_name: "", // New field
    account_number: "", // New field
    ifsc_code: "", // New field
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [loading, setLoading] = useState({
    categories: false,
    subCategories: false,
    countries: false,
    states: false,
    districts: false,
    towns: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading((prev) => ({ ...prev, categories: true }));
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/partner/category`
        );
        setCategories(response.data.data);
      } finally {
        setLoading((prev) => ({ ...prev, categories: false }));
      }
    };

    const fetchCountries = async () => {
      setLoading((prev) => ({ ...prev, countries: true }));
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/country?limit=300`
        );
        setCountries(response.data.data);
      } finally {
        setLoading((prev) => ({ ...prev, countries: false }));
      }
    };

    fetchCategories();
    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchSubCategories = async () => {
      setLoading((prev) => ({ ...prev, subCategories: true }));
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND}/api/partner/sub_category`
        );
        setSubCategories(response.data.data);
      } finally {
        setLoading((prev) => ({ ...prev, subCategories: false }));
      }
    };

    fetchSubCategories();
  }, []);

  useEffect(() => {
    if (formData.Partner_Category_id) {
      const filtered = subCategories.filter(
        (subcategory) => subcategory.partner_id === formData.Partner_Category_id
      );
      setFilteredSubCategories(filtered);
    } else {
      setFilteredSubCategories(subCategories);
    }
  }, [subCategories, formData.Partner_Category_id]);

  const fetchStates = async (countryId) => {
    setLoading((prev) => ({ ...prev, states: true }));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/state/by-country/?limit=300`,
        {
          params: { countryId },
        }
      );
      setStates(response.data.data);
    } catch (err) {
      console.error(
        "Failed to fetch states:",
        err.response?.data?.message || err.message
      );
    } finally {
      setLoading((prev) => ({ ...prev, states: false }));
    }
  };

  const fetchDistricts = async (stateId) => {
    setLoading((prev) => ({ ...prev, districts: true }));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/district/by-state?limit=300`,
        {
          params: { stateId },
        }
      );
      setDistricts(response.data.data);
    } catch (err) {
      console.error(
        "Failed to fetch districts:",
        err.response?.data?.message || err.message
      );
    } finally {
      setLoading((prev) => ({ ...prev, districts: false }));
    }
  };

  const fetchTowns = async (districtId) => {
    setLoading((prev) => ({ ...prev, towns: true }));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/town/by-district`,
        {
          params: { districtId },
        }
      );
      setTowns(response.data.data);
    } catch (err) {
      console.error(
        "Failed to fetch towns:",
        err.response?.data?.message || err.message
      );
    } finally {
      setLoading((prev) => ({ ...prev, towns: false }));
    }
  };

  useEffect(() => {
    if (formData.countryId) {
      fetchStates(formData.countryId);
    } else {
      setStates([]);
      setFormData((prev) => ({
        ...prev,
        stateId: "",
        districtId: "",
        townId: "",
      }));
    }
  }, [formData.countryId]);

  useEffect(() => {
    if (formData.stateId) {
      fetchDistricts(formData.stateId);
    } else {
      setDistricts([]);
      setFormData((prev) => ({ ...prev, districtId: "", townId: "" }));
    }
  }, [formData.stateId]);

  useEffect(() => {
    if (formData.districtId) {
      fetchTowns(formData.districtId);
    } else {
      setTowns([]);
      setFormData((prev) => ({ ...prev, townId: "" }));
    }
  }, [formData.districtId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND}/api/partner`,
        formData
      );
      setSnackbarMessage("Partner added successfully!");
      setSnackbarType("success");
      setSnackbarOpen(true);
      setTimeout(() => navigate("/partner_list"), 2000);
    } catch (error) {
      setSnackbarMessage("Error adding partner.");
      setSnackbarType("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <div>
      <h1>Add Partner</h1>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <Grid container spacing={2}>

          {/* Category */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Category</InputLabel>
              <Select
                name="Partner_Category_id"
                value={formData.Partner_Category_id}
                onChange={handleChange}
                label="Category"
              >
                {loading.categories ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : categories.length > 0 ? (
                  categories.map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.partner_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No categories available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          {/* Sub-Category */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Sub-Category</InputLabel>
              <Select
                name="Partner_sub_Category_id"
                value={formData.Partner_sub_Category_id}
                onChange={handleChange}
                label="Sub-Category"
              >
                {loading.subCategories ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : filteredSubCategories.length > 0 ? (
                  filteredSubCategories.map((subCategory) => (
                    <MenuItem key={subCategory._id} value={subCategory._id}>
                      {subCategory.SubCategory_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No sub-categories available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          {/* Country */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Country</InputLabel>
              <Select
                name="countryId"
                value={formData.countryId}
                onChange={handleChange}
                label="Country"
              >
                {loading.countries ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : countries.length > 0 ? (
                  countries.map((country) => (
                    <MenuItem key={country._id} value={country._id}>
                      {country.country_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No countries available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          {/* State */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>State</InputLabel>
              <Select
                name="stateId"
                value={formData.stateId}
                onChange={handleChange}
                label="State"
              >
                {loading.states ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : states.length > 0 ? (
                  states.map((state) => (
                    <MenuItem key={state._id} value={state._id}>
                      {state.state_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No states available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          {/* District */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" required>
              <InputLabel>District</InputLabel>
              <Select
                name="districtId"
                value={formData.districtId}
                onChange={handleChange}
                label="District"
              >
                {loading.districts ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : districts.length > 0 ? (
                  districts.map((district) => (
                    <MenuItem key={district._id} value={district._id}>
                      {district.district_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No districts available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Place"
              name="place"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Partner Name"
              name="partner_name"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Contact Person"
              name="contact_person"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile"
              name="mobile"
              type="tel"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
            />
          </Grid>
         

          <Grid item xs={12} sm={6}>
            <TextField
              label="Agreement Start Date"
              name="agreement_start_date"
              type="date"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true, // Keeps the label above the input
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Agreement End Date"
              name="agreement_end_date"
              type="date"
              variant="outlined"
              fullWidth
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
              InputLabelProps={{
                shrink: true, // Keeps the label above the input
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
    <TextField
      label="GST Number"
      name="gst_no"
      variant="outlined"
      fullWidth
      onChange={handleChange}
      required
      sx={{ mb: 2 }}
    />
  </Grid>

  <Grid item xs={12} sm={6}>
    <TextField
      label="Whatsapp Number"
      name="whatsapp_no"
      variant="outlined"
      fullWidth
      onChange={handleChange}
      required
      sx={{ mb: 2 }}
    />
  </Grid>

          {/* bank deatils */}
          <Grid item xs={12}>
    <Typography variant="h6" gutterBottom>
      Bank Details
    </Typography>
  </Grid>
  <Grid item xs={12} sm={6}>
    <TextField
      label="Bank Name"
      name="bank_name"
      variant="outlined"
      fullWidth
      onChange={handleChange}
      required
      sx={{ mb: 2 }}
    />
  </Grid>
  
            {/* Branch Name */}
  <Grid item xs={12} sm={6}>
    <TextField
      label="Branch Name"
      name="branch_name"
      variant="outlined"
      fullWidth
      onChange={handleChange}
      required
      sx={{ mb: 2 }}
    />
  </Grid>
  
  {/* Account Name */}
  <Grid item xs={12} sm={6}>
    <TextField
      label="Account Name"
      name="account_name"
      variant="outlined"
      fullWidth
      onChange={handleChange}
      required
      sx={{ mb: 2 }}
    />
  </Grid>

  {/* Account Number */}
  <Grid item xs={12} sm={6}>
    <TextField
      label="Account Number"
      name="account_number"
      type="text"
      variant="outlined"
      fullWidth
      onChange={handleChange}
      required
      sx={{ mb: 2 }}
    />
  </Grid>

  {/* IFSC Code */}
  <Grid item xs={12} sm={6}>
    <TextField
      label="IFSC Code"
      name="ifsc_code"
      variant="outlined"
      fullWidth
      onChange={handleChange}
      required
      sx={{ mb: 2 }}
    />
  </Grid>

        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ mt: 2, backgroundColor: "#e0e0e0" }}
        >
          Create
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddPartnerPage;
