import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Grid,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Typography,
  Switch,
  Snackbar,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; // PDF icon
import { useNavigate } from 'react-router-dom';

const NewsletterList = () => {
  const [newsletters, setNewsletters] = useState([]);
  const [expandedEventId, setExpandedEventId] = useState(null); // Track expanded newsletter
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState('success');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Fetch newsletters from the backend
  const fetchNewsletters = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/newsletter`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          searchTerm,
        },
      });
      setNewsletters(response.data.data);
      setTotalItems(response.data.pagination.totalItems);
    } catch (err) {
      setError('Error fetching newsletters');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNewsletters();
  }, [searchTerm, page, rowsPerPage]);

  const handleAddNewsletter = () => {
    navigate('/newsletters/add');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatusToggle = async (id, currentStatus) => {
    const newStatus = !currentStatus;
    setNewsletters(prev => prev.map(newsletter => (newsletter._id === id ? { ...newsletter, status: newStatus } : newsletter)));
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/newsletter/${id}/status_newsletter`, { status: newStatus });
      setSnackbarMessage("Newsletter status updated successfully");
      setSnackbarOpen(true);
    } catch {
      setNewsletters(prev => prev.map(newsletter => (newsletter._id === id ? { ...newsletter, status: currentStatus } : newsletter)));
      setSnackbarMessage("Failed to update newsletter status");
      setSnackbarOpen(true);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleEditNewsletter = (id) => {
    navigate(`/newsletters/edit/${id}`);
  };

  const handleDeleteNewsletter = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/newsletter/${id}`);
      fetchNewsletters(); // Refresh the list after deletion
    } catch (err) {
      setError('Error deleting newsletter');
      console.error(err);
    }
  };

  const handleOpenPDF = (url) => {
    window.open(url, '_blank');
  };

  const toggleExpand = (id) => {
    setExpandedEventId(expandedEventId === id ? null : id);
  };

  return (
    <div>
      <h1>Newsletters</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddNewsletter}
        >
          Create
        </Button>
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial No.</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Content</TableCell>
                <TableCell>Author</TableCell>
                <TableCell>File URL</TableCell>
                <TableCell>Published Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newsletters.length > 0 ? (
                newsletters.map((newsletter, index) => (
                  <TableRow key={newsletter._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{newsletter.title}</TableCell>
                    <TableCell>
                      <Typography>
                        {expandedEventId === newsletter._id ? (
                          <span>
                            {newsletter.content}
                            <Button onClick={() => toggleExpand(newsletter._id)} size="small">
                              Show Less
                            </Button>
                          </span>
                        ) : (
                          <span>
                            {newsletter.content.length > 100
                              ? `${newsletter.content.slice(0, 100)}...`
                              : newsletter.content}
                            {newsletter.content.length > 100 && (
                              <Button onClick={() => toggleExpand(newsletter._id)} size="small">
                                Read More
                              </Button>
                            )}
                          </span>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>{newsletter.author}</TableCell>
                    <TableCell>
                      {newsletter.file_url ? (
                        <IconButton onClick={() => handleOpenPDF(newsletter.file_url)}>
                          <PictureAsPdfIcon color="error" />
                        </IconButton>
                      ) : (
                        <Typography color="textSecondary">No File</Typography>
                      )}
                    </TableCell>
                    <TableCell>{new Date(newsletter.publish_date).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <Switch
                        checked={newsletter.status}
                        onChange={() => handleStatusToggle(newsletter._id, newsletter.status)}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton color="primary" onClick={() => handleEditNewsletter(newsletter._id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton color="error" onClick={() => handleDeleteNewsletter(newsletter._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No newsletters found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalItems >= 10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default NewsletterList;
