import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Grid,
  TableRow,
  Paper,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/AuthUtils';

const SchoolList = () => {
  const [schools, setSchools] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedSchoolId, setSelectedSchoolId] = useState(null);
   // Pagination States
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(50);
 
   const [categories, setCategories] = useState([]);
   const [subCategories, setSubCategories] = useState([]);
   const [countries, setCountries] = useState([]);
   const [states, setStates] = useState([]);
   const [districts, setDistricts] = useState([]);
 
   const [filter, setFilter] = useState({
     schoolCategory: '',
     schoolSubCategory: '',
     countryId: '',
     stateId: '',
     districtId: ''
   });
 
   const [filteredSchools, setFilteredSchools] = useState([]);
   const [loading, setLoading] = useState(false);
   // Fetch categories, subcategories, countries, states, districts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school_category?limit=300`);
        setCategories(categoriesResponse.data.data);

        const countriesResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country?limit=300`);
        setCountries(countriesResponse.data.data);

        // You can call fetch states and districts here if you need them initially
        if (filter.countryId) {
          const statesResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state/by-country/?limit=300`, {
            params: { countryId: filter.countryId }
          });
          setStates(statesResponse.data.data);
        }

        if (filter.stateId) {
          const districtsResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/district/by-state?limit=300`, {
            params: { stateId: filter.stateId }
          });
          setDistricts(districtsResponse.data.data);
        }
      } catch (err) {
        console.error('Failed to fetch data:', err);
      }
    };
    fetchData();
  }, [filter.countryId, filter.stateId]); // Reload based on changes to countryId and stateId

  // Fetch sub-categories based on selected category
  useEffect(() => {
    const fetchSubCategories = async () => {
      if (filter.schoolCategory) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sub_school_category?limit=300`, {
            params: { school_category: filter.schoolCategory }
          });
          setSubCategories(response.data.data);
        } catch (err) {
          console.error('Failed to fetch sub-categories:', err);
        }
      } else {
        setSubCategories([]);
      }
    };
    fetchSubCategories();
  }, [filter.schoolCategory]);

  // Function to handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // Submit filter and fetch filtered schools
  const handleFilterSubmit = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school/filter_school`,
         {
        params: filter,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setFilteredSchools(response.data.data);
    } }catch (err) {
      console.error('Failed to fetch filtered schools:', err);
    } finally {
      setLoading(false);
    }
  };
  const navigate = useNavigate();
  // Use filteredSchools if filters are applied, otherwise show all schools
  const schoolsToDisplay = filteredSchools.length > 0 ? filteredSchools : schools;

  useEffect(() => {
    fetchSchools();
  }, [page, rowsPerPage, searchTerm , filteredSchools]);

  const fetchSchools = async () => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school`, {
          params: {
            page: page + 1, // Assuming the API uses 1-based index
            limit: rowsPerPage,
            searchTerm,
          },
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setSchools(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      } else {
        setError('Unauthorized');
        setOpenSnackbar(true);
      }
    } catch (err) {
      setError('Error fetching schools');
      setOpenSnackbar(true);
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setSelectedSchoolId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleDeleteSchool = async (id) => {
    try {
      const token = getToken();
      if (token) {
        await axios.delete(`${process.env.REACT_APP_BACKEND}/api/school/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setOpenDeleteDialog(false);
        fetchSchools();
      }
    } catch (err) {
      setError('Failed to delete school');
      setOpenSnackbar(true);
    }
  };

  const handleNavigateToAddPage = () => {
    navigate('/add-school');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigateToEditPage = (id) => {
    navigate(`/edit-school/${id}`);
  };

  const handleNavigateToViewPage = (id) => {
    navigate(`/view-school/${id}`);
  };

  return (
    <div>
      <h1>School Listing</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
            {/* Filter Form */}
            <Grid container spacing={2}>
          {/* Category */}
          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                name="schoolCategory"
                value={filter.schoolCategory}
                onChange={handleFilterChange}
                label="Category"
              >
                {categories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.school_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Sub-Category */}
          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel>Sub-Category</InputLabel>
              <Select
                name="schoolSubCategory"
                value={filter.schoolSubCategory}
                onChange={handleFilterChange}
                label="Sub-Category"
              >
                {subCategories.map((subcategory) => (
                  <MenuItem key={subcategory._id} value={subcategory._id}>
                    {subcategory.SubCategory_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Country */}
          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel>Country</InputLabel>
              <Select
                name="countryId"
                value={filter.countryId}
                onChange={handleFilterChange}
                label="Country"
              >
                {countries.map((country) => (
                  <MenuItem key={country._id} value={country._id}>
                    {country.country_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* State */}
          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel>State</InputLabel>
              <Select
                name="stateId"
                value={filter.stateId}
                onChange={handleFilterChange}
                label="State"
              >
                {states.map((state) => (
                  <MenuItem key={state._id} value={state._id}>
                    {state.state_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* District */}
          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel>District</InputLabel>
              <Select
                name="districtId"
                value={filter.districtId}
                onChange={handleFilterChange}
                label="District"
              >
                {districts.map((district) => (
                  <MenuItem key={district._id} value={district._id}>
                    {district.district_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box textAlign="center" mt={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFilterSubmit}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Apply Filters'}
          </Button>
        </Box>
        {/* <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleNavigateToAddPage}
        >
          Create
        </Button> */}
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>School ID</TableCell>
              <TableCell>School Logo</TableCell>
              <TableCell>Principal Photo</TableCell>
              <TableCell>School Name</TableCell>
              <TableCell>Principal Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {schoolsToDisplay.length > 0 ? (
              schoolsToDisplay.map((school, index) => (
                <TableRow key={school._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{school.school_code}</TableCell>
                  {/* School Logo */}
                  <TableCell>
                    {school.school_logo ? (
                      <img
                        src={school.school_logo}
                        alt="School Logo"
                        style={{ width: 50, height: 50, objectFit: 'cover' }}
                      />
                    ) : (
                      'No Logo'
                    )}
                  </TableCell>
                  <TableCell>
                    {school.principal_photo ? (
                      <img
                        src={school.principal_photo}
                        alt="Principal Photo"
                        style={{ width: 50, height: 50, objectFit: 'cover' }}
                      />
                    ) : (
                      'No Photo'
                    )}
                  </TableCell>
                  <TableCell>{school.school_name}</TableCell>
                  <TableCell>{school.principal_name}</TableCell>
                  <TableCell>{school.school_address}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleNavigateToViewPage(school._id)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => handleNavigateToEditPage(school._id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleOpenDeleteDialog(school._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No schools found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
   
     {totalItems >= 10 &&( <Box display="flex" justifyContent="flex-end" padding={2}>
        <TablePagination
          component="div"
          count={totalItems}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage=""
          showFirstButton
          showLastButton
        />
      </Box>
    )}
      {/* Delete Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Delete School</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this school?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button
            onClick={() => handleDeleteSchool(selectedSchoolId)}
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar for feedback */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={error ? 'error' : 'success'}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SchoolList;
