import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import axios from 'axios';

function EditStudent({ open, onClose, category, onUpdate }) {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (open && category) {
      // Fetch student category data if category is provided
      const fetchCategoryData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/student/category/${category._id}`);
          console.log("response",response.data.data);
          
          setName(response.data.data.Student_category_name);
        } catch (err) {
          setError('Failed to fetch category data');
        }
      };
      fetchCategoryData();
    }
  }, [open, category]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/student/category/${category._id}`, { Student_category_name: name });
      onUpdate(); // Callback to refresh the list
      onClose(); // Close the modal after successful update
    } catch (err) {
      setError('Failed to update category');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Student  Category</DialogTitle>
      <DialogContent>
        {error && (
          <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError(null)}>
            <Alert onClose={() => setError(null)} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}
        <TextField
          autoFocus
          margin="dense"
          label="Student Category Name"
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditStudent;
