import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import AddPlace from './AddPlace';
import EditPlace from '../components/pages/EditPlace';

function PlaceList() {
  const [places, setPlaces] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedPlaceId, setSelectedPlaceId] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);

  useEffect(() => {
    fetchPlaces();
    fetchCountries();
    fetchStates();
    fetchDistricts();
    fetchTowns();
  }, [page, rowsPerPage, searchTerm]);

  const fetchPlaces = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/place`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          searchTerm,
        },
      });
      setPlaces(response.data.data);
      setTotalItems(response.data.pagination.totalItems);
    } catch (err) {
      setError("Error fetching places");
      setOpenSnackbar(true);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country`);
      setCountries(response.data.data);
    } catch (err) {
      console.error('Failed to fetch countries', err);
    }
  };

  const fetchStates = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state/without_pagination`);
      setStates(response.data.data);
    } catch (err) {
      console.error('Failed to fetch states', err);
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/district/without_pagination`);
      setDistricts(response.data.data);
    } catch (err) {
      console.error('Failed to fetch districts', err);
    }
  };

  const fetchTowns = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/town/without_pagination`);
      setTowns(response.data.data);
    } catch (err) {
      console.error('Failed to fetch towns', err);
    }
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleAddPlace = () => {
    handleCloseAddModal();
    fetchPlaces(); // Refresh the list after adding
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page on search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (placeId) => {
    setSelectedPlaceId(placeId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedPlaceId(null);
  };

  const handleDeletePlace = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/place/${id}`);
      setSnackbarMessage('Place deleted successfully');
      setOpenSnackbar(true);
      fetchPlaces(); // Refresh the list after deleting
    } catch (err) {
      setSnackbarMessage('Failed to delete place');
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenEditModal = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/place/${id}`);
      setSelectedPlace(response.data.data);
      setSelectedPlaceId(id);
      setOpenEditModal(true);
    } catch (err) {
      setError('Error fetching place details');
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedPlaceId(null);
    setSelectedPlace(null);
  };

  const handleEditPlace = () => {
    handleCloseEditModal();
    fetchPlaces(); // Refresh the list after editing
  };

  const getCountryName = (id) => {
    const country = countries.find((c) => c._id === id);
    return country ? country.country_name : 'Unknown';
  };

  const getStateName = (id) => {
    const state = states.find((s) => s._id === id);
    return state ? state.state_name : 'Unknown';
  };

  const getDistrictName = (id) => {
    const district = districts.find((d) => d._id === id);
    return district ? district.district_name : 'Unknown';
  };

  const getTownName = (id) => {
    const town = towns.find((s) => s._id === id);
    return town ? town.Town_name : 'Unknown'; // Ensure 'town_name' matches your data
  };

  return (
    <div>
      <h1>Place Listing</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
        >
          Create
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>Place Name</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>State</TableCell>
              <TableCell>District</TableCell>
              <TableCell>Town</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {places.length > 0 ? (
              places
              .filter(subject => subject.place_name.toLowerCase().includes(searchTerm.toLowerCase()))

              .map((place, index) => (
                <TableRow key={place._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{place.place_name}</TableCell>
                  <TableCell>{getCountryName(place.country)}</TableCell>
                  <TableCell>{getStateName(place.state)}</TableCell>
                  <TableCell>{getDistrictName(place.district)}</TableCell>
                  <TableCell>{getTownName(place.town)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenEditModal(place._id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteDialog(place._id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">No data available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems >=10 &&  (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      {/* Add Place Modal */}
      <AddPlace
        isOpen={openAddModal}
        onClose={handleCloseAddModal}
        onAdd={handleAddPlace}
        countries={countries}
        states={states}
        districts={districts}
        towns={towns}
      />
      {/* Edit Place Modal */}
      <Dialog open={openEditModal} onClose={handleCloseEditModal} fullWidth maxWidth="sm">
        <EditPlace
          isOpen={openEditModal}
          onClose={handleCloseEditModal}
          place={selectedPlace}
          countries={countries}
          states={states}
          districts={districts}
          towns={towns}
          onEditPlace={handleEditPlace}
        />
      </Dialog>
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-confirmation-dialog"
      >
        <DialogTitle id="delete-confirmation-dialog">
          Confirm Deletion
        </DialogTitle>
        <DialogContent>Are you sure you want to delete this place?</DialogContent>

        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            color="secondary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDeletePlace(selectedPlaceId)}
            color="primary"
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={error ? 'error' : 'success'}>
          {snackbarMessage || (error ? error : 'Operation successful')}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default PlaceList;
