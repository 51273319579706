import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import axios from 'axios';

function EditCountry({ open, onClose, countryId, onUpdate }) {
  const [countryName, setCountryName] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (open && countryId) {
      // Fetch country data when modal opens and countryId is provided
      const fetchCountryData = async () => {
        setFetching(true);
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country/${countryId}`);
          const { country_name, country_code } = response.data.data;
          setCountryName(country_name);
          setCountryCode(country_code);
        } catch (error) {
          setSnackbarMessage('Error fetching country details');
          setSnackbarOpen(true);
        } finally {
          setFetching(false);
        }
      };
      fetchCountryData();
    }
  }, [open, countryId]);

  const handleCountryNameChange = (e) => {
    setCountryName(e.target.value);
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handleCountrySubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/country/${countryId}`, {
        country_name: countryName,
        country_code: countryCode,
      });

      if (response.status === 200) {
        setSnackbarMessage('Country updated successfully');
        onUpdate(); // Notify parent to refresh data
        onClose(); // Close the modal
      } else {
        setSnackbarMessage('Failed to update country');
      }
    } catch (error) {
      setSnackbarMessage('Error occurred');
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Country</DialogTitle>
      <DialogContent>
        {fetching ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100px">
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleCountrySubmit}>
            <TextField
              label="Country Name"
              value={countryName}
              onChange={handleCountryNameChange}
              fullWidth
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              label="Country Code"
              value={countryCode}
              onChange={handleCountryCodeChange}
              fullWidth
              margin="normal"
              variant="outlined"
              required
            />
            <Box display="flex" justifyContent="center" marginTop={2}>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button type="submit" variant="contained" color="primary">
                  Update Country
                </Button>
              )}
            </Box>
          </form>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarMessage === 'Country updated successfully' ? 'success' : 'error'}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditCountry;
