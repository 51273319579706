import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { getToken } from "../../utils/AuthUtils";
import { useNavigate } from "react-router-dom";

const ListingPage = () => {
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [partners, setPartners] = useState({});
  const [partnerIds, setPartnerIds] = useState({});
  const [courses, setCourses] = useState({});
  const [categories, setCategories] = useState({});
  const [subCategories, setSubCategories] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page to 10
  const [totalItems, setTotalItems] = useState(0); 

  const navigate = useNavigate();
  const token = getToken();

  const fetchAssignedCourses = async () => {
    setLoading(true);
    try {
      const token = getToken();
      if (token) {
        const { data } = await axios.get(`${process.env.REACT_APP_BACKEND}/api/assign_course_to_partner`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setAssignedCourses(data.data);
        setTotalItems(data.pagination.totalItems);
      }
    } catch (error) {
      setError("Failed to fetch assigned courses.");
      console.error("Error fetching assigned courses:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch assigned courses
  useEffect(() => {
    fetchAssignedCourses();
  }, []);

  // Fetch partners, courses, categories, and sub-categories based on IDs
  useEffect(() => {
    const fetchPartnersCoursesCategories = async () => {
      if (assignedCourses.length === 0) return;

      const partnerIds = [...new Set(assignedCourses.map(assignment => assignment.partner_id))];
      const courseIds = [...new Set(assignedCourses.map(assignment => assignment.course_id))];

      try {
        const [partnersResponse, coursesResponse, categoriesResponse, subCategoriesResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND}/api/partner`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${process.env.REACT_APP_BACKEND}/api/courses`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category`, {
            headers: { Authorization: `Bearer ${token}` },
          }),
          axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category_sub`, {
            headers: { Authorization: `Bearer ${token}` },
          })
        ]);

        const partnersData = partnersResponse.data.data.reduce((acc, partner) => {
          acc[partner._id] = partner.partner_name;
          return acc;
        }, {});

        const partnersId = partnersResponse.data.data.reduce((acc, partner) => {
          acc[partner._id] = partner.partner_id;
          return acc;
        }, {});

        const coursesData = coursesResponse.data.data.reduce((acc, course) => {
          acc[course._id] = course.courses_name;
          return acc;
        }, {});

        const categoriesData = categoriesResponse.data.data.reduce((acc, category) => {
          acc[category._id] = category.courses_name;
          return acc;
        }, {});

        const subCategoriesData = subCategoriesResponse.data.data.reduce((acc, subCategory) => {
          acc[subCategory._id] = subCategory.courses_sub_category_name;
          return acc;
        }, {});

        setPartners(partnersData);
        setCourses(coursesData);
        setCategories(categoriesData);
        setSubCategories(subCategoriesData);
        setPartnerIds(partnersId);
      } catch (error) {
        setError("Failed to fetch partners, courses, categories, and sub-categories.");
        console.error("Error fetching partners, courses, categories, and sub-categories:", error);
      }
    };

    fetchPartnersCoursesCategories();
  }, [assignedCourses, token]);

  const handleSnackbarClose = () => {
    setError(null);
  };

  const handleAddCourse = () => {
    navigate('/assign_courses_to_partner');
  };

  const handleEditCourse = (id) => {
    navigate(`/assign_courses_to_partner/edit/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  const openDeleteDialog = (_id) => {
    setCourseToDelete(_id);
    setIsDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDialogOpen(false);
    setCourseToDelete(null);
  };

  const handleDeleteCourse = async () => {
    if (courseToDelete) {
      try {
        const token = getToken();
        if (token) {
          await axios.delete(`${process.env.REACT_APP_BACKEND}/api/assign_course_to_partner/${courseToDelete}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          closeDeleteDialog();
          fetchAssignedCourses(); // Call to fetch assigned courses again to refresh the list
        }
      } catch (err) {
        setError("Failed to delete the course.");
        console.error('Error deleting course:', err);
      }
    }
  };

// Filter and paginate assigned courses
const filteredCourses = assignedCourses.filter(assignment => {
  const courseName = courses[assignment.course_id] || '';
  const partnerName = partners[assignment.partner_id] || '';
  const courseCategory = categories[assignment.course_category_id] || '';
  const partnerId = partnerIds[assignment.partner_id] || '';
  const courseSubCategory = subCategories[assignment.course_sub_category_id] || '';

  return courseName.toLowerCase().includes(searchTerm.toLowerCase()) ||
         partnerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
         courseCategory.toLowerCase().includes(searchTerm.toLowerCase()) ||
         partnerId.toLowerCase().includes(searchTerm.toLowerCase()) ||
         courseSubCategory.toLowerCase().includes(searchTerm.toLowerCase());
});


  const paginatedCourses = filteredCourses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Container maxWidth="xl" sx={{ padding: "20px" }}>
      <Typography variant="h4" gutterBottom>
        Assigned Courses List
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {error && (
            <Snackbar open={error !== null} autoHideDuration={6000} onClose={handleSnackbarClose}>
              <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: "100%" }}>
                {error}
              </Alert>
            </Snackbar>
          )}

          <Box display="flex" flexDirection="column" padding={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                onChange={(event) => setSearchTerm(event.target.value)}
                value={searchTerm}
                sx={{ width: '300px' }}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddCourse}
              >
                Assign Course To Partner
              </Button>
            </Box>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Serial No.</TableCell>
                    <TableCell>PartnerID</TableCell>
                    <TableCell>Partner Name</TableCell>
                    <TableCell>Course Category</TableCell>
                    <TableCell>Course Sub-Category</TableCell>
                    <TableCell>Assigned Course Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedCourses.map((assignment, index) => (
                    <TableRow key={assignment._id}>
                      <TableCell>{(page * rowsPerPage) + index + 1}</TableCell>
                      <TableCell>{partnerIds[assignment.partner_id] || "Loading..."}</TableCell>
                      <TableCell>{partners[assignment.partner_id] || "Loading..."}</TableCell>
                      <TableCell>{categories[assignment.course_category_id] || "Loading..."}</TableCell>
                      <TableCell>{subCategories[assignment.course_sub_category_id] || "Loading..."}</TableCell>
                      <TableCell>{courses[assignment.course_id] || "Loading..."}</TableCell>
                      <TableCell>
                        <IconButton color="primary" onClick={() => handleEditCourse(assignment._id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => openDeleteDialog(assignment._id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          
          {assignedCourses.length >= 10 && ( // Show pagination only if there are enough items
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={totalItems} // Use totalItems instead of assignedCourses.length
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
          
          {/* Confirmation Dialog */}
          <Dialog open={isDialogOpen} onClose={closeDeleteDialog}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <Typography>Are you sure you want to delete this course?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeleteDialog}>Cancel</Button>
              <Button color="error" onClick={handleDeleteCourse}>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Container>
  );
};

export default ListingPage;
