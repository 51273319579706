import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogActions, Button, TextField, Box } from '@mui/material';
import axios from 'axios';

function EditPlace({ isOpen, onClose, place, countries, states, districts, towns, onEditPlace }) {
  const [placeName, setPlaceName] = useState(place ? place.place_name : "");
  const [country, setCountry] = useState(place ? place.country : "");
  const [state, setState] = useState(place ? place.state : "");
  const [district, setDistrict] = useState(place ? place.district : "");
  const [town, setTown] = useState(place ? place.town : "");

  useEffect(() => {
    if (place) {
      setPlaceName(place.place_name);
      setCountry(place.country);
      setState(place.state);
      setDistrict(place.district);
      setTown(place.town);
    }
  }, [place]);

  const handleEditPlace = async () => {
    try {
      const updatedPlace = {
        place_name: placeName,
        country,
        state,
        district,
        town,
      };

      await axios.put(`${process.env.REACT_APP_BACKEND}/api/place/${place._id}`, updatedPlace);
      onEditPlace(); // Callback to refresh the place list
    } catch (error) {
      console.error('Error updating place', error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Edit Place</DialogTitle>
      <Box padding={2}>
        <TextField
          label="Place Name"
          variant="outlined"
          value={placeName}
          onChange={(e) => setPlaceName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Country"
          variant="outlined"
          select
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          fullWidth
          margin="normal"
          SelectProps={{ native: true }}
        >
          {countries.map((country) => (
            <option key={country._id} value={country._id}>
              {country.country_name}
            </option>
          ))}
        </TextField>
        <TextField
          label="State"
          variant="outlined"
          select
          value={state}
          onChange={(e) => setState(e.target.value)}
          fullWidth
          margin="normal"
          SelectProps={{ native: true }}
        >
          {states.map((state) => (
            <option key={state._id} value={state._id}>
              {state.state_name}
            </option>
          ))}
        </TextField>
        <TextField
          label="District"
          variant="outlined"
          select
          value={district}
          onChange={(e) => setDistrict(e.target.value)}
          fullWidth
          margin="normal"
          SelectProps={{ native: true }}
        >
          {districts.map((district) => (
            <option key={district._id} value={district._id}>
              {district.district_name}
            </option>
          ))}
        </TextField>
        <TextField
          label="Town"
          variant="outlined"
          select
          value={town}
          onChange={(e) => setTown(e.target.value)}
          fullWidth
          margin="normal"
          SelectProps={{ native: true }}
        >
          {towns.map((town) => (
            <option key={town._id} value={town._id}>
              {town.Town_name}
            </option>
          ))}
        </TextField>
      </Box>
      <DialogActions>
        <Button onClick={handleEditPlace} color="primary">
          Save
        </Button>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditPlace;
