import Axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const tokenKey = 'token_key';

export function getToken() {
  return localStorage.getItem(tokenKey);
}

export function saveToken(token) {
  document.cookie = `${tokenKey}=${token}; Path=/; HttpOnly; Secure`;
}

export function removeToken() {
  document.cookie = `${tokenKey}=; Path=/; Expires=Thu, 01 Jan 1970 00:00 GMT`;
  localStorage.removeItem(tokenKey);
}

export async function verifyToken() { // Marked as async
    Axios.defaults.withCredentials = true;
    const token = getToken();
    
    if (!token) {
      return false; // No token found
    }
  
    try {
      // Make an authenticated request to verify the token
      const response = await Axios.get(`${process.env.REACT_APP_BACKEND}/auth/verify`, {
        headers: {
          'Authorization': `Bearer ${token}` // Include the token in the Authorization header
        }
      });
      
      // Return the status from the backend response
      return response.data.status;
    } catch (error) {
      console.error('Error verifying token:', error);
      return false; // Return false if there was an error
    }
}

export function getRole() {
  const token = getToken();
  if (!token) {
    return null;
  }
  const decodedToken = jwtDecode(token); // Use named import
  console.log(decodedToken);
  
  return decodedToken.role_name;
}
