import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const EditCarrierPlanner = () => {
  const { id } = useParams(); // Get the carrier planner ID from the URL
  const [formData, setFormData] = useState({
    title: '',
    image_url: '',  // Initialize as an empty string for the URL
    redirected_url: '',
    start_date: '',
    end_date: '',
    is_active: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCarrierPlanner = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/carrier_planner/${id}`);
        console.log(response.data); // Log the response to check its structure
        setFormData({
          title: response.data.data.title, // Accessing the correct path
          image_url: response.data.data.image_url,
          redirected_url: response.data.data.redirected_url,
          start_date: response.data.data.start_date.split('T')[0], // Format for date input
          end_date: response.data.data.end_date.split('T')[0],     // Format for date input
          is_active: response.data.data.is_active,
        });
      } catch (err) {
        console.error(err.response ? err.response.data : err.message); // Log the error details
        setError('Error fetching carrier planner data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCarrierPlanner();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'file') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: e.target.files[0],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/carrier_planner/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      navigate('/career_planner_list');
    } catch (err) {
      const errorMessage = err.response?.data?.message || 'Error updating carrier planner';
      setError(errorMessage);
    }
  };

  return (
    <Box padding={2}>
      <Typography variant="h4">Edit Carrier Planner</Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <input
            type="file"
            name="image_url"
            accept="image/*"
            onChange={handleChange}
          />
          {formData.image_url && (
            <img 
              src={formData.image_url} 
              alt="Current" 
              style={{ width: '100px', height: 'auto', marginTop: '10px' }}
            />
          )}
          <TextField
            label="Redirected URL"
            name="redirected_url"
            value={formData.redirected_url}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="Start Date"
            name="start_date"
            type="date"
            value={formData.start_date}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            label="End Date"
            name="end_date"
            type="date"
            value={formData.end_date}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
            required
          />
          <Button type="submit" variant="contained" color="primary">
            Update Carrier Planner
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </form>
      )}
    </Box>
  );
};

export default EditCarrierPlanner;
