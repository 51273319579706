import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';

function EditProject({ open, onClose, subject, onUpdate }) {
  const [projectName, setProjectName] = useState(subject.project_category_name);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (subject) {
      setProjectName(subject.project_category_name); // Set the project name from props
    }
  }, [subject]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!projectName.trim()) {
      setSnackbarMessage('Project name cannot be empty');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/project/category/${subject._id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ project_category_name: projectName }),
      });

      if (response.ok) {
        setSnackbarMessage('Project updated successfully');
        setSnackbarSeverity('success');
        onUpdate(); // Notify parent to refresh data
        onClose(); // Close the modal
      } else {
        setSnackbarMessage('Failed to update project');
        setSnackbarSeverity('error');
      }
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage('Error occurred while updating project');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Project Category</DialogTitle>
      <DialogContent>
        <form onSubmit={handleFormSubmit}>
          <TextField
            label="Project Category Name"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            fullWidth
            margin="normal"
            variant="outlined"
            required
            autoFocus
          />
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Update Project
              </Button>
            )}
          </div>
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditProject;
