import React, { useState } from "react";
import {
  TextField,
  Button,
  Paper,
  Typography,
  Box,
  Grid // Import Grid from @mui/material
} from "@mui/material";
import Axios from "axios";
import "./login.css";

function Signup() {
  const [username, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_BACKEND}/auth/signup`,
        {
          username,
          email,
          password,
        }
      );
      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid container component="main" className="login-root">
      <Grid item xs={12} sm={6} md={7} className="login-image-section">
        <Box className="login-overlay">
          <Typography variant="h4" color="white" gutterBottom>
            Join our community and get started with LMS!
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={5}
        component={Paper}
        elevation={6}
        square
        className="login-form-section"
      >
        <Box className="login-box">
          <Typography component="h1" variant="h5">
            Sign Up for LMS
          </Typography>
          <form onSubmit={handleSignup} className="login-form">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email"
              name="email"
              type="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="login-submit-button"
            >
              Sign Up
            </Button>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Signup;
