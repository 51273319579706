import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from "./components/Login"
import Register from "./components/Signup"
import Home from "./components/Home"
import Dash from './components/Dash';
import AddPartner from './components/pages/partner/AddPartnerModal';
import PartnerList from './components/pages/partner/PartnerList';
import Layout from "./components/Layout";
import SubCategoryPartnerList from "./components/pages/partner/SubCategoryPartnerList"
import SchoolCategoryList from './components/pages/partner/SchoolCategoryList';
import AddSchools from './components/pages/partner/AddSchools';
import SchoolSubList from './components/pages/partner/SchoolSubList';
import TeacherCategoryList from './components/pages/partner/TeacherCategoryList';
import TeacherSubList from './components/pages/partner/TeacherSubList';
import StudentCategoryList from './components/pages/partner/StudentCategoryList';
import StudentSubList from './components/pages/partner/StudentSubList';
import ProductCategory from './components/pages/partner/ProductCategory';
import ProductSubList from './components/pages/partner/ProductSubList';
import ProjectList from './components/pages/partner/ProjectList';
import ProjectSubList from './components/pages/partner/ProjectSubList';
import CoursesCategoryList from './components/pages/partner/CourseCategoryList';
import CoursesSubList from './components/pages/partner/CoursesSubList'
import CountryList from './components/CountryList';
import StateList from './components/StateList';
import DistrictList from './components/DistrictListing';
import TownList from './components/TownList';
import PlaceList from './components/PlaceList';

// import Login from "./components/Login";
// import Register from "./components/Signup";
// import Home from "./components/Home";
// import Cred from "./components/cred";
// import Layout from "./components/Layout";

import ProtectedAdminRoute from "./components/route_protection/ProtectedAdminRoute"
import ProtectedCompanyRoute from "./components/route_protection/ProtectedCompanyRoute"
import ProtectedPartnerRoute from "./components/route_protection/ProtectedPartnerRoute"

import StudentTable from "./components/pages/partner/Students";
import PartnerPage from "./components/pages/partner/PartnerPage"
import AddSchool from "./components/pages/partner/AddSchool";
import UpdateSchool from "./components/pages/partner/UpdateSchool"


import SchoolList from "./components/pages/SchoolList"
import AddSchoolMain from './components/pages/AddSchoolMain';
import EditSchoolMain from './components/pages/EditSchoolMain';
import ViewSchoolPage from './components/pages/ViewSchoolPage';
import DepartmentList from './components/pages/DepartmentList';
import AddDepartment from './components/pages/AddDepartment';
import EditDepartment from './components/pages/EditDepartment';
import ProductsList from './components/pages/ProductsList';
import AddProducts from './components/pages/AddProductsList';
import EditProducts from './components/pages/EditProducts';
import CoursesList from './components/pages/partner/CoursesList';
import AddCoursePage from './components/pages/AddCoursePage';
import EditCoursePage from './components/pages/EditCoursePage';
import CourseListMain from './components/pages/CoursesListMain';
import NewsLetterList from './components/pages/NewsLetterPage';
import PartnerListPage from './components/pages/PartnerLListPage';
import AddPartnerPage from './components/pages/AddPartnerPage';
import EditPartnerPage from './components/pages/EditPartnerPage';
import AddNewsletterPage from './components/pages/AddNewsletterPage';
import EditNewsletterPage from './components/pages/EditNewsletterPage';
import SliderListPage from './components/pages/SliderListPage';
import EditSliderPage from './components/pages/EditSliderPage';
import AddSliderPage from './components/pages/AddSliderPage';
import EventListPage from './components/pages/EventListPage';
import AddEvent from './components/pages/AddEvent';
import EditEvent from './components/pages/EditEvent';
import DepartmentCategoryList from './components/pages/partner/DepartmentCategoryList';
import DepartmentSubCategory from './components/pages/partner/DepartmentSubCategory';
import UploadContent from "./components/UploadContent";
import List from "./components/List";
import Chapters from './components/pages/Chapters';
import ChaptersTable from './components/pages/ChaptersTable';
import EditChapter from './components/pages/EditChapter';
import Dashboard from './components/pages/Dashboard';
import AssessmentForm from "./components/pages/assessment/AssessmentForm";
import Students from './components/pages/list/Students';
import Teacher from './components/pages/list/Teachers';
import Parent from './components/pages/list/Parents';
import Unit from './components/pages/units/UnitList';



function App() {
  return (
    <BrowserRouter>
      <Routes>

   <Route element={<Layout />}>
          
          <Route path="/unitlist" element={<Unit />} />
          <Route path="/studlist" element={<Students />} />
          <Route path="/teachlist" element={<Teacher />} />
          <Route path="/parlist" element={<Parent />} />
          <Route path="/uploadunit" element={<UploadContent />} />
          <Route path="/listhi" element={<List />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/register" element={<Register />} />
          <Route path="/home" element={<Home />} />
          <Route path="/home_ms" element={<Dash />} />
          <Route path="/add_partner" element={<AddPartner />} />
          <Route path="/partner_category" element={<PartnerList />} />
          <Route path="/sub_category" element={<SubCategoryPartnerList />} />
          <Route path="/school_category" element={<SchoolCategoryList />} />
          <Route path="/add_school_category" element={<AddSchools />} />
          <Route path="/school_sub_category" element={<SchoolSubList />} />
          <Route path="/teachers_category" element={<TeacherCategoryList />} />
          <Route path="/teachers_sub_category" element={<TeacherSubList />} />
          <Route path="/students_category" element={<StudentCategoryList />} />
          <Route path="/students_sub_category" element={<StudentSubList />} />
          <Route path="/product/category" element={<ProductCategory />} />
          <Route path="/product/sub_category" element={<ProductSubList />} />
          <Route path="/project/category" element={<ProjectList />} />
          <Route path="/project/sub_category" element={<ProjectSubList />} />
          <Route path="/courses/category" element={<CoursesCategoryList />} />
          <Route path="/courses/sub_category" element={<CoursesSubList />} />
          <Route path="/department/category" element={<DepartmentCategoryList />} />
          <Route path="/department/sub_category" element={<DepartmentSubCategory />} />

          {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

          {/*  country */}
          <Route path="/country_list" element={<CountryList />} />
          <Route path="/state_list" element={<StateList />} />
          <Route path="/district_list" element={<DistrictList />} />
          <Route path="/town_list" element={<TownList />} />
          <Route path="/place_list" element={<PlaceList />} />

          {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          {/* School Listing */}
          <Route path="/school_list" element={<SchoolList />} />
          <Route path="/add-school" element={<AddSchoolMain />} />
          <Route path="/edit-school/:id" element={<EditSchoolMain />} />
          <Route path="/view-school/:id" element={<ViewSchoolPage />} />
          {/* Department */}
          <Route path="/department_list" element={<DepartmentList />} />
          {/* add not working */}
          <Route path="/departments/add" element={<AddDepartment />} />

          <Route path="/departments/edit/:id" element={<EditDepartment />} />
          {/* Product creation */}
          <Route path="/product_list" element={<ProductsList />} />
          <Route path="/products/add" element={<AddProducts />} />
          <Route path="/products/edit/:id" element={<EditProducts />} />
          {/* Courses crreation */}
          <Route path="/courses_list" element={<CourseListMain />} />
          <Route path="/courses/add" element={<AddCoursePage />} />
          <Route path="/courses/edit/:id" element={<EditCoursePage />} />
          {/* NewsLetter Creation */}
          <Route path="/newsletter_list" element={<NewsLetterList />} />
          <Route path="/newsletters/add" element={<AddNewsletterPage />} />\
          <Route path="/newsletters/edit/:id" element={<EditNewsletterPage />} />\

          {/* Partner Creation */}
          <Route path="/partner_list" element={<PartnerListPage />} />
          <Route path="/partners/add" element={<AddPartnerPage />} />
          <Route path="/partners/edit/:id" element={<EditPartnerPage />} />

          {/* Slider Creation */}
          <Route path="/slider_list" element={<SliderListPage />} />
          <Route path="/sliders/add" element={<AddSliderPage />} />
          <Route path="/sliders/edit/:id" element={<EditSliderPage />} />
          {/* chapter */}
          <Route path="/chapters_add" element={<Chapters />} />
          <Route path="/chapters" element={<ChaptersTable />} />
          <Route path="/edit_chapter/:id" element={<EditChapter />} />

{/* Event Creation */}
<Route path="/event_list" element={<EventListPage />} />
<Route path="/events/add" element={<AddEvent />} />
<Route path="/events/edit/:id" element={<EditEvent />} />
{/* Assesment */}
<Route path="/assessment" element={<AssessmentForm />} />

{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

        </Route>
        <Route path="/" element={<Login />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route element={<Layout />}>

          {/* ----------------------------------------Admin routes---------------------------------------- */}
          <Route path="super_admin" element={<ProtectedAdminRoute><Dashboard /></ProtectedAdminRoute>} />
          <Route path="uploadunit" element={<ProtectedAdminRoute><UploadContent /></ProtectedAdminRoute>} />


          {/* ----------------------------------------Company routes---------------------------------------- */}
          <Route path="company" element={<ProtectedCompanyRoute><StudentTable /></ProtectedCompanyRoute>} />
          <Route path="partner/view" element={<ProtectedPartnerRoute><PartnerPage /></ProtectedPartnerRoute>} />


          {/* ----------------------------------------Partner routes---------------------------------------- */}
          <Route path="partner" element={<ProtectedPartnerRoute><StudentTable /></ProtectedPartnerRoute>} />
          <Route path="partner/addshool" element={<ProtectedPartnerRoute><AddSchool /></ProtectedPartnerRoute>} />
          <Route path="partner/updateshool" element={<ProtectedPartnerRoute><UpdateSchool /></ProtectedPartnerRoute>} />


        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
