import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
  Switch,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility'; // Import the Visibility icon
import { useNavigate } from 'react-router-dom';

const PartnerListPage = () => {
  const [partners, setPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [partnerToDelete, setPartnerToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState('success');
  const [isDeleting, setIsDeleting] = useState(false);

  const navigate = useNavigate();

  const fetchPartners = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner`, {
        params: { searchTerm },
      });
      setPartners(response.data.data);
    } catch (err) {
      console.error(err);
      setError('Error fetching partners');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPartners();
  }, [searchTerm]);

  const handleAddPartner = () => navigate('/partners/add');
  const handleEditPartner = (id) => navigate(`/partners/edit/${id}`);
  const handleViewPartner = (id) => navigate(`/partners/view/${id}`); // New function for viewing partner details

  const handleDeleteConfirmation = (partner) => {
    setPartnerToDelete(partner);
    setOpenDialog(true);
  };

  const handleDeletePartner = async () => {
    setIsDeleting(true);
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/partner/${partnerToDelete._id}`);
      setOpenDialog(false);
      fetchPartners();
      setSnackbarMessage("Partner deleted successfully");
      setSnackbarType("success");
      setSnackbarOpen(true);
    } catch {
      setSnackbarMessage("Error deleting partner");
      setSnackbarType("error");
      setSnackbarOpen(true);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleToggleStatus = async (id, status) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/partner/${id}`, { status: status === 1 ? 0 : 1 });
      fetchPartners();
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  return (
    <div>
      <h1>Partners</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ width: '300px' }}
        />
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddPartner}>
          Create
        </Button>
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial No.</TableCell>
                  <TableCell>Partner Id</TableCell>
                  <TableCell>Partner Name</TableCell>
                  <TableCell>Contact Person</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Agreement Start Date</TableCell>
                  <TableCell>Agreement End Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partners.length > 0 ? (
                  partners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((partner, index) => (
                    <TableRow key={partner._id}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell>{partner.partner_id}</TableCell>
                      <TableCell>{partner.partner_name}</TableCell>
                      <TableCell>{partner.contact_person}</TableCell>
                      <TableCell>{partner.mobile}</TableCell>
                      <TableCell>{partner.email}</TableCell>
                      <TableCell>{new Date(partner.agreement_start_date).toLocaleDateString()}</TableCell>
                      <TableCell>{new Date(partner.agreement_end_date).toLocaleDateString()}</TableCell>
                      <TableCell>
                        <Switch
                          checked={partner.status === 1}
                          onChange={() => handleToggleStatus(partner._id, partner.status)}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton color="primary" onClick={() => handleViewPartner(partner._id)}>
                          <VisibilityIcon /> {/* View icon */}
                        </IconButton>
                        <IconButton color="primary" onClick={() => handleEditPartner(partner._id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => handleDeleteConfirmation(partner)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">No partners found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {partners.length >= 9 && ( 
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={partners.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the partner "{partnerToDelete?.partner_name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">Cancel</Button>
          <Button onClick={handleDeletePartner} color="error" disabled={isDeleting}>
            {isDeleting ? <CircularProgress size={24} color="inherit" /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PartnerListPage;
