import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, CircularProgress, Snackbar, Alert, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import axios from 'axios';

function EditCourseSub({ open, onClose, categoryId, onUpdate }) {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Fetch category details if categoryId is available
        if (categoryId) {
          const categoryResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category_sub/${categoryId}`);
          setName(categoryResponse.data.data.courses_sub_category_name);
          setSelectedCategory(categoryResponse.data.data.category_id); // Update with existing category
        }

        // Fetch categories for the dropdown
        const categoriesResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category`);
        const activeCategories = categoriesResponse.data.data.filter(category => category.status === 1); // Filter by status
        setCategories(activeCategories);

      } catch (err) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [categoryId]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const updateData = { courses_sub_category_name: name, category_id: selectedCategory };
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/courses_category_sub/${categoryId}`, updateData);
      setSuccess(true);
      onUpdate();
      onClose();
    } catch (err) {
      setError('Failed to update category');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Course Sub-Category</DialogTitle>
      <DialogContent>
        {error && (
          <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError(null)}>
            <Alert onClose={() => setError(null)} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}
        {success && (
          <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
            <Alert onClose={() => setSuccess(false)} severity="success">
              Course Sub-Category updated successfully
            </Alert>
          </Snackbar>
        )}
         <FormControl fullWidth margin="normal">
          <InputLabel>Category</InputLabel>
          <Select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            disabled={loading}
          >
            {categories.length > 0 ? (
              categories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.courses_name} {/* Ensure this matches the actual field in the API response */}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No categories available</MenuItem>
            )}
          </Select>
        </FormControl>
        <TextField
          autoFocus
          margin="dense"
          label="Course Sub-Category Name"
          fullWidth
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={loading}
        />
       
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditCourseSub;
