import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Switch
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const CourseListMain = () => {
  const [courses, setCourses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department`);
        setDepartments(response.data.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    const fetchCourses = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses`, {
          params: {
            page: page + 1, // Page numbers might be 1-based
            limit: rowsPerPage,
            searchTerm,
          },
        });
        setCourses(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      } catch (err) {
        console.error('Error fetching courses:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDepartments();
    fetchCourses();
  }, [page, rowsPerPage, searchTerm]);

  const handleToggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/courses/${id}`, { status: newStatus });
      setCourses(prevCourses => prevCourses.map(course =>
        course._id === id ? { ...course, status: newStatus } : course
      ));
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const getDepartmentName = (departmentId) => {
    const department = departments.find(dep => dep._id === departmentId);
    return department ? department.department_name : 'Unknown';
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleAddCourse = () => {
    navigate('/courses/add');
  };

  const handleEditCourse = (id) => {
    navigate(`/courses/edit/${id}`);
  };

  const handleDeleteCourse = async () => {
    if (courseToDelete) {
      try {
        await axios.delete(`${process.env.REACT_APP_BACKEND}/api/courses/${courseToDelete}`);
        setCourseToDelete(null);
        setIsDialogOpen(false);
        // Refresh the list after deletion
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses`, {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            searchTerm,
          },
        });
        setCourses(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      } catch (err) {
        console.error('Error deleting course:', err);
      }
    }
  };

  const openDeleteDialog = (id) => {
    setCourseToDelete(id);
    setIsDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDialogOpen(false);
    setCourseToDelete(null);
  };

  return (
    <div>
      <h1>Courses</h1>
      <Box display="flex" flexDirection="column" padding={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={handleSearch}
            value={searchTerm}
            sx={{ width: '300px' }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddCourse}
          >
            Add Course
          </Button>
        </Box>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial No.</TableCell>
                  <TableCell>Course Name</TableCell>
                  <TableCell>Details</TableCell>
                  <TableCell>No. Of Chapters</TableCell>
                  <TableCell>Department</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {courses.length > 0 ? (
                  courses
                    .filter(course => course.courses_name.toLowerCase().includes(searchTerm.toLowerCase()))
                    .map((course, index) => (
                      <TableRow key={course._id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{course.courses_name}</TableCell>
                        <TableCell>{course.course_details}</TableCell>
                        <TableCell>{course.no_of_chapters}</TableCell>
                        <TableCell>{getDepartmentName(course.department_id)}</TableCell>
                        <TableCell>
                          <Switch
                            checked={course.status === 1}
                            onChange={() => handleToggleStatus(course._id, course.status)}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton color="primary" onClick={() => handleEditCourse(course._id)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton color="error" onClick={() => openDeleteDialog(course._id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No courses found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Box>

      {/* Confirmation Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={closeDeleteDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this course?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button
            color="error"
            onClick={handleDeleteCourse}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CourseListMain;
