import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material';
import { getToken } from '../../utils/AuthUtils';

const ViewSchoolPage = () => {
  const { id } = useParams();
  const [school, setSchool] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = getToken();
        if (!token) {
          setError('No authentication token found');
          setLoading(false);
          return;
        }
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school_category?page=1&limit=50`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        
        setCategories(response.data.data);
      } catch (err) {
        console.error(err); // Log error for debugging
        setError('Failed to fetch categories');
      }
    };

    const fetchSubCategories = async () => {
      try {
        const token = getToken();
        if (!token) {
          setError('No authentication token found');
          setLoading(false);
          return;
        }
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sub_school_category?page=1&limit=10`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        
        setSubCategories(response.data.data);
      } catch (err) {
        console.error(err); // Log error for debugging
        setError('Failed to fetch subcategories');
      }
    };

    const fetchSchoolDetails = async () => {
      try {
        const token = getToken();
        if (!token) {
          setError('No authentication token found');
          setLoading(false);
          return;
        }
        
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        
        setSchool(response.data.data);
      } catch (err) {
        console.error(err); // Log error for debugging
        setError('Failed to fetch school details');
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
    fetchSubCategories();
    fetchSchoolDetails();
  }, [id]);

  const getCategoryName = (id) => {
    const category = categories.find(cat => cat._id === id);
    return category ? category.school_name : 'Unknown';
  };

  const getSubCategoryName = (id) => {
    const subCategory = subCategories.find(subCat => subCat._id === id);
    return subCategory ? subCategory.SubCategory_name : 'Unknown';
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        School Details
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <div style={{ color: 'red' }}>{error}</div>
      ) : (
        <Paper elevation={3} style={{ padding: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">School Name:</Typography>
              <Typography variant="body1">{school.school_name || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">School Code:</Typography>
              <Typography variant="body1">{school.school_code || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Category:</Typography>
              <Typography variant="body1">{getCategoryName(school.school_category) || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Sub-category:</Typography>
              <Typography variant="body1">{getSubCategoryName(school.school_sub_category) || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Address:</Typography>
              <Typography variant="body1">{school.school_address || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Pin Code:</Typography>
              <Typography variant="body1">{school.school_pin_code || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Website:</Typography>
              <Typography variant="body1">{school.school_website || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Email:</Typography>
              <Typography variant="body1">{school.school_email || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Principal Name:</Typography>
              <Typography variant="body1">{school.principal_name || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Principal Contact No:</Typography>
              <Typography variant="body1">{school.principal_contact_no || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Principal Email:</Typography>
              <Typography variant="body1">{school.principal_email || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Admin Name:</Typography>
              <Typography variant="body1">{school.admin_name || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Admin Contact No:</Typography>
              <Typography variant="body1">{school.admin_contact_no || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Admin Email</Typography>
              <Typography variant="body1">{school.admin_email || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Total Number of Students:</Typography>
              <Typography variant="body1">{school.total_number_of_students || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Social Media Links:</Typography>
              <Typography variant="body1">LinkedIn: {school.social_media_linkedin || 'N/A'}</Typography>
              <Typography variant="body1">Instagram: {school.social_media_instagram || 'N/A'}</Typography>
              <Typography variant="body1">Facebook: {school.social_media_facebook || 'N/A'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={() => window.history.back()}>
                Back
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
};

export default ViewSchoolPage;
