import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, CircularProgress, Snackbar, Alert, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import axios from 'axios';

function EditProductSub({ open, onClose, subject, categories, onUpdate }) {
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (subject) {
      setName(subject.product_sub_category_name);
      setCategory(subject.product_id);
    }
  }, [subject]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/product/category_sub/${subject._id}`, {
        product_sub_category_name: name,
        product_id: category
      });
      onUpdate(); // Callback to refresh the list
      onClose();
    } catch (err) {
      setError('Failed to update sub-category');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Sub-Category</DialogTitle>
      <DialogContent>
        {error && (
          <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError(null)}>
            <Alert onClose={() => setError(null)} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}
          <FormControl fullWidth margin="dense">
          <InputLabel>Category*</InputLabel>
          <Select
            value={category}
            required
            onChange={(e) => setCategory(e.target.value)}
          >
            {categories.map((cat) => (
              <MenuItem key={cat._id} value={cat._id}>
                {cat.product_category_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          autoFocus
          margin="dense"
          label="Sub Category Name"
          fullWidth
          variant="outlined"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditProductSub;
