import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Button,
  Grid,
} from '@mui/material';
import { getToken } from '../../utils/AuthUtils';

const ViewSchoolPage = () => {
  const { id } = useParams();
  const [school, setSchool] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSchoolDetails = async () => {
      try {
        const token = getToken();
        if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school/${id}`,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setSchool(response.data.data);
      }
     }catch (err) {
        setError('Failed to fetch school details');
      } finally {
        setLoading(false);
      }
    };

    fetchSchoolDetails();
  }, [id]);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        School Details
      </Typography>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div style={{ color: 'red' }}>{error}</div>
      ) : (
        <Paper elevation={3} style={{ padding: 20 }}>
          <Grid container spacing={3}>
           
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">School Name:</Typography>
              <Typography variant="body1">{school.school_name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Address:</Typography>
              <Typography variant="body1">{school.school_address}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Pin Code:</Typography>
              <Typography variant="body1">{school.school_pin_code}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Principal Name:</Typography>
              <Typography variant="body1">{school.principal_name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Principal Contact No:</Typography>
              <Typography variant="body1">{school.principal_contact_no}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Principal Email:</Typography>
              <Typography variant="body1">{school.principal_email}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Admin Name:</Typography>
              <Typography variant="body1">{school.admin_name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Admin Contact No:</Typography>
              <Typography variant="body1">{school.admin_contact_no}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Total Number of Students:</Typography>
              <Typography variant="body1">{school.total_number_of_students}</Typography>
            </Grid>
           
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Coordinator Contact No:</Typography>
              <Typography variant="body1">{school.rob_lab_coordntr_contact_no}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">Coordinator Email:</Typography>
              <Typography variant="body1">{school.rob_lab_coordntr_email}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">School Website:</Typography>
              <Typography variant="body1">{school.school_website}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">School Email:</Typography>
              <Typography variant="body1">{school.school_email}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Social Media Links:</Typography>
              <Typography variant="body1">LinkedIn: {school.social_media_linkedin}</Typography>
              <Typography variant="body1">Instagram: {school.social_media_instagram}</Typography>
              <Typography variant="body1">Facebook: {school.social_media_facebook}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Status:</Typography>
              <Typography variant="body1">{school.status === 1 ? 'Active' : 'Inactive'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={() => window.history.back()}>
                Back
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
};

export default ViewSchoolPage;
