import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const EditEventPage = () => {
  const { id } = useParams(); // Get the event ID from the URL params
  const [formData, setFormData] = useState({
    event_title: '',
    event_description: '',
    event_image: null, // Initialize with an empty string for the image
    event_coordinator: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [imagePreview, setImagePreview] = useState(''); 
  const navigate = useNavigate();

  // Fetch event details when the component mounts
  useEffect(() => {
    const fetchEvent = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/event/${id}`);
        const eventData = response.data.data;
        setFormData({
          event_title: eventData.event_title,
          event_description: eventData.event_description,
          event_coordinator: eventData.event_coordinator,
          event_image: eventData.event_image, // Keep the existing image URL
        });
        setImagePreview(eventData.event_image); // Set the initial image preview
      } catch (err) {
        setError('Error fetching event details');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    
      if (type === 'file') {
        setFormData((prevData) => ({
          ...prevData,
          event_image: files[0], // Store the file object
        }));
      setImagePreview(URL.createObjectURL(files[0])); // Set the image preview
    } else {
      setFormData({
        ...formData,
        [name]: value, // Update form data for text fields
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    for (const key in formData) {
      if (key === 'event_image' && formData.event_image instanceof File) {
        data.append(key, formData.event_image);
      } else if (key === 'event_image') {
        data.append(key, formData.event_image); // Append the existing image URL
      } else {
        data.append(key, formData[key]);
      }
    }

    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/event/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      navigate('/event_list'); // Redirect to the event list page after update
    } catch (err) {
      setError('Error updating event');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setFormData({
      ...formData,
      event_image: formData.event_image, // Retain the existing image if user removes the preview
    });
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Edit Event
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            name="event_title"
            label="Event Title"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.event_title} // Populate with existing title
            onChange={handleChange}
            required
          />
          <TextField
            name="event_description"
            label="Event Description"
            variant="outlined"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            value={formData.event_description}
            onChange={handleChange}
            required
          />
          <input
            name="event_image"
            type="file"
            accept="image/*"
            onChange={handleChange}
          />
          {imagePreview && (
            <Box mt={2} position="relative">
              <img
                src={imagePreview} 
                alt="Preview"
                style={{ width: '200px', height: 'auto', borderRadius: '4px' }}
              />
              <IconButton
                onClick={handleRemoveImage}
                style={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          <TextField
            name="event_coordinator"
            label="Event Coordinator"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.event_coordinator} // Populate with existing coordinator
            onChange={handleChange}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Update Event'}
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      )}
    </div>
  );
};

export default EditEventPage;



