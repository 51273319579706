import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, MenuItem } from '@mui/material';

const AddTown = ({ isOpen, onClose, onAdd }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [townName, setTownName] = useState('');
  const [townCode, setTownCode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [status, setStatus] = useState(0);

  useEffect(() => {
    if (isOpen) {
      fetchCountries();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedCountry) {
      fetchStates(selectedCountry);
    } else {
      setStates([]);
      setSelectedState('');
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      fetchDistricts(selectedState);
    } else {
      setDistricts([]);
      setSelectedDistrict('');
    }
  }, [selectedState]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country`);
      setCountries(response.data.data);
    } catch (err) {
      console.error('Failed to fetch countries:', err.response?.data?.message || err.message);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state/by-country`, {
        params: { countryId }
      });
      setStates(response.data.data);
    } catch (err) {
      console.error('Failed to fetch states:', err.response?.data?.message || err.message);
    }
  };

  const fetchDistricts = async (stateId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/district/by-state`, {
        params: { stateId }
      });
      setDistricts(response.data.data);
    } catch (err) {
      console.error('Failed to fetch districts:', err.response?.data?.message || err.message);
    }
  };

  const handleAddTown = async () => {
    if (!townName.trim() || !selectedCountry || !selectedState || !selectedDistrict) {
      alert('Please fill in all required fields.');
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/town`, {
        Town_name: townName.trim(),
        // Town_code: townCode.trim(),
        country: selectedCountry,
        state: selectedState,
        district: selectedDistrict,
        status
      });
      onAdd(); // Refresh the list
      onClose();
    } catch (err) {
      console.error('Failed to add town:', err.response?.data?.message || err.message);
      alert('Failed to add town. Please check your inputs and try again.');
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Create</DialogTitle>
      <DialogContent>
      <TextField
          margin="normal"
          required
          fullWidth
          select
          label="Country"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
        >
          {countries.map((country) => (
            <MenuItem key={country._id} value={country._id}>
              {country.country_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="State"
          value={selectedState}
          onChange={(e) => setSelectedState(e.target.value)}
          disabled={!selectedCountry}
        >
          {states.map((state) => (
            <MenuItem key={state._id} value={state._id}>
              {state.state_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="District"
          value={selectedDistrict}
          onChange={(e) => setSelectedDistrict(e.target.value)}
          disabled={!selectedState}
        >
          {districts.map((district) => (
            <MenuItem key={district._id} value={district._id}>
              {district.district_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Town Name"
          value={townName}
          onChange={(e) => setTownName(e.target.value)}
        />
        {/* <TextField
          margin="normal"
          fullWidth
          label="Town Code"
          value={townCode}
          onChange={(e) => setTownCode(e.target.value)}
        /> */}
       
        {/* <TextField
          margin="normal"
          fullWidth
          select
          label="Status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <MenuItem value={0}>Active</MenuItem>
          <MenuItem value={1}>Inactive</MenuItem>
        </TextField> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddTown} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTown;