import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/system';

const ListEventsPage = () => {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [error, setError] = useState('');
  const [expandedEventId, setExpandedEventId] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();

  // Fetch events from the backend
  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/event`);
      setEvents(response.data.data);
      setTotalItems(response.data.pagination.totalItems); // Adjust based on API response
    } catch (err) {
      setError('Error fetching events');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const handleEditEvent = (id) => {
    navigate(`/events/edit/${id}`);
  };

  // Toggle the event's status
  const handleToggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle logic
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/event/${id}`, { status: newStatus });
      fetchEvents(); // Refresh the events list
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const handleDeleteOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleDeleteClose = () => {
    setOpen(false);
    setDeleteId(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteEvent = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/event/${deleteId}`);
      fetchEvents(); // Refresh the list after deletion
    } catch (err) {
      setError('Error deleting event');
      console.error(err);
    } finally {
      handleDeleteClose();
    }
  };

  const toggleExpand = (id) => {
    setExpandedEventId(expandedEventId === id ? null : id);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredEvents = events.filter(event =>
    event.event_title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Events
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/events/add')}
        >
          Create
        </Button>
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial No.</TableCell>
                <TableCell>Event Title</TableCell>
                <TableCell>Event Coordinator</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEvents.length > 0 ? (
                filteredEvents.map((event, index) => (
                  <TableRow key={event._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{event.event_title}</TableCell>
                    <TableCell>{event.event_coordinator}</TableCell>
                    <TableCell>
                      {event.event_image && (
                        <img
                          src={event.event_image}
                          alt={event.event_title}
                          style={{ width: '100px', height: 'auto' }}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {expandedEventId === event._id ? (
                        <Typography>
                          {event.event_description}
                          <Button onClick={() => toggleExpand(event._id)} size="small">
                            Show Less
                          </Button>
                        </Typography>
                      ) : (
                        <Typography>
                          {event.event_description.length > 100
                            ? `${event.event_description.slice(0, 100)}...`
                            : event.event_description}
                          {event.event_description.length > 100 && (
                            <Button onClick={() => toggleExpand(event._id)} size="small">
                              Read More
                            </Button>
                          )}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={event.status === 1}
                        onChange={() => handleToggleStatus(event._id, event.status)}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditEvent(event._id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteOpen(event._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No events found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalItems >= 10 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      {/* Confirmation Dialog */}
      <Dialog open={open} onClose={handleDeleteClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this event?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteEvent} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ListEventsPage;
