import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const EditPartnerPage = () => {
  const [formData, setFormData] = useState({
    partner_id: '',
    partner_category: '',
    partner_sub_category: '',
    country: '',
    state: '',
    district: '',
    place: '',
    partner_official_id: '',
    partner_name: '',
    designation: '',
    contact_number: '',
    whatsapp_number: '',
    email_address: '',
    address: '',
    pincode: '',
    name_of_contact_person1: '',
    contact_person1_contact_no: '',
    contact_person1_whatsapp_no: '',
    contact_person1_email: '',
    name_of_contact_person2: '',
    contact_person2_contact_no: '',
    contact_person2_whatsapp_no: '',
    contact_person2_email: '',
    area_of_operation: '',
    date_of_partnership: '',
    contract_duration: '',
    products_authorised_to_sell: '',
    sales_target: '',
    bank_acount_details: '',
    gst_identification_number: '',
    agreement_upload: '',
    role: '',
    logo: '',
    password: '',
    status: '',
  });
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingSubCategories, setLoadingSubCategories] = useState(true);
  const { id } = useParams(); // Partner ID from the URL
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/category`);
        setCategories(Array.isArray(response.data.data) ? response.data.data : []);
        setLoadingCategories(false);
      } catch (err) {
        console.error('Error fetching categories:', err);
        setLoadingCategories(false);
      }
    };

    const fetchSubCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/sub_category`);
        setSubCategories(Array.isArray(response.data.data) ? response.data.data : []);
        setLoadingSubCategories(false);
      } catch (err) {
        console.error('Error fetching sub-categories:', err);
        setLoadingSubCategories(false);
      }
    };

    const fetchPartner = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/${id}`);
        setFormData(response.data.data);
        console.log("partner:",response.data.data);
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching partner details:', err);
        setLoading(false);
      }
    };

    fetchCategories();
    fetchSubCategories();
    fetchPartner();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/partners/${id}`, formData);
      setLoading(false);
      navigate('/partners');
    } catch (err) {
      setLoading(false);
      console.error('Error updating partner:', err);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Edit Partner
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="partner_id"
              label="Partner ID"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.partner_id}
              onChange={handleChange}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Category</InputLabel>
              <Select
                name="partner_category"
                value={formData.partner_category}
                onChange={handleChange}
                label="Category"
              >
                {loadingCategories ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : (
                  Array.isArray(categories) && categories.length > 0 ? (
                    categories.map((category) => (
                      <MenuItem key={category._id} value={category._id}>
                        {category.partner_name} {/* Adjust based on actual category object field */}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No categories available</MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Sub-Category</InputLabel>
              <Select
                name="partner_sub_category"
                value={formData.partner_sub_category}
                onChange={handleChange}
                label="Sub-Category"
              >
                {loadingSubCategories ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : (
                  Array.isArray(subCategories) && subCategories.length > 0 ? (
                    subCategories.map((subCategory) => (
                      <MenuItem key={subCategory._id} value={subCategory._id}>
                        {subCategory.SubCategory_name} {/* Adjust based on actual sub-category object field */}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No sub-categories available</MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="country"
              label="Country"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.country}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="state"
              label="State"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.state}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="district"
              label="District"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.district}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="place"
              label="Place"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.place}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="partner_official_id"
              label="Partner Official ID"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.partner_official_id}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="partner_name"
              label="Partner Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.partner_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="designation"
              label="Designation"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.designation}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="contact_number"
              label="Contact Number"
              variant="outlined"
              fullWidth
              margin="normal"
              type="tel"
              value={formData.contact_number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="whatsapp_number"
              label="WhatsApp Number"
              variant="outlined"
              fullWidth
              margin="normal"
              type="tel"
              value={formData.whatsapp_number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="email_address"
              label="Email Address"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              value={formData.email_address}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="address"
              label="Address"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.address}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="pincode"
              label="Pincode"
              variant="outlined"
              fullWidth
              margin="normal"
              type="number"
              value={formData.pincode}
              onChange={handleChange}
            />
          </Grid>
          {/* Contact Person 1 */}
          <Grid item xs={12} sm={6}>
            <TextField
              name="name_of_contact_person1"
              label="Name of Contact Person 1"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.name_of_contact_person1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="contact_person1_contact_no"
              label="Contact Person 1 Contact No"
              variant="outlined"
              fullWidth
              margin="normal"
              type="tel"
              value={formData.contact_person1_contact_no}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="contact_person1_whatsapp_no"
              label="Contact Person 1 WhatsApp No"
              variant="outlined"
              fullWidth
              margin="normal"
              type="tel"
              value={formData.contact_person1_whatsapp_no}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="contact_person1_email"
              label="Contact Person 1 Email"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              value={formData.contact_person1_email}
              onChange={handleChange}
            />
          </Grid>
          {/* Contact Person 2 */}
          <Grid item xs={12} sm={6}>
            <TextField
              name="name_of_contact_person2"
              label="Name of Contact Person 2"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.name_of_contact_person2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="contact_person2_contact_no"
              label="Contact Person 2 Contact No"
              variant="outlined"
              fullWidth
              margin="normal"
              type="tel"
              value={formData.contact_person2_contact_no}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="contact_person2_whatsapp_no"
              label="Contact Person 2 WhatsApp No"
              variant="outlined"
              fullWidth
              margin="normal"
              type="tel"
              value={formData.contact_person2_whatsapp_no}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="contact_person2_email"
              label="Contact Person 2 Email"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              value={formData.contact_person2_email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="area_of_operation"
              label="Area of Operation"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.area_of_operation}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="date_of_partnership"
              label="Date of Partnership"
              variant="outlined"
              fullWidth
              margin="normal"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formData.date_of_partnership}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="contract_duration"
              label="Contract Duration"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.contract_duration}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="products_authorised_to_sell"
              label="Products Authorised to Sell"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.products_authorised_to_sell}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="sales_target"
              label="Sales Target"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.sales_target}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="bank_acount_details"
              label="Bank Account Details"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.bank_acount_details}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="gst_identification_number"
              label="GST Identification Number"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.gst_identification_number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="agreement_upload"
              label="Agreement Upload"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.agreement_upload}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="role"
              label="Role"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.role}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="logo"
              label="Logo"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.logo}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="password"
              label="Password"
              variant="outlined"
              fullWidth
              margin="normal"
              type="password"
              value={formData.password}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="status"
              label="Status"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.status}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Update Partner'}
        </Button>
      </Box>
    </div>
  );
};

export default EditPartnerPage;
