import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';

function AddSchoolSub({ open, onClose, onAdd }) {
  const [categoryName, setCategoryName] = useState('');
  const [selectedSchool, setSelectedSchool] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [schoolOptions, setSchoolOptions] = useState([]);

  // Fetch school categories when component mounts
  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/school_category`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        if (Array.isArray(result.data)) {
          // Filter out schools where status is not 1
          const activeSchools = result.data.filter(school => school.status === 1);
          setSchoolOptions(activeSchools);
        } else {
          console.error('Unexpected data format:', result.data);
        }
      } catch (error) {
        console.error('Failed to fetch school names:', error);
      }
    };

    fetchSchools();
  }, []);

  // Handle category name change
  const handleCategoryChange = (e) => {
    setCategoryName(e.target.value);
  };

  // Handle school selection change
  const handleSchoolChange = (e) => {
    setSelectedSchool(e.target.value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/sub_school_category`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          SubCategory_name: categoryName, 
          School_category_id: selectedSchool // Send the selected school category ObjectId
        }),
      });

      if (response.ok) {
        setSnackbarMessage('Category added successfully');
        onAdd(); // Notify parent to refresh data
        onClose(); // Close the modal
      } else {
        setSnackbarMessage('Failed to add Category');
      }

      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage('Error occurred');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle Snackbar close event
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Sub-Category</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>School Category Name</InputLabel>
            <Select
              value={selectedSchool}
              onChange={handleSchoolChange}
              label="School Category Name"
            >
              {schoolOptions.length > 0 ? (
                schoolOptions.map((school) => (
                  <MenuItem key={school._id} value={school._id}>
                    {school.school_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No schools available</MenuItem>
              )}
            </Select>
          </FormControl>
          <TextField
            label="Sub Category Name"
            value={categoryName}
            onChange={handleCategoryChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <Box display="flex" justifyContent="center" marginTop={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Add
              </Button>
            )}
          </Box>
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarMessage.includes('success') ? 'success' : 'error'}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddSchoolSub;
