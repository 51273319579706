import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AddCountry from './AddCountry';
import EditCountry from './EditCountry';
// import AddCountryModal from './AddCountryModal';
// import EditCountryModal from './EditCountryModal';

function CountryList() {
  const [countries, setCountries] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false); // State for Add Country modal
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const navigate = useNavigate();

  const fetchCountries = async () => {
    try {

      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country`, {
        params: {
          page: page + 1,
          limit: rowsPerPage
        }
      });
      setCountries(response.data.data);
      setTotalItems(response.data.pagination.totalItems);
    } catch (err) {
      setError("Error fetching countries");
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, [page, rowsPerPage]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (countryId) => {
    setSelectedCountryId(countryId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedCountryId(null);
  };

  const handleDeleteCountry = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/country/${id}`);
      setSnackbarMessage('Country deleted successfully');
      setOpenSnackbar(true);
      fetchCountries(); // Refresh the list after deleting
    } catch (err) {
      setSnackbarMessage('Failed to delete country');
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenEditModal = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country/${id}`);
      setSelectedCountry(response.data.data);
      setSelectedCountryId(id);
      setOpenEditModal(true);
    } catch (err) {
      setError('Error fetching country details');
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedCountryId(null);
    setSelectedCountry(null);
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleAddCountry = () => {
    handleCloseAddModal();
    fetchCountries(); // Refresh the list after adding
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateCountryStatus(id, newStatus); // Update status in the backend
  };

  const updateCountryStatus = async (id, status) => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND}/api/country/${id}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      });
      setSnackbarMessage('Country status updated successfully');
      setOpenSnackbar(true);
      fetchCountries(); // Refresh the list after status change
    } catch (err) {
      setError('Failed to update country status');
      setOpenSnackbar(true);
    }
  };

  return (
    <div>
      <h1>Country Listing</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal} // Open Add Country modal
        >
          Add Country
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>Country Name</TableCell>
              <TableCell>Country Code</TableCell>

              {/* <TableCell>Status</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {countries.length > 0 ? (
              countries
                .filter(country => country.country_name.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((country, index) => (
                  <TableRow key={country._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{country.country_name}</TableCell>
                    <TableCell>{country.country_code}</TableCell>

                    {/* <TableCell>
                      <Switch
                        checked={country.status}
                        onChange={() => handleStatusToggle(country._id, country.status)}
                        color="primary"
                      />
                    </TableCell> */}
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenEditModal(country._id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => handleOpenDeleteDialog(country._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No countries found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 5 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <AddCountry open={openAddModal} onClose={handleCloseAddModal} onAdd={handleAddCountry} />
      {selectedCountryId && (
        <EditCountry
          open={openEditModal}
          onClose={handleCloseEditModal}
          countryId={selectedCountryId}
          country={selectedCountry}
          onUpdate={() => {
            handleCloseEditModal();
            fetchCountries(); // Refresh the list after updating
          }}
        />
      )}
      {selectedCountryId && (
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this country?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteCountry(selectedCountryId)}
              color="secondary"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default CountryList;
