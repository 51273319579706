import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';

const PartnerAddPage = () => {
  const [formData, setFormData] = useState({
    
    Partner_Category_id: '',
    Partner_sub_Category_id: '',
    country: '',
    state: '',
    district: '',
    place: '',
    partner_official_id: '',
    partner_name: '',
    contact_person: '',
    mobile: '',
    email: '',
    address: {
      street: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    },
    agreement_start_date: '',
    agreement_end_date: '',
    product_id: '',
    products: [{
      product_name: '',
      product_description: '',
      product_cost: '',
    }],
    status: 'Active',
    isDeleted: false
  });

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingSubCategories, setLoadingSubCategories] = useState(true);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingDistricts, setLoadingDistricts] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/category`);
        setCategories(Array.isArray(response.data.data) ? response.data.data : []);
        setLoadingCategories(false);
      } catch (err) {
        console.error('Error fetching categories:', err);
        setLoadingCategories(false);
      }
    };

    const fetchSubCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/sub_category`);
        setSubCategories(Array.isArray(response.data.data) ? response.data.data : []);
        setLoadingSubCategories(false);
      } catch (err) {
        console.error('Error fetching sub-categories:', err);
        setLoadingSubCategories(false);
      }
    };

    const fetchCountries = async () => {
      try {
        setLoadingCountries(true);
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country`);
        setCountries(response.data.data || []);
        setLoadingCountries(false);
      } catch (err) {
        setLoadingCountries(false);
        console.error('Failed to fetch countries:', err.response?.data?.message || err.message);
      }
    };

    fetchCountries();
    fetchCategories();
    fetchSubCategories();
  }, []);

  const handleCountryChange = async (e) => {
    const { value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      country: value,
      state: '',
      district: '',
      address: {
        ...prevData.address,
        country: value
      }
    }));

    try {
      setLoadingStates(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state/by-country`, { params: { countryId: value } });
      setStates(response.data.data || []);
      setLoadingStates(false);
    } catch (err) {
      setLoadingStates(false);
      console.error('Failed to fetch states:', err.response?.data?.message || err.message);
    }
  };

  const handleStateChange = async (e) => {
    const { value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      state: value,
      district: '',
      address: {
        ...prevData.address,
        state: value
      }
    }));

    try {
      setLoadingDistricts(true);
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/district/by-state`, { params: { stateId: value } });
      setDistricts(response.data.data || []);
      setLoadingDistricts(false);
    } catch (err) {
      setLoadingDistricts(false);
      console.error('Failed to fetch districts:', err.response?.data?.message || err.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('address.')) {
      const field = name.split('.')[1];
      setFormData(prevData => ({
        ...prevData,
        address: {
          ...prevData.address,
          [field]: value
        }
      }));
    } else if (name.startsWith('products[')) {
      const [_, index, field] = name.match(/^products\[(\d+)]\.(.+)$/);
      const productIndex = parseInt(index, 10);
      setFormData(prevData => {
        const updatedProducts = [...prevData.products];
        updatedProducts[productIndex] = {
          ...updatedProducts[productIndex],
          [field]: value
        };
        return {
          ...prevData,
          products: updatedProducts
        };
      });
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleProductChange = (index, field, value) => {
    setFormData(prevData => {
      const updatedProducts = [...prevData.products];
      updatedProducts[index] = {
        ...updatedProducts[index],
        [field]: value
      };
      return {
        ...prevData,
        products: updatedProducts
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/partner`, formData);
      setIsLoading(false);
      navigate('/partner_list');
    } catch (err) {
      setIsLoading(false);
      console.error('Error adding partner:', err);
    }
  };

  return (

    <Container maxWidth="xl" sx={{ width: "80%", marginLeft: "0px" }}>
      <Typography variant="h5" gutterBottom sx={{ backgroundColor: "#e4e1de", padding: "5px" }}>
        Add New Partner
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="partner_id"
              label="Partner ID"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.partner_id}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Category</InputLabel>
              <Select
                name="Partner_Category_id"
                value={formData.Partner_Category_id}
                onChange={handleChange}
                label="Category"
              >
                {loadingCategories ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : categories.length > 0 ? (
                  categories.map(category => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.partner_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No categories available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Sub-Category</InputLabel>
              <Select
                name="Partner_sub_Category_id"
                value={formData.Partner_sub_Category_id}
                onChange={handleChange}
                label="Sub-Category"
              >
                {loadingSubCategories ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : subCategories.length > 0 ? (
                  subCategories.map(subCategory => (
                    <MenuItem key={subCategory._id} value={subCategory._id}>
                      {subCategory.SubCategory_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No sub-categories available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Country</InputLabel>
              <Select
                name="country"
                value={formData.country}
                onChange={handleCountryChange}
                label="Country"
              >
                {loadingCountries ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : countries.length > 0 ? (
                  countries.map(country => (
                    <MenuItem key={country._id} value={country._id}>
                      {country.country_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No countries available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>State</InputLabel>
              <Select
                name="state"
                value={formData.state}
                onChange={handleStateChange}
                label="State"
                disabled={loadingStates}
              >
                {loadingStates ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : states.length > 0 ? (
                  states.map(state => (
                    <MenuItem key={state._id} value={state._id}>
                      {state.state_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No states available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>District</InputLabel>
              <Select
                name="district"
                value={formData.district}
                onChange={handleChange}
                label="District"
                disabled={loadingDistricts}
              >
                {loadingDistricts ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : districts.length > 0 ? (
                  districts.map(district => (
                    <MenuItem key={district._id} value={district._id}>
                      {district.district_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No districts available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="place"
              label="Place"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.place}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="partner_official_id"
              label="Partner Official ID"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.partner_official_id}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="partner_name"
              label="Partner Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.partner_name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="contact_person"
              label="Contact Person"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.contact_person}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="mobile"
              label="Mobile"
              variant="outlined"
              fullWidth
              margin="normal"
              type="number"
              value={formData.mobile}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="email"
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="address.street"
              label="Street"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.address.street}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="address.city"
              label="City"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.address.city}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="address.state"
              label="State"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.address.state}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="address.postal_code"
              label="Postal Code"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.address.postal_code}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="address.country"
              label="Country"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.address.country}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="agreement_start_date"
              label="Agreement Start Date"
              variant="outlined"
              fullWidth
              margin="normal"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formData.agreement_start_date}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="agreement_end_date"
              label="Agreement End Date"
              variant="outlined"
              fullWidth
              margin="normal"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formData.agreement_end_date}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="product_id"
              label="Product ID"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.product_id}
              onChange={handleChange}
              required
            />
          </Grid>
          {formData.products.map((product, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name={`products[${index}].product_name`}
                  label={`Product ${index + 1} Name`}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={product.product_name}
                  onChange={(e) => handleProductChange(index, 'product_name', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name={`products[${index}].product_description`}
                  label={`Product ${index + 1} Description`}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={product.product_description}
                  onChange={(e) => handleProductChange(index, 'product_description', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name={`products[${index}].product_cost`}
                  label={`Product ${index + 1} Cost`}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  type="number"
                  value={product.product_cost}
                  onChange={(e) => handleProductChange(index, 'product_cost', e.target.value)}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={formData.status}
                onChange={handleChange}
                label="Status"
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="created_by"
              label="Created By"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.created_by}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="updated_by"
              label="Updated By"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData.updated_by}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              {isLoading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PartnerAddPage;
