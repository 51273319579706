import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import axios from 'axios';

function EditStudentSub({ open, onClose, categoryId, category, onUpdate }) {
  const [subCategoryName, setSubCategoryName] = useState(category?.student_sub_category_name || '');
  const [selectedCategory, setSelectedCategory] = useState(category?.student_id || '');
  const [loading, setLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState(null);

  // Fetch student categories for the dropdown
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/student/category`);
        // Filter out categories with status = 1
        const activeCategories = response.data.data.filter(category => category.status === 1);
        setCategoryOptions(activeCategories);
      } catch (err) {
        console.error('Error fetching student categories', err);
      }
    };

    fetchCategories();
  }, []);

  // Handle input changes
  const handleSubCategoryChange = (e) => {
    setSubCategoryName(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/student/category_sub/${categoryId}`, {
        student_sub_category_name: subCategoryName,
        student_id: selectedCategory,
      });
      setSnackbarMessage('Student subcategory updated successfully');
      onUpdate(); // Notify parent to refresh data
      onClose(); // Close the modal
    } catch (err) {
      setSnackbarMessage('Failed to update student subcategory');
      setError('Failed to update student subcategory');
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  // Handle Snackbar close event
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Student Subcategory</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Student Category</InputLabel>
            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              label="Student Category"
            >
              {categoryOptions.length > 0 ? (
                categoryOptions.map((cat) => (
                  <MenuItem key={cat._id} value={cat._id}>
                    {cat.Student_category_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No student categories available</MenuItem>
              )}
            </Select>
          </FormControl>
          <TextField
            label="Subcategory Name"
            value={subCategoryName}
            onChange={handleSubCategoryChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <Box display="flex" justifyContent="center" marginTop={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Update Student Subcategory
              </Button>
            )}
          </Box>
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={error ? 'error' : 'success'}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditStudentSub;
