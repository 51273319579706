import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const EditSliderPage = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: '',
    image_url: null,
    redirected_url: '',
    start_date: '',
    end_date: '',
    is_active: false,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const navigate = useNavigate();

  // Fetch slider details when the component mounts
  useEffect(() => {
    const fetchSlider = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/slider/${id}`);
        const sliderData = response.data.data;
        setFormData({
          title: sliderData.title,
          image_url: sliderData.image_url,
          redirected_url: sliderData.redirected_url,
          start_date: sliderData.start_date.split('T')[0],
          end_date: sliderData.end_date.split('T')[0],
          is_active: sliderData.is_active,
        });
        setImagePreview(sliderData.image_url);
      } catch (err) {
        setError('Error fetching slider details');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchSlider();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData((prevData) => ({
        ...prevData,
        image_url: files[0],
      }));
      setImagePreview(URL.createObjectURL(files[0]));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(formData.image_url); // Reset preview to existing image
    setFormData((prevData) => ({
      ...prevData,
      image_url: null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    for (const key in formData) {
      if (key === 'image_url' && formData.image_url instanceof File) {
        data.append(key, formData.image_url);
      } else if (key === 'image_url') {
        data.append(key, formData.image_url); // Append the existing image URL
      } else {
        data.append(key, formData[key]);
      }
    }

    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/slider/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      navigate('/slider_list');
    } catch (err) {
      setError('Error updating slider');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Edit Slider
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            name="title"
            label="Title"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.title}
            onChange={handleChange}
            required
          />
          <input
            name="image_url"
            type="file"
            accept="image/*"
            onChange={handleChange}
          />
          {imagePreview && (
            <Box mt={2} position="relative">
              <img
                src={imagePreview}
                alt="Preview"
                style={{ width: '200px', height: 'auto', borderRadius: '4px' }}
              />
              <IconButton
                onClick={handleRemoveImage}
                style={{ position: 'absolute', top: 0, right: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          <TextField
            name="redirected_url"
            label="Redirected URL"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.redirected_url}
            onChange={handleChange}
            required
          />
          <TextField
            name="start_date"
            label="Start Date"
            type="date"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.start_date}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            name="end_date"
            label="End Date"
            type="date"
            variant="outlined"
            fullWidth
            margin="normal"
            value={formData.end_date}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Update Slider'}
          </Button>
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      )}
    </div>
  );
};

export default EditSliderPage;
