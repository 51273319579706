import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';

function AddProductSub({ open, onClose, onAdd }) {
  const [partnerName, setPartnerName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [categoryOptions, setCategoryOptions] = useState([]);

  // Fetch product categories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/product/category`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        if (Array.isArray(result.data)) {
          setCategoryOptions(result.data);
        } else {
          console.error('Unexpected data format:', result.data);
        }
      } catch (error) {
        console.error('Failed to fetch product categories:', error);
      }
    };

    fetchCategories();
  }, []);

  // Handle category change
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  // Handle partner name change
  const handlePartnerChange = (e) => {
    setPartnerName(e.target.value);
  };

  // Handle form submission
  const handlePartnerSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/product/category_sub`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          product_sub_category_name: partnerName,
          product_id: selectedCategory, // Include selected product category ID
        }),
      });

      if (response.ok) {
        setSnackbarMessage('Product sub-category added successfully');
        onAdd(); // Notify parent to refresh data
        onClose(); // Close the modal
      } else {
        const errorData = await response.json();
        setSnackbarMessage(`Failed to add Product: ${errorData.message || 'Unknown error'}`);
      }

      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(`Error occurred: ${error.message}`);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle Snackbar close event
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Product Sub-Category</DialogTitle>
      <DialogContent>
        <form onSubmit={handlePartnerSubmit}>
          <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Product Category</InputLabel>
            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              label="Product Category"
            >
              {categoryOptions.length > 0 ? (
                categoryOptions.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.product_category_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No categories available</MenuItem>
              )}
            </Select>
          </FormControl>
          <TextField
            label="Product Sub-Category Name"
            value={partnerName}
            onChange={handlePartnerChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <Box display="flex" justifyContent="center" marginTop={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Add Product Sub-Category
              </Button>
            )}
          </Box>
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarMessage.includes('success') ? 'success' : 'error'}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddProductSub;
