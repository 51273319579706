import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, MenuItem } from '@mui/material';

const AddDistrict = ({ isOpen, onClose, onAdd }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districtName, setDistrictName] = useState('');
  const [districtCode, setDistrictCode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');

  useEffect(() => {
    if (isOpen) {
      fetchCountries();
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedCountry) {
      fetchStates(selectedCountry);
    } else {
      setStates([]);
    }
  }, [selectedCountry]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country`);
      setCountries(response.data.data);
    } catch (err) {
      console.error('Failed to fetch countries');
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state/by-country`, {
        params: { countryId } // Pass countryId as a query parameter
      });
      setStates(response.data.data);
    } catch (err) {
      console.error('Failed to fetch states');
    }
  };

  const handleAddDistrict = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/district`, {
        district_name: districtName,
        district_code: districtCode,
        country: selectedCountry,
        state: selectedState,
      });
      onAdd();
      onClose();
    } catch (err) {
      console.error('Failed to add District');
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Create</DialogTitle>
      <DialogContent>
      <TextField
          margin="normal"
          required
          fullWidth
          select
          label="Country"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
        >
          {countries.map((country) => (
            <MenuItem key={country._id} value={country._id}>
              {country.country_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="State"
          value={selectedState}
          onChange={(e) => setSelectedState(e.target.value)}
        >
          {states.map((state) => (
            <MenuItem key={state._id} value={state._id}>
              {state.state_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          label="District Name"
          value={districtName}
          onChange={(e) => setDistrictName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="District Code"
          value={districtCode}
          onChange={(e) => setDistrictCode(e.target.value)}
        />
        
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddDistrict} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddDistrict;
