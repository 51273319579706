import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Grid,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const SliderListPage = () => {
  const [sliders, setSliders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [sliderToDelete, setSliderToDelete] = useState(null);
  const navigate = useNavigate();

  // Function to fetch sliders from the backend
  const fetchSliders = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/slider`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          searchTerm,
        },
      });
      setSliders(response.data.data);
      setTotalItems(response.data.pagination.totalItems); // Adjust based on API response
    } catch (err) {
      setError('Error fetching sliders');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSliders();
  }, [searchTerm, page, rowsPerPage]);

  const handleAddSlider = () => {
    navigate('/sliders/add');
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };
  const handleEditSlider = (id) => {
    navigate(`/sliders/edit/${id}`);
  };

  const handleDeleteConfirmation = (slider) => {
    setSliderToDelete(slider);
    setOpenDialog(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteSlider = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/slider/${sliderToDelete._id}`);
      setOpenDialog(false);
      fetchSliders(); // Refresh the list after deletion
    } catch (err) {
      setError('Error deleting slider');
      console.error(err);
    }
  };

  return (
    <div>
      <h1>Sliders</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddSlider}
        >
          Add Slider
        </Button>
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial No.</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Image URL</TableCell>
                <TableCell>Redirected URL</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sliders.length > 0 ? (
                sliders
                  .filter(subject => subject.title.toLowerCase().includes(searchTerm.toLowerCase()))

                  .map((slider, index) => (
                    <TableRow key={slider._id}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell>{slider.title}</TableCell>
                      <TableCell>{slider.image_url}</TableCell>
                      <TableCell>{slider.redirected_url}</TableCell>
                      <TableCell>{new Date(slider.start_date).toLocaleDateString()}</TableCell>
                      <TableCell>{new Date(slider.end_date).toLocaleDateString()}</TableCell>
                      <TableCell>{slider.is_active ? 'Active' : 'Inactive'}</TableCell>
                      <TableCell>
                        <IconButton color="primary" onClick={() => handleEditSlider(slider._id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => handleDeleteConfirmation(slider)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No sliders found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalItems > 5 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the slider "{sliderToDelete?.title}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSlider} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SliderListPage;
