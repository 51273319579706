import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Button, TextField, MenuItem, CircularProgress, Typography, Grid, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AddCoursePage = () => {
  const [subcategories, setSubcategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [courseCategories, setCourseCategories] = useState([]);
  const [grades, setGrades] = useState([]);
  const [course, setCourse] = useState({
    courses_name: "",
    course_details: "",
    no_of_chapters: 0,
    status: 1,
    course_category_id: "",
    course_subcategory_id: "",
    department_id: "",
    grade_id: "", // Changed from null to ""
    course_thumbnail: '',
    course_image: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [courseImage, setCourseImage] = useState(null);
  const navigate = useNavigate();

  const handleImageChange = (e, type) => {
    if (type === 'thumbnail') {
      setThumbnailImage(e.target.files[0]);
    } else if (type === 'course') {
      setCourseImage(e.target.files[0]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          subcategoriesRes,
          departmentsRes,
          courseCategoriesRes,
          gradesRes
        ] = await Promise.all([
          axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category_sub`),
          axios.get(`${process.env.REACT_APP_BACKEND}/api/department`),
          axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category`),
          axios.get(`${process.env.REACT_APP_BACKEND}/api/grade`)
        ]);

        setSubcategories(subcategoriesRes.data.data);
        setDepartments(departmentsRes.data.data);
        setCourseCategories(courseCategoriesRes.data.data);
        setGrades(gradesRes.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (course.course_category_id) {
      const filtered = subcategories.filter(subcategory =>
        subcategory.course_category_id === course.course_category_id
      );
      setFilteredSubcategories(filtered);
    }
  }, [course.course_category_id, subcategories]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCourse((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!course.courses_name || !course.course_details || !course.course_category_id || !course.course_subcategory_id || !course.department_id) {
      alert('Please fill out all required fields.');
      return;
    }

    setIsSaving(true);
    const formData = new FormData();
    formData.append('courses_name', course.courses_name);
    formData.append('course_details', course.course_details);
    formData.append('no_of_chapters', course.no_of_chapters);
    formData.append('status', course.status);
    formData.append('course_category_id', course.course_category_id);
    formData.append('course_subcategory_id', course.course_subcategory_id);
    formData.append('department_id', course.department_id);

    if (course.grade_id) {
      formData.append('grade_id', course.grade_id);
    }

    if (thumbnailImage) {
      formData.append('course_thumbnail', thumbnailImage);
    }

    if (courseImage) {
      formData.append('course_image', courseImage);
    }

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/courses`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      navigate("/courses_list");
    } catch (error) {
      console.error("Error saving course:", error);
      alert('Error saving course. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ width: "80%", marginLeft: "0px" }}>
      <Box padding={2}>
        <Typography variant="h5" gutterBottom sx={{ backgroundColor: "#e4e1de", padding: "5px" }}>
          Add Course
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
            <CircularProgress />
          </Box>
        ) : (
          <Box component="form" noValidate autoComplete="off" onSubmit={handleSave}>
            <TextField
              label="Course Category"
              variant="outlined"
              fullWidth
              margin="normal"
              name="course_category_id"
              select
              value={course.course_category_id}
              onChange={handleInputChange}
              required
            >
              {courseCategories.map((courseCategory) => (
                <MenuItem key={courseCategory._id} value={courseCategory._id}>
                  {courseCategory.courses_name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Subcategory"
              variant="outlined"
              fullWidth
              margin="normal"
              name="course_subcategory_id"
              select
              value={course.course_subcategory_id}
              onChange={handleInputChange}
              required
            >
              {filteredSubcategories.map((subcategory) => (
                <MenuItem key={subcategory._id} value={subcategory._id}>
                  {subcategory.courses_sub_category_name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Department"
              variant="outlined"
              fullWidth
              margin="normal"
              name="department_id"
              select
              value={course.department_id}
              onChange={handleInputChange}
              required
            >
              {departments.map((department) => (
                <MenuItem key={department._id} value={department._id}>
                  {department.department_name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Grade"
              variant="outlined"
              fullWidth
              margin="normal"
              name="grade_id"
              select
              value={course.grade_id}
              onChange={handleInputChange}
            >
              <MenuItem value="">Select Grade</MenuItem>
              {grades.map((grade) => (
                <MenuItem key={grade._id} value={grade._id}>
                  {grade.gradename}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Course Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="courses_name"
              value={course.courses_name}
              onChange={handleInputChange}
              required
            />
            <TextField
              label="Course Details"
              variant="outlined"
              fullWidth
              margin="normal"
              name="course_details"
              value={course.course_details}
              onChange={handleInputChange}
              required
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  Course Thumbnail Image
                </Typography>
                <Button variant="contained" component="label">
                  Upload Course Thumbnail Image
                  <input
                    type="file"
                    hidden
                    onChange={(e) => handleImageChange(e, 'thumbnail')}
                  />
                </Button>
                {thumbnailImage && (
                  <Typography>{thumbnailImage.name}</Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  Course Image
                </Typography>
                <Button variant="contained" component="label">
                  Upload Course Image
                  <input
                    type="file"
                    hidden
                    onChange={(e) => handleImageChange(e, 'course')}
                  />
                </Button>
                {courseImage && (
                  <Typography>{courseImage.name}</Typography>
                )}
              </Grid>
            </Grid>
            <TextField
              label="Number of Chapters"
              variant="outlined"
              fullWidth
              margin="normal"
              type="number"
              name="no_of_chapters"
              value={course.no_of_chapters}
              onChange={handleInputChange}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSaving}
              sx={{ mt: 2 }}
            >
              {isSaving ? <CircularProgress size={24} /> : "Save"}
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default AddCoursePage;
