import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Grid,
  TextField,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const CoursesList = () => {
  const [courses, setCourses] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    const fetchCourses = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses`, {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            searchTerm,
          },
        });
        setCourses(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      } catch (err) {
        setError('Failed to fetch courses');
        setSnackbarMessage('Failed to fetch courses');
        setSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCourses();
  }, [page, rowsPerPage, searchTerm]);

  const handleDeleteCourse = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/courses/${id}`);
      setCourses(courses.filter(course => course._id !== id));
      setSnackbarMessage('Course deleted successfully');
      setSnackbarOpen(true);
    } catch (err) {
      setError('Failed to delete course');
      setSnackbarMessage('Failed to delete course');
      setSnackbarOpen(true);
    }
  };

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
        <Button variant="contained" color="primary" component={Link} to="/add-course">
          Add Course
        </Button>
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Details</TableCell>
                {/* <TableCell>Status</TableCell> */}
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses.length > 0 ? (
                courses.map((course) => (
                  <TableRow key={course._id}>
                    <TableCell>{course._id}</TableCell>
                    <TableCell>{course.courses_name}</TableCell>
                    <TableCell>{course.course_details}</TableCell>
                    {/* <TableCell>{course.status}</TableCell> */}
                    <TableCell>
                      <IconButton component={Link} to={`/edit-course/${course._id}`} color="primary">
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteCourse(course._id)} color="error">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No courses found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}{totalItems > 5 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={error ? 'error' : 'success'}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CoursesList;
