import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TablePagination,
  Snackbar,
  TextField,
  Button,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';

const QuestionsPage = () => {
  const { assessmentId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  
  // New question state with options and points
  const [newQuestion, setNewQuestion] = useState({
    question_text: '',
    question_type: 'mcq',
    options: ['', '', '', ''], // Four options
    answer: '',
    points: '',
  });
  const [openAddQuestionDialog, setOpenAddQuestionDialog] = useState(false);

  const fetchQuestions = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/assessment_question/${assessmentId}?page=${currentPage + 1}&limit=${pageSize}&search=${searchTerm}`
      );

      if (response.data && response.data.data) {
        setQuestions(response.data.data);
        setTotalItems(response.data.pagination ? response.data.pagination.totalItems : response.data.data.length);
        setError('');
      } else {
        setError('No questions found');
      }
    } catch (err) {
      setError(`Error fetching questions: ${err.response?.data?.message || err.message}`);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleOpenDialog = (id) => {
    setDeleteQuestionId(id);
    setOpenDialog(true);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteQuestionId(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/assessment_question/${deleteQuestionId}`);
      setSnackbarMessage('Question deleted successfully');
      setOpenSnackbar(true);
      fetchQuestions();
    } catch (err) {
      setError('Failed to delete question: ' + (err.response?.data?.message || err.message));
      console.error(err);
    } finally {
      handleCloseDialog();
    }
  };

  const handleOpenAddQuestionDialog = () => {
    setOpenAddQuestionDialog(true);
  };

  const handleCloseAddQuestionDialog = () => {
    setOpenAddQuestionDialog(false);
    setNewQuestion({ question_text: '', question_type: 'mcq', options: ['', '', '', ''], answer: '', points: '' });
  };

  const handleChangeNewQuestion = (event) => {
    const { name, value } = event.target;
    setNewQuestion((prev) => ({ ...prev, [name]: value }));
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...newQuestion.options];
    newOptions[index] = value;
    setNewQuestion((prev) => ({ ...prev, options: newOptions }));
  };

  const handleAddQuestion = async () => {
    try {
      if (!newQuestion.question_text || !newQuestion.question_type || !newQuestion.points || (newQuestion.question_type === 'mcq' && !newQuestion.answer)) {
        setError("Please fill all required fields.");
        return;
      }

      const questionData = {
        assessment_id: assessmentId,
        question_text: newQuestion.question_text,
        question_type: newQuestion.question_type,
        points: parseInt(newQuestion.points, 10),
        options: newQuestion.options.filter(option => option.trim() !== ''),
        answer: newQuestion.answer,
      };

      const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/assessment_question`, questionData);
      
      if (response.status === 201) {
        setSnackbarMessage('Question added successfully');
        setOpenSnackbar(true);
        fetchQuestions();
        handleCloseAddQuestionDialog();
      } else {
        setError('Failed to add question: ' + response.data.message);
      }
    } catch (err) {
      setError('Failed to add question: ' + (err.response?.data?.message || err.message));
      console.error(err);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, [assessmentId, currentPage, pageSize, searchTerm]);

  return (
    <Box padding={2}>
      <Typography variant="h4" gutterBottom>
        Questions for Assessment
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: "300px" }}
        />
        <Button onClick={handleOpenAddQuestionDialog} variant="contained" color="primary">
          Add Question
        </Button>
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial No</TableCell>
                <TableCell>Question Text</TableCell>
                <TableCell>Question Type</TableCell>
                <TableCell>Options</TableCell>
                <TableCell>Answer</TableCell>
                <TableCell>Points</TableCell>
                <TableCell><b>Actions</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.length > 0 ? (
                questions.map((question, index) => (
                  <TableRow key={question._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{question.question_text}</TableCell>
                    <TableCell>{question.question_type}</TableCell>
                    <TableCell>{question.options.join(', ')}</TableCell>
                    <TableCell>{question.answer}</TableCell>
                    <TableCell>{question.points}</TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => handleOpenDialog(question._id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No questions found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Confirmation Dialog for Deletion */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this question?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>

      {/* Add Question Dialog */}
      <Dialog open={openAddQuestionDialog} onClose={handleCloseAddQuestionDialog}>
        <DialogTitle>Add New Question</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="question_text"
                label="Question Text"
                value={newQuestion.question_text}
                onChange={handleChangeNewQuestion}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="question_type"
                label="Question Type"
                select
                value={newQuestion.question_type}
                onChange={handleChangeNewQuestion}
                fullWidth
                required
              >
                <MenuItem value="mcq">MCQ</MenuItem>
                <MenuItem value="descriptive">Text</MenuItem>
                <MenuItem value="yes/no">Yes/No</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="points"
                label="Points"
                type="number"
                value={newQuestion.points}
                onChange={handleChangeNewQuestion}
                fullWidth
                required
              />
            </Grid>

            {newQuestion.question_type === 'mcq' && newQuestion.options.map((option, idx) => (
              <Grid item xs={12} key={idx}>
                <TextField
                  label={`Option ${idx + 1}`}
                  variant="outlined"
                  value={option}
                  onChange={(e) => handleOptionChange(idx, e.target.value)}
                  fullWidth
                  required={idx < 2} // Make first two options required
                />
              </Grid>
            ))}

            {newQuestion.question_type === 'yes/no' && (
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Answer</InputLabel>
                  <Select
                    name="answer"
                    value={newQuestion.answer}
                    onChange={handleChangeNewQuestion}
                    label="Answer"
                  >
                    <MenuItem value="">Select Answer</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            {newQuestion.question_type !== 'yes/no' && (
              <Grid item xs={12}>
                <TextField
                  name="answer"
                  label="Answer"
                  value={newQuestion.answer}
                  onChange={handleChangeNewQuestion}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddQuestionDialog} color="primary">Cancel</Button>
          <Button onClick={handleAddQuestion} color="secondary">Add Question</Button>
        </DialogActions>
      </Dialog>

      {/* Pagination Control */}
      <TablePagination
        component="div"
        count={totalItems}
        page={currentPage}
        onPageChange={handlePageChange}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handlePageSizeChange}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />

      {/* Snackbar for Notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default QuestionsPage;
