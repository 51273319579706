import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    TextField,
    Typography,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AddLabname = () => {
    const [labName, setLabName] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const navigate = useNavigate();

    // Fetch departments from the backend
    const fetchDepartments = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department`);
            setDepartments(response.data.data); // Adjust based on your API response
        } catch (err) {
            setError('Failed to fetch departments.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDepartments();
    }, []);

    const validateForm = () => {
        const errors = {};
        if (!labName) errors.labName = 'Lab name is required';
        if (!departmentId) errors.departmentId = 'Department is required';
        
        setFormErrors(errors);
        return Object.keys(errors).length === 0; // Return true if no errors
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return; // Prevent submission if form is invalid

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND}/api/labnames`, {
                lab_name: labName,
                department: departmentId,
                status: true, // Set default status, modify as needed
                created_by: null, // Set this based on your authentication context
                updated_by: null, // Set this based on your authentication context
            });
            if (response.status === 201) {
                navigate('/labnames'); // Redirect to list after successful add
            }
        } catch (err) {
            setError('Error adding lab name');
            console.error(err);
        }
    };

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Box padding={2}>
            <Typography variant="h4">Add Lab Name</Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Lab Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={labName}
                    onChange={(e) => {
                        setLabName(e.target.value);
                        setFormErrors({ ...formErrors, labName: null }); // Clear error if valid
                    }}
                    required
                    error={!!formErrors.labName}
                    helperText={formErrors.labName}
                />
                <FormControl fullWidth margin="normal" required error={!!formErrors.departmentId}>
                    <InputLabel>Department</InputLabel>
                    <Select
                        value={departmentId}
                        onChange={(e) => {
                            setDepartmentId(e.target.value);
                            setFormErrors({ ...formErrors, departmentId: null }); // Clear error if valid
                        }}
                    >
                        {departments.map((department) => (
                            <MenuItem key={department._id} value={department._id}>
                                {department.department_name}
                            </MenuItem>
                        ))}
                    </Select>
                    {formErrors.departmentId && <FormHelperText>{formErrors.departmentId}</FormHelperText>}
                </FormControl>
                <Button variant="contained" color="primary" type="submit">
                    Add Lab Name
                </Button>
            </form>
        </Box>
    );
};

export default AddLabname;
