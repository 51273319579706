import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, FormControl, InputLabel, Select, FormHelperText } from '@mui/material';
import axios from 'axios';

function AddSubCategoryModal({ open, onClose, onAdd }) {
  const [subCategoryName, setSubCategoryName] = useState('');
  const [partnerId, setPartnerId] = useState('');
  const [partners, setPartners] = useState([]);
  const [errors, setErrors] = useState({
    subCategoryName: '',
    partnerId: '',
    fetchPartners: '',
    api: ''
  });

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/category`);
        console.log('API Response:', response.data); // Log API response

        if (response.data && Array.isArray(response.data.data)) {
          setPartners(response.data.data);
        } else {
          setErrors(prevErrors => ({
            ...prevErrors,
            fetchPartners: 'Invalid response format'
          }));
        }
      } catch (err) {
        setErrors(prevErrors => ({
          ...prevErrors,
          fetchPartners: 'Failed to fetch partners'
        }));
        console.error('Fetch Error:', err); // Log fetch error
      }
    };

    fetchPartners();
  }, []);

  const validate = () => {
    let isValid = true;
    const newErrors = {
      subCategoryName: '',
      partnerId: ''
    };

    if (!subCategoryName.trim()) {
      newErrors.subCategoryName = 'Sub Category Name is required';
      isValid = false;
    }

    if (!partnerId) {
      newErrors.partnerId = 'Partner Name is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleAdd = async () => {
    if (!validate()) return;

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/partner/sub_category`, { 
        SubCategory_name: subCategoryName,
        partner_id: partnerId
      });
      setErrors({
        subCategoryName: '',
        partnerId: '',
        fetchPartners: '',
        api: ''
      });
      onAdd();
      onClose(); // Close the modal after successful addition
    } catch (err) {
      setErrors(prevErrors => ({
        ...prevErrors,
        api: 'Failed to add subcategory'
      }));
      console.error('API Error:', err); // Log API error
    }
  };

  // Filter partners by status (not deleted and active)
  const filteredPartners = partners.filter(partner => !partner.isDeleted && partner.status);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Sub-Category</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="dense" error={!!errors.partnerId}>
          <InputLabel sx={{backgroundColor:"#fff "}}>Partner Category Name*</InputLabel>
          <Select
            value={partnerId}
            onChange={(e) => setPartnerId(e.target.value)}
            label="Category Name*"
            displayEmpty
          >
            {filteredPartners.length > 0 ? (
              filteredPartners.map((partner) => (
                <MenuItem key={partner._id} value={partner._id}>
                  {partner.partner_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No partners available</MenuItem>
            )}
          </Select>
          {errors.partnerId && <FormHelperText>{errors.partnerId}</FormHelperText>}
        </FormControl>
        <TextField
          autoFocus
          margin="dense"
          label="SubCategory Name*"
          fullWidth
          value={subCategoryName}
          onChange={(e) => setSubCategoryName(e.target.value)}
          error={!!errors.subCategoryName}
          helperText={errors.subCategoryName}
        />
        {errors.fetchPartners && <FormHelperText error>{errors.fetchPartners}</FormHelperText>}
        {errors.api && <FormHelperText error>{errors.api}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddSubCategoryModal;
