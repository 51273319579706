import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const EditEvent = () => {
  const [event, setEvent] = useState({
    event_title: '',
    event_description: '',
    event_image: '',
    event_coordinator: '',
    file_path: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvent = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/event/${id}`);
        setEvent(response.data.data);
      } catch (err) {
        setError('Error fetching event');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvent();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent({ ...event, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/event/${id}`, event);
      navigate('/event_list'); // Redirect after successful update
    } catch (err) {
      setError('Error updating event');
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <IconButton onClick={() => navigate('/events')}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h4">Edit Event</Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            label="Title"
            name="event_title"
            value={event.event_title}
            onChange={handleChange}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            label="Description"
            name="event_description"
            value={event.event_description}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            label="Image URL"
            name="event_image"
            value={event.event_image}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Coordinator"
            name="event_coordinator"
            value={event.event_coordinator}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="File Path"
            name="file_path"
            value={event.file_path}
            onChange={handleChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <Button type="submit" variant="contained" color="primary">
              Update Event
            </Button>
          )}
          {error && <Typography color="error">{error}</Typography>}
        </Box>
      )}
    </div>
  );
};

export default EditEvent;
