// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import {
//   Box,
//   Button,
//   IconButton,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TablePagination,
//   TableRow,
//   Paper,
//   TextField,
//   CircularProgress,
//   Typography,
// } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { useNavigate } from 'react-router-dom';

// const EventListPage = () => {
//   const [events, setEvents] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [totalItems, setTotalItems] = useState(0);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState('');
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchEvents = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/event`, {
//           params: {
//             page: page + 1,
//             limit: rowsPerPage,
//             searchTerm,
//           },
//         });
//         setEvents(response.data.data);
//         setTotalItems(response.data.pagination.totalItems); // Adjust based on API response
//       } catch (err) {
//         setError('Error fetching events');
//         console.error(err);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     const delayDebounceFn = setTimeout(() => {
//       fetchEvents();
//     }, 500);

//     return () => clearTimeout(delayDebounceFn);
//   }, [searchTerm, page, rowsPerPage]);

//   const handleAddEvent = () => {
//     navigate('/events/add');
//   };

//   const handleEditEvent = (id) => {
//     navigate(`/events/edit/${id}`);
//   };

//   const handleDeleteEvent = async (id) => {
//     try {
//       await axios.delete(`${process.env.REACT_APP_BACKEND}/api/event/${id}`);
//       fetchEvents(); // Refresh the list after deletion
//     } catch (err) {
//       setError('Error deleting event');
//       console.error(err);
//     }
//   };

//   return (
//     <div>
//       <h1>Events</h1>
//       <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
//         <TextField
//           label="Search"
//           variant="outlined"
//           size="small"
//           onChange={(e) => setSearchTerm(e.target.value)}
//           value={searchTerm}
//           sx={{ width: '300px' }}
//         />
//         <Button
//           variant="contained"
//           color="primary"
//           startIcon={<AddIcon />}
//           onClick={handleAddEvent}
//         >
//           Add Event
//         </Button>
//       </Box>
//       {isLoading ? (
//         <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
//           <CircularProgress />
//         </Box>
//       ) : error ? (
//         <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
//           <Typography color="error">{error}</Typography>
//         </Box>
//       ) : (
//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>Serial No.</TableCell>
//                 <TableCell>Title</TableCell>
//                 <TableCell>Description</TableCell>
//                 <TableCell>Image</TableCell>
//                 <TableCell>Coordinator</TableCell>
//                 <TableCell>File Path</TableCell>
//                 <TableCell>Status</TableCell>
//                 <TableCell>Actions</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {events.length > 0 ? (
//                 events.map((event, index) => (
//                   <TableRow key={event._id}>
//                     <TableCell>{page * rowsPerPage + index + 1}</TableCell>
//                     <TableCell>{event.event_title}</TableCell>
//                     <TableCell>{event.event_description}</TableCell>
//                     <TableCell>
//                       {event.event_image ? (
//                         <img src={event.event_image} alt={event.event_title} style={{ width: '100px', height: 'auto' }} />
//                       ) : (
//                         'No Image'
//                       )}
//                     </TableCell>
//                     <TableCell>{event.event_coordinator}</TableCell>
//                     <TableCell>{event.file_path}</TableCell>
//                     <TableCell>{event.isDeleted ? 'Deleted' : 'Active'}</TableCell>
//                     <TableCell>
//                       <IconButton color="primary" onClick={() => handleEditEvent(event._id)}>
//                         <EditIcon />
//                       </IconButton>
//                       <IconButton color="error" onClick={() => handleDeleteEvent(event._id)}>
//                         <DeleteIcon />
//                       </IconButton>
//                     </TableCell>
//                   </TableRow>
//                 ))
//               ) : (
//                 <TableRow>
//                   <TableCell colSpan={8} align="center">
//                     No events found
//                   </TableCell>
//                 </TableRow>
//               )}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       )}
//       <TablePagination
//         rowsPerPageOptions={[10, 25, 50]}
//         component="div"
//         count={totalItems}
//         rowsPerPage={rowsPerPage}
//         page={page}
//         onPageChange={(event, newPage) => setPage(newPage)}
//         onRowsPerPageChange={(event) => {
//           setRowsPerPage(parseInt(event.target.value, 10));
//           setPage(0);
//         }}
//       />
//     </div>
//   );
// };

// export default EventListPage;
import React from 'react'

function EventListPage() {
  return (
    <div>EventListPage</div>
  )
}

export default EventListPage
