import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const PartnerEditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    partner_name: '',
    contact_person: '',
    mobile: '',
    email: '',
    agreement_start_date: '',
    agreement_end_date: '',
    Partner_Category_id: '',
    Partner_sub_Category_id: '',
    countryId: '',
    stateId: '',
    districtId: '',
    townId: '',
    branch_name: "", // New field
    account_name: "", // New field
    account_number: "", // New field
    ifsc_code: "", // New field
  });
  
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('success');
  
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [loading, setLoading] = useState({
    categories: false,
    subCategories: false,
    countries: false,
    states: false,
    districts: false,
    towns: false,
  });

  useEffect(() => {
    const fetchPartner = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/${id}`);
        setFormData(response.data.data);
      } catch (err) {
        console.error('Error fetching partner data:', err);
      }
    };

    const fetchCategories = async () => {
      setLoading(prev => ({ ...prev, categories: true }));
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/category`);
        setCategories(response.data.data);
      } finally {
        setLoading(prev => ({ ...prev, categories: false }));
      }
    };

    const fetchCountries = async () => {
      setLoading(prev => ({ ...prev, countries: true }));
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country?limit=300`);
        setCountries(response.data.data);
      } finally {
        setLoading(prev => ({ ...prev, countries: false }));
      }
    };

    fetchPartner();
    fetchCategories();
    fetchCountries();
  }, [id]);

  useEffect(() => {
    const fetchSubCategories = async () => {
      setLoading(prev => ({ ...prev, subCategories: true }));
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/sub_category`);
        setSubCategories(response.data.data);
      } finally {
        setLoading(prev => ({ ...prev, subCategories: false }));
      }
    };

    fetchSubCategories();
  }, []);

  useEffect(() => {
    if (formData.Partner_Category_id) {
      const filtered = subCategories.filter(
        subcategory => subcategory.partner_id === formData.Partner_Category_id
      );
      setFilteredSubCategories(filtered);
    } else {
      setFilteredSubCategories(subCategories);
    }
  }, [subCategories, formData.Partner_Category_id]);

  const fetchStates = async (countryId) => {
    setLoading(prev => ({ ...prev, states: true }));
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state/by-country/?limit=300`, {
        params: { countryId }
      });
      setStates(response.data.data);
    } finally {
      setLoading(prev => ({ ...prev, states: false }));
    }
  };

  const fetchDistricts = async (stateId) => {
    setLoading(prev => ({ ...prev, districts: true }));
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/district/by-state?limit=300`, {
        params: { stateId }
      });
      setDistricts(response.data.data);
    } finally {
      setLoading(prev => ({ ...prev, districts: false }));
    }
  };

  // const fetchTowns = async (districtId) => {
  //   setLoading(prev => ({ ...prev, towns: true }));
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/town/by-district`, {
  //       params: { districtId }
  //     });
  //     setTowns(response.data.data);
  //   } finally {
  //     setLoading(prev => ({ ...prev, towns: false }));
  //   }
  // };

  useEffect(() => {
    if (formData.countryId) {
      fetchStates(formData.countryId);
      console.log("fetched staes:",states);
      
    } else {
      setStates([]);
      setFormData(prev => ({ ...prev, stateId: '', districtId: '', townId: '' }));
    }
  }, [formData.countryId]);

  useEffect(() => {
    if (formData.stateId) {
      fetchDistricts(formData.stateId);
      console.log("district fetched:",districts);
      
    } else {
      setDistricts([]);
      setFormData(prev => ({ ...prev, districtId: '', townId: '' }));
    }
  }, [formData.stateId]);

  // useEffect(() => {
  //   if (formData.districtId) {
  //     fetchTowns(formData.districtId);
  //   } else {
  //     setTowns([]);
  //     setFormData(prev => ({ ...prev, townId: '' }));
  //   }
  // }, [formData.districtId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/partner/${id}`, formData);
      setSnackbarMessage('Partner updated successfully!');
      setSnackbarType('success');
      setSnackbarOpen(true);
      setTimeout(() => navigate('/partner_list'), 2000);
    } catch (error) {
      setSnackbarMessage('Error updating partner.');
      setSnackbarType('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <div>
      <h1>Edit Partner</h1>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Partner Name"
              name="partner_name"
              variant="outlined"
              fullWidth
              value={formData.partner_name}
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Contact Person"
              name="contact_person"
              variant="outlined"
              fullWidth
              value={formData.contact_person}
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Mobile"
              name="mobile"
              type="tel"
              variant="outlined"
              fullWidth
              value={formData.mobile}
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              name="email"
              type="email"
              variant="outlined"
              fullWidth
              value={formData.email}
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="agreement_start_date"
              type="date"
              variant="outlined"
              fullWidth
              value={formData.agreement_start_date.split('T')[0]} // Format for date input
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="agreement_end_date"
              type="date"
              variant="outlined"
              fullWidth
              value={formData.agreement_end_date.split('T')[0]} // Format for date input
              onChange={handleChange}
              required
              sx={{ mb: 2 }}
            />
          </Grid>
          {/* Category */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Category</InputLabel>
              <Select
                name="Partner_Category_id"
                value={formData.Partner_Category_id}
                onChange={handleChange}
                label="Category"
              >
                 {categories.map((category) => (
          <MenuItem key={category._id} value={category._id}>
            {category.partner_name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
          </Grid>
          {/* Sub-category */}
          <Grid item xs={12} sm={6}>
<FormControl fullWidth margin="normal" disabled={!formData.Partner_Category_id}>
<InputLabel> Sub-Category</InputLabel>
<Select
  name="Partner_sub_Category_id"
  value={formData.Partner_sub_Category_id}
  onChange={handleChange}
>
  {filteredSubCategories.map(subCat => (
    <MenuItem key={subCat._id} value={subCat._id}>{subCat.SubCategory_name}</MenuItem>
  ))}
</Select>
</FormControl>
</Grid>

          {/* Country */}
          <Grid item xs={12} md={6}>
  <FormControl fullWidth>
    <InputLabel>Country</InputLabel>
    <Select
      name="countryId"
      value={formData.countryId}
      onChange={handleChange}
    >
      {countries.map((country) => (
        <MenuItem key={country._id} value={country._id}>
          {country.country_name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>
      {/* State */}
<Grid item xs={12} md={6}>
  <FormControl fullWidth>
    <InputLabel>State</InputLabel>
    <Select
      name="stateId"
      value={formData.stateId}
      onChange={handleChange}
      disabled={!formData.countryId}
    >
      {states.map((state) => (
        <MenuItem key={state._id} value={state._id}>
          {state.state_name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>
{/* District */}
<Grid item xs={12} md={6}>
  <FormControl fullWidth>
    <InputLabel>District</InputLabel>
    <Select
      name="districtId"
      value={formData.districtId}
      onChange={handleChange}
      disabled={!formData.stateId}
    >
      {districts.map((district) => (
        <MenuItem key={district._id} value={district._id}>
          {district.district_name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>
  {/* bank deatils */}
  <Grid item xs={12}>
    <Typography variant="h6" gutterBottom>
      Bank Details
    </Typography>
  </Grid>
            {/* Branch Name */}
  <Grid item xs={12} sm={6}>
    <TextField
      label="Branch Name"
      name="branch_name"
      variant="outlined"
      fullWidth
      value={formData.branch_name}
      onChange={handleChange}
      required
      sx={{ mb: 2 }}
    />
  </Grid>
  
  {/* Account Name */}
  <Grid item xs={12} sm={6}>
    <TextField
      label="Account Name"
      name="account_name"
      variant="outlined"
      fullWidth
      value={formData.account_name}
      onChange={handleChange}
      required
      sx={{ mb: 2 }}
    />
  </Grid>

  {/* Account Number */}
  <Grid item xs={12} sm={6}>
    <TextField
      label="Account Number"
      name="account_number"
      type="text"
      variant="outlined"
      fullWidth
      value={formData.account_number}
      onChange={handleChange}
      required
      sx={{ mb: 2 }}
    />
  </Grid>

  {/* IFSC Code */}
  <Grid item xs={12} sm={6}>
    <TextField
      label="IFSC Code"
      name="ifsc_code"
      variant="outlined"
      fullWidth
      value={formData.ifsc_code}
      onChange={handleChange}
      required
      sx={{ mb: 2 }}
    />
  </Grid>

        
          <Grid item xs={12}>
            <Button variant="contained" type="submit">
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PartnerEditPage;
