import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const AddEventPage = () => {
  const [formData, setFormData] = useState({
    event_title: '',
    event_description: '',
    event_image: null,
    event_coordinator: '',
    start_time: '',
    end_time: '',
    location: '',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file' && files.length > 0) {
      setFormData({
        ...formData,
        event_image: files[0],
      });
      setImagePreview(URL.createObjectURL(files[0]));
      setFormErrors({ ...formErrors, event_image: null });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      if (!value) {
        setFormErrors({ ...formErrors, [name]: 'This field is required' });
      } else {
        const { [name]: removedError, ...rest } = formErrors;
        setFormErrors(rest);
      }
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.event_title) errors.event_title = 'Event title is required';
    if (!formData.event_description) errors.event_description = 'Event description is required';
    if (!formData.event_image) errors.event_image = 'Event image is required';
    if (!formData.event_coordinator) errors.event_coordinator = 'Event coordinator is required';
    if (!formData.start_time) errors.start_time = 'Start time is required';
    if (!formData.end_time) errors.end_time = 'End time is required';
    if (!formData.location) errors.location = 'Location is required';

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Form is valid if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/event`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      navigate('/event_list');
    } catch (err) {
      setError('Error adding event');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = () => {
    setImagePreview(null);
    setFormData({ ...formData, event_image: null });
    setFormErrors({ ...formErrors, event_image: 'Event image is required' });
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Add Event
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          name="event_title"
          label="Event Title"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.event_title}
          onChange={handleChange}
          required
          error={!!formErrors.event_title}
          helperText={formErrors.event_title}
        />
        <TextField
          name="event_description"
          label="Event Description"
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={formData.event_description}
          onChange={handleChange}
          required
          error={!!formErrors.event_description}
          helperText={formErrors.event_description}
        />
        <input
          name="event_image"
          type="file"
          accept="image/*"
          onChange={handleChange}
          required
        />
        {formErrors.event_image && (
          <Typography color="error">{formErrors.event_image}</Typography>
        )}
        {imagePreview && (
          <Box mt={2} position="relative">
            <img
              src={imagePreview}
              alt="Preview"
              style={{ width: '200px', height: 'auto', borderRadius: '4px' }}
            />
            <IconButton
              onClick={handleRemoveImage}
              style={{ position: 'absolute', top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        <TextField
          name="event_coordinator"
          label="Event Coordinator"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.event_coordinator}
          onChange={handleChange}
          required
          error={!!formErrors.event_coordinator}
          helperText={formErrors.event_coordinator}
        />
        <TextField
          name="start_time"
          label="Start Time"
          type="datetime-local"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.start_time}
          onChange={handleChange}
          required
          error={!!formErrors.start_time}
          helperText={formErrors.start_time}
          InputLabelProps={{
            shrink: true, // Makes the label always visible
          }}
        />
        <TextField
          name="end_time"
          label="End Time"
          type="datetime-local"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.end_time}
          onChange={handleChange}
          required
          error={!!formErrors.end_time}
          helperText={formErrors.end_time}
          InputLabelProps={{
            shrink: true, // Makes the label always visible
          }}
        />
        <TextField
          name="location"
          label="Location"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData.location}
          onChange={handleChange}
          required
          error={!!formErrors.location}
          helperText={formErrors.location}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 2, backgroundColor: "#e0e0e0" }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Add Event'}
        </Button>
        {error && <Typography color="error">{error}</Typography>}
      </Box>
    </div>
  );
};

export default AddEventPage;
