import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Switch,
  CircularProgress,
  Container,
  Typography,
  Box,
  MenuItem,
  TablePagination,
  Paper,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import axios from "axios";

const DepartmentSubCategoryList = () => {
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentSubCategory, setCurrentSubCategory] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [subCategoryToDelete, setSubCategoryToDelete] = useState(null);

  const [errors, setErrors] = useState({
    name: "",
    category: "",
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    fetchSubCategories();
    fetchDepartmentCategories();
  }, [page, rowsPerPage, searchTerm]);

  const fetchSubCategories = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/department_category_sub`,
        {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            search: searchTerm,
          },
        }
      );
      setSubCategories(response.data.data);
      setTotalCount(response.data.totalCount);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching sub-categories", error);
      setLoading(false);
    }
  };

  const fetchDepartmentCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND}/api/department_category`
      );
      setCategories(response.data.data.filter((cat) => cat.status === 1)); // Filter categories based on status
    } catch (error) {
      console.error("Error fetching department categories", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const validateFields = () => {
    const newErrors = {
      name: "",
      category: "",
    };
    let isValid = true;

    if (!currentSubCategory.department_sub_category_name) {
      newErrors.name = "Sub-Category Name is required";
      isValid = false;
    }

    if (!currentSubCategory.department_category_id) {
      newErrors.category = "Department Category is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSave = async () => {
    if (!validateFields()) {
      return;
    }

    try {
      let response;
      if (isEdit) {
        response = await axios.put(
          `${process.env.REACT_APP_BACKEND}/api/department_category_sub/${currentSubCategory._id}`,
          currentSubCategory
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/department_category_sub`,
          currentSubCategory
        );
        setSubCategories([...subCategories, response.data.data]);
      }
      console.log("Save successful:", response.data);
      fetchSubCategories();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving sub-category", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND}/api/department_category_sub/${id}`
      );
      fetchSubCategories();
      handleCloseConfirmDialog();
    } catch (error) {
      console.error("Error deleting sub-category", error);
    }
  };

  const handleToggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND}/api/department_category_sub/${id}`,
        { status: newStatus }
      );
      fetchSubCategories();
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const handleOpenDialog = (subCategory) => {
    setCurrentSubCategory(
      subCategory
        ? { ...subCategory }
        : { department_sub_category_name: "", department_category_id: "" }
    );
    setIsEdit(!!subCategory);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCurrentSubCategory({});
    setOpenDialog(false);
  };

  const handleOpenConfirmDialog = (subCategory) => {
    setSubCategoryToDelete(subCategory);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setSubCategoryToDelete(null);
    setOpenConfirmDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container maxWidth="xl">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4" gutterBottom>
          Department Sub-Categories
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearch}
          style={{ width: "300px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenDialog()}
        >
          Create
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial No.</TableCell>
                  <TableCell>Sub-Category Name</TableCell>
                  <TableCell>Department Category</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subCategories.length > 0 ? (
                  subCategories
                    .filter((subCategory) =>
                      subCategory.department_sub_category_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) // Filter based on search term
                    .map((subCategory, index) => (
                      <TableRow key={subCategory._id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>
                          {subCategory.department_sub_category_name}
                        </TableCell>
                        <TableCell>
                          {categories.find(
                            (cat) =>
                              cat._id === subCategory.department_category_id
                          )?.department_category_name || "N/A"}
                        </TableCell>
                        <TableCell>
                          <Switch
                            checked={subCategory.status === 1}
                            onChange={() =>
                              handleToggleStatus(
                                subCategory._id,
                                subCategory.status
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => handleOpenDialog(subCategory)}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => handleOpenConfirmDialog(subCategory)}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No sub-categories found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {isEdit ? "Edit Sub-Category" : "Add Sub-Category"}
        </DialogTitle>
        <DialogContent>
          <TextField
            select
            label="Department Category"
            fullWidth
            margin="normal"
            value={currentSubCategory.department_category_id || ""}
            required
            onChange={(e) =>
              setCurrentSubCategory({
                ...currentSubCategory,
                department_category_id: e.target.value,
              })
            }
            error={!!errors.category}
            helperText={errors.category}
          >
            {categories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.department_category_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Sub-Category Name"
            fullWidth
            margin="normal"
            value={currentSubCategory.department_sub_category_name || ""}
            required
            onChange={(e) =>
              setCurrentSubCategory({
                ...currentSubCategory,
                department_sub_category_name: e.target.value,
              })
            }
            error={!!errors.name}
            helperText={errors.name}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            {isEdit ? "Update" : "Save"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this sub-category?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (subCategoryToDelete) {
                handleDelete(subCategoryToDelete._id);
              }
            }}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default DepartmentSubCategoryList;
