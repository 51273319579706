import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    TextField,
    Typography,
    CircularProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const EditLabname = () => {
    const { id } = useParams();
    const [labName, setLabName] = useState('');
    const [departmentId, setDepartmentId] = useState('');
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department`);
            setDepartments(response.data.data);
        } catch (err) {
            setError('Failed to fetch departments.');
            console.error(err);
        }
    };

    const fetchLabname = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/labnames/${id}`);
            const { lab_name, department } = response.data.data;
            setLabName(lab_name);
            setDepartmentId(department?._id || ''); // Ensure you are setting the department ID
        } catch (err) {
            setError('Failed to fetch lab name.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDepartments();
        fetchLabname();
    }, [id]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/labnames/${id}`, {
                lab_name: labName,
                department: departmentId,
                status: true,
                updated_by: null,
            });
            if (response.status === 200) {
                navigate('/labnames');
            }
        } catch (err) {
            setError('Error updating lab name');
            console.error(err);
        }
    };

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Box padding={2}>
            <Typography variant="h4">Edit Lab Name</Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Lab Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={labName}
                    onChange={(e) => setLabName(e.target.value)}
                    required
                />
                <FormControl fullWidth margin="normal" required>
                    <InputLabel>Department</InputLabel>
                    <Select
                        value={departmentId}
                        onChange={(e) => setDepartmentId(e.target.value)}
                    >
                        {departments.map((department) => (
                            <MenuItem key={department._id} value={department._id}>
                                {department.department_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary" type="submit">
                    Update Lab Name
                </Button>
            </form>
        </Box>
    );
};

export default EditLabname;
