import React, { useRef } from "react";
import {
  Grid,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
} from "@mui/material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import QuizIcon from "@mui/icons-material/Quiz";
import ProjectIcon from "@mui/icons-material/Build";
import CommentIcon from "@mui/icons-material/Comment";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const dashboardData = [
  { title: "NO OF SCHOOLS", number1: 40, number2: 36, color1: "#fde0a9", color2: "#e4443f" },
  { title: "NO OF TEACHERS", number1: 40, number2: 36, color1: "#fde0a9", color2: "#e4443f" },
  { title: "NO OF STUDENTS", number1: 40, number2: 36, color1: "#fde0a9", color2: "#e4443f" },
  { title: "PROJECTS SUBMITTED", number1: 40, number2: 36, color1: "#fde0a9", color2: "#e4443f" },
  { title: "NO OF ASSESSMENTS", number1: 40, number2: 36, color1: "#fde0a9", color2: "#e4443f" },
  { title: "NO OF QUIZZES", number1: 40, number2: 32, color1: "#fde0a9", color2: "#e4443f" },
  { title: "NO OF TICKETS", number1: 40, number2: 36, color1: "#fde0a9", color2: "#e4443f" },
  { title: "TASKS SUBMITTED", number1: 40, number2: 36, color1: "#fde0a9", color2: "#e4443f" },
];

const progressData = [
  { value: 33, label: "> COURSES", icon: MenuBookIcon },
  { value: 33, label: "> CHAPTERS", icon: MenuBookIcon },
  { value: 33, label: "> UNITS", icon: MenuBookIcon },
  { value: 33, label: "> QUIZZES", icon: QuizIcon },
  { value: 4, label: "> PROJECTS", icon: ProjectIcon },
  { value: 13, label: "> COMMENTS", icon: CommentIcon },
];

const circleData = [
  { title: "> Score", value: 67 },
  { title: "> Teacher Rating", value: 5 },
  { title: "> Quiz", value: 73 },
  { title: "> Class Average", value: 67 },
  { title: "> Engagement", value: 44 },
  { title: "> Discussion", value: 56 },
  { title: "> Consistency", value: 78 },
  { title: "> Critical Thinking", value: 34 },
  { title: "> Creative Thinking", value: 52 },
];

const Dashboard = () => {
  const scrollRef = useRef(null);

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  return (
    <Container maxWidth="xl" sx={{ paddingY: 4, overflowX: 'hidden' }}>
      <Grid container spacing={2}>
        {dashboardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <DashboardCard {...card} />
          </Grid>
        ))}

        <Grid container item xs={12} spacing={2}>
          {["SCHOOLS", "TEACHERS", "STUDENTS", "PROJECTS"].map((type, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <RatingCard
                title={`${type} RATING`}
                excellent={40}
                good={22}
                average={12}
                bad={6}
                barWidths={[40, 60, 60, 25]}
              />
            </Grid>
          ))}
        </Grid>

        <Grid container item xs={12} spacing={2} sx={{ paddingTop: 2 }}>
          {progressData.map((item, index) => (
            <Grid item key={index}>
              <Progress score={item.value} total={100} title={item.label} IconComponent={item.icon} />
            </Grid>
          ))}
        </Grid>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            overflow: "hidden",
            paddingX: { xs: 1, sm: 2 },
            mt: 4,
          }}
        >
          <Box
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fde0a9",
              borderRadius: "20%",
              cursor: "pointer",
              marginBottom: "70px",
              boxShadow: 3,
              flexShrink: 0,
            }}
            onClick={handleScrollLeft}
          >
            <ArrowBackIosNewIcon />
          </Box>

          <Box
            ref={scrollRef}
            sx={{
              display: "flex",
              overflowX: "auto",
              scrollSnapType: "x mandatory",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              width: "calc(100% - 80px)",
              height: "200px",
            }}
          >
            {circleData.map((circle, index) => (
              <Box
                key={index}
                sx={{
                  flexShrink: 0,
                  scrollSnapAlign: "start",
                  padding: "0 8px",
                  width: { xs: "100px", sm: "120px" },
                }}
              >
                <CircleCard title={circle.title} value={circle.value} />
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fde0a9",
              borderRadius: "20%",
              cursor: "pointer",
              boxShadow: 3,
              marginBottom: "70px",
              flexShrink: 0,
            }}
            onClick={handleScrollRight}
          >
            <ArrowForwardIosIcon />
          </Box>
        </Box>
      </Grid>
    </Container>
  );
};

const DashboardCard = ({ title, number1, number2, color1, color2 }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      borderRadius: "8px",
      boxShadow: 3,
      backgroundColor: color1,
      padding: { xs: "12px", sm: "16px", md: "20px" },
    }}
  >
    {/* Title section with improved handling for long text */}
    <Typography
      variant="h5"
      align="center"
      fontWeight="bold"
      sx={{
        marginBottom: { xs: 1, sm: 2 },
        fontSize: { xs: "16px", sm: "20px", md: "24px" },
        whiteSpace: "normal",
        // overflow: "hidden",
        textOverflow: "ellipsis",
        maxHeight: "48px", // Adjust this as needed
        lineHeight: "1.2em",
        display: "-webkit-box",
        WebkitLineClamp: 2,    // Ensures the title is limited to two lines
        WebkitBoxOrient: "vertical",
      }}
    >
      {title}
    </Typography>

    {/* Inner cards, fixed layout to prevent shifting */}
    <Grid container spacing={1} justifyContent="center" alignItems="center">
      {[number1, number2].map((num, idx) => (
        <Grid item xs={5} key={idx}>
          <Box
            sx={{
              backgroundColor: idx === 0 ? color2 : "#000",
              color: idx === 0 ? "#fff" : "rgba(255, 255, 255, 0.7)",
              textAlign: "center",
              padding: { xs: "10px", sm: "16px", md: "20px" },
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: { sm: "17px", md: "19px", lg: "22px" },
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {num}
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Box>
);

const RatingCard = ({ title, excellent, good, average, bad, barWidths }) => {
  const ratings = [
    { label: "EXCELLENT", value: excellent, color: "#f05622", width: barWidths[1] },
    { label: "GOOD", value: good, color: "#0087c0", width: barWidths[1] },
    { label: "AVERAGE", value: average, color: "#9b2e9b", width: barWidths[2] },
    { label: "BAD", value: bad, color: "#323232", width: barWidths[3] },
  ];

  return (
    <Card sx={{ borderRadius: "16px", boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h6" align="center" gutterBottom>
          {title}
        </Typography>
        <Grid container spacing={2}>
          {ratings.map((rating, index) => (
            <Grid item xs={12} key={index}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {/* Bar (Label) on the Left */}
                <Box
                  sx={{
                    backgroundColor: rating.color,
                    color: "#fff",
                    padding: "8px 16px",
                    width: `${rating.width}%`, // Customize the width with percentage
                    textAlign: "center",
                  }}
                >
                  <Typography variant="body1" fontWeight="bold">
                    {rating.label}
                  </Typography>
                </Box>

                {/* Set of Numbers on the Right */}
                <Box display="flex" alignItems="center">
                  {/* Left number set */}
                  <Box
                    sx={{
                      backgroundColor: rating.color, // Same color as the rating bar
                      padding: "8px",
                      width: "60px",
                      color: "#fff",
                      textAlign: "center",
                      marginRight: "8px",
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      {rating.value}
                    </Typography>
                  </Box>

                  {/* Right number set */}
                  <Box
                    sx={{
                      backgroundColor: rating.color, // Same color as the rating bar
                      padding: "8px",
                      width: "60px",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      {rating.value}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

const Progress = ({ score, title, IconComponent }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: { xs: "100px", sm: "120px", md: "140px" }, // Responsive circle width
        height: { xs: "100px", sm: "120px", md: "140px" }, // Responsive circle height
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: { xs: "80px", sm: "100px", md: "120px" }, // Responsive inner circle width
          height: { xs: "80px", sm: "100px", md: "120px" }, // Responsive inner circle height
          borderRadius: "50%",
          backgroundColor: "black",
          border: "8px solid #ac3a38", // Adjusted border size for consistency
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {IconComponent && (
          <IconComponent
            sx={{
              fontSize: { xs: "20px", sm: "30px", md: "35px" }, // Responsive icon size
              position: "absolute",
              color: "white",
              bottom: { xs: "5px", sm: "10px", md: "15px" }, // Adjust bottom position based on screen size
            }}
          />
        )}
        <Typography
          variant="h5"
          sx={{
            color: "white",
            fontSize: { xs: "18px", sm: "22px", md: "28px" }, // Responsive font size for the score
            zIndex: 1,
            position: "absolute",
            top: { xs: "5px", sm: "8px", md: "10px" }, // Adjust position based on screen size
          }}
        >
          {score}
        </Typography>
      </Box>

      <Typography
        variant="body2"
        sx={{
          marginTop: "8px",
          fontSize: { xs: "12px", sm: "14px" }, // Responsive title font size
          textAlign: "center",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const CircleCard = ({ title, value }) => {
  return (
    <Box
      sx={{
        width: { xs: "90px", sm: "110px", md: "120px" }, // Responsive width
        height: { xs: "90px", sm: "110px", md: "120px" }, // Responsive height
        position: "relative",
        marginX: { xs: "4px", sm: "6px", md: "8px" }, // Responsive margin
      }}
    >
      {/* Full circle */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          border: "10px solid #fde0a9", // Circle stroke color
          position: "relative",
          marginRight: "10px",
        }}
      >
        {/* Top half of the circle */}
        <Box
          sx={{
            width: "100%",
            height: "50%",
            backgroundColor: "#000", // Top half background
            borderTopLeftRadius: "60px",
            borderTopRightRadius: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
          }}
        >
          <Typography
            variant="h3"
            color="#fff"
            sx={{
              fontSize: { xs: "16px", sm: "20px", md: "28px" }, // Responsive font size for the value
            }}
          >
            {value}
          </Typography>
        </Box>

        {/* Bottom half of the circle */}
        <Box
          sx={{
            width: "100%",
            height: "50%",
            backgroundColor: "#ac3a38", // Bottom half background
            borderBottomLeftRadius: "60px",
            borderBottomRightRadius: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            bottom: 0,
          }}
        >
          <Typography
            variant="h4"
            color="#fff"
            sx={{
              fontSize: { xs: "16px", sm: "20px", md: "28px" }, // Responsive font size for bottom number
            }}
          >
            100
          </Typography>
        </Box>
      </Box>

      {/* Title below the circle */}
      <Typography
        variant="body2"
        align="center"
        sx={{
          position: "absolute",
          bottom: "-20px",
          width: "100%",
          fontWeight: "bold",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: { xs: "12px", sm: "14px" }, // Responsive title font size
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default Dashboard;
