import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const EditAssessment = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [assessment, setAssessment] = useState({
    assessment_title: '',
    unit: '',
    total_score: '',
    expire_time: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState('success');
  const [units, setUnits] = useState([]);
  const [unitsLoading, setUnitsLoading] = useState(true);

  const fetchAssessment = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/assessment/${id}`);
      setAssessment(response.data.data);
    } catch (err) {
      console.error(err);
      setError('Error fetching assessment details');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssessment();
  }, [id]);

  const fetchUnits = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/list/unit`);
      setUnits(response.data.data); // Assuming response data structure
    } catch (err) {
      console.error(err);
      setError('Error fetching units');
    } finally {
      setUnitsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssessment();
    fetchUnits();
  }, [id]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAssessment((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/assessment/${id}`, assessment);
      setSnackbarMessage("Assessment updated successfully");
      setSnackbarType("success");
      setSnackbarOpen(true);
      navigate('/partner/assessment_list'); // Redirect to assessment list
    } catch (err) {
      setSnackbarMessage("Error updating assessment");
      setSnackbarType("error");
      setSnackbarOpen(true);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ padding: "20px", borderRadius: "8px" }}>
      <Typography variant="h4" gutterBottom>
        Edit Assessment
      </Typography>

      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="assessment_title"
                label="Assessment Title"
                variant="outlined"
                value={assessment.assessment_title}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Unit</InputLabel>
                <Select
                  name="unit"
                  value={assessment.unit}
                  onChange={handleChange}
                >
                  {units.map((unit) => (
                    <MenuItem key={unit._id} value={unit._id}>
                      {unit.unitDetails.unit_name} {/* Assuming unit has a 'name' field */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="total_score"
                label="Total Score"
                type="number"
                variant="outlined"
                value={assessment.total_score}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="expire_time"
                label="Expire Time (ms)"
                type="number"
                variant="outlined"
                value={assessment.expire_time}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit" fullWidth sx={{ marginTop: 2, backgroundColor:"#e0e0e0" }}>
                Update Assessment
              </Button>
            </Grid>
          </Grid>
        </form>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditAssessment;
