import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Collapse,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SchoolIcon from "@mui/icons-material/School";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logoFull from "../assets/Logofull.png";
import logoIcon from "../assets/logoicon.png";
import { Link, Outlet } from "react-router-dom";
import CategoryIcon from "@mui/icons-material/Category";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import HandshakeIcon from "@mui/icons-material/Handshake";
import BusinessIcon from "@mui/icons-material/Business";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PublicIcon from "@mui/icons-material/Public"; // For Country
import FlagIcon from "@mui/icons-material/Flag"; // For State
import LocationCityIcon from "@mui/icons-material/LocationCity"; // For District
import PlaceIcon from "@mui/icons-material/Place"; // For Town
import MapIcon from "@mui/icons-material/Map"; // For Place

import GradeIcon from "@mui/icons-material/Grade"; // For Grade
import GroupIcon from "@mui/icons-material/Group"; // For Division
import PersonIcon from "@mui/icons-material/Person"; // For Teachers
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount"; // For Resource Person
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom"; // For Parents

import ApartmentIcon from "@mui/icons-material/Apartment"; // For Department
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"; // For Products
import GroupWorkIcon from "@mui/icons-material/GroupWork"; // For Partner

import EventIcon from "@mui/icons-material/Event"; // For Event
import MailIcon from "@mui/icons-material/Mail"; // For Newsletter
import SlideshowIcon from "@mui/icons-material/Slideshow"; // For Slider
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import { useNavigate } from "react-router-dom";
import "./Layout.css";
import { getRole, removeToken } from "../utils/AuthUtils";
import AssignCourses from "./pages/AssignCourses";

const drawerWidth = 300;
const collapsedWidth = 65;
const collapseScreenWidth = 1500;
const menuItems = [
  { text: "Dashboard", route: "/dashboard", icon: <DashboardIcon /> },
  {
    text: "Masters",
    icon: <SchoolIcon />,
    subItems: [
      {
        text: "Partners",
        icon: <HandshakeIcon />,
        subItems: [
          {
            text: "Category",
            path: "/partner_category",
            icon: <CategoryIcon />,
          },
          {
            text: "Subcategory",
            path: "/sub_category",
            icon: <SubdirectoryArrowRightIcon />,
          },
        ],
      },
      {
        text: "Department",
        icon: <BusinessIcon />,
        subItems: [
          {
            text: "Category",
            path: "/department/category",
            icon: <CategoryIcon />,
          },
          {
            text: "Subcategory",
            path: "/department/sub_category",
            icon: <SubdirectoryArrowRightIcon />,
          },
        ],
      },
      {
        text: "Courses",
        icon: <MenuBookIcon />,
        subItems: [
          {
            text: "Category",
            path: "/courses/category",
            icon: <CategoryIcon />,
          },
          {
            text: "Subcategory",
            path: "/courses/sub_category",
            icon: <SubdirectoryArrowRightIcon />,
          },
        ],
      },
      {
        text: "School",
        icon: <SchoolIcon />,
        subItems: [
          {
            text: "Category",
            path: "/school_category",
            icon: <CategoryIcon />,
          },
          {
            text: "Subcategory",
            path: "/school_sub_category",
            icon: <SubdirectoryArrowRightIcon />,
          },
        ],
      },
      {
        text: "Teacher",
        icon: <EmojiPeopleIcon />,
        subItems: [
          {
            text: "Category",
            path: "/teachers_category",
            icon: <CategoryIcon />,
          },
          {
            text: "Subcategory",
            path: "/teachers_sub_category",
            icon: <SubdirectoryArrowRightIcon />,
          },
        ],
      },
      {
        text: "Student",
        icon: <EmojiPeopleIcon />,
        subItems: [
          {
            text: "Category",
            path: "/students_category",
            icon: <CategoryIcon />,
          },
          {
            text: "Subcategory",
            path: "/students_sub_category",
            icon: <SubdirectoryArrowRightIcon />,
          },
        ],
      },
      {
        text: "Product",
        icon: <ProductionQuantityLimitsIcon />,
        subItems: [
          {
            text: "Category",
            path: "/product/category",
            icon: <CategoryIcon />,
          },
          {
            text: "Subcategory",
            path: "/product/sub_category",
            icon: <SubdirectoryArrowRightIcon />,
          },
        ],
      },
      {
        text: "Project",
        icon: <AssignmentIcon />,
        subItems: [
          {
            text: "Category",
            path: "/project/category",
            icon: <CategoryIcon />,
          },
          {
            text: "Subcategory",
            path: "/project/sub_category",
            icon: <SubdirectoryArrowRightIcon />,
          },
        ],
      },
    ],
  },
  {
    text: "Country/State",
    icon: <PublicIcon />, // Icon for Country/State menu
    subItems: [
      { text: "Country", path: "/country_list", icon: <PublicIcon /> },
      { text: "State", path: "/state_list", icon: <FlagIcon /> },
      { text: "District", path: "/district_list", icon: <LocationCityIcon /> },
      { text: "Town", path: "/town_list", icon: <PlaceIcon /> },
      { text: "Place", path: "/place_list", icon: <MapIcon /> },
    ],
  },
  {
    text: "School",
    icon: <BusinessIcon />,
    subItems: [
      // { text: "Grade", path: "", icon: <GradeIcon /> },
      // { text: "Division", path: "", icon: <GroupIcon /> },
      { text: "Teachers", path: "/teachlist", icon: <PersonIcon /> },
      // { text: "Resource Person", path: "", icon: <SupervisorAccountIcon /> },
      { text: "Students", path: "/studlist", icon: <SchoolIcon /> },
      { text: "Parents", path: "/parlist", icon: <FamilyRestroomIcon /> },
    ],
  },
  {
    text: "Academics",
    icon: <SchoolIcon />,
    subItems: [
      { text: "School", path: "/school_list", icon: <BusinessIcon /> },
      { text: "Department", path: "/department_list", icon: <ApartmentIcon /> },
      { text: "Courses", path: "/courses_list", icon: <MenuBookIcon /> },
      // { text: "Chapters", path: "/chapter_table", icon: <GroupWorkIcon /> },
      { text: "Units", path: "/unitlist", icon: <SlideshowIcon /> },
      { text: "Create Units", path: "/uploadunit", icon: <SlideshowIcon /> },
      { text: "Chapters", path: "/chapters", icon: <GroupWorkIcon /> },
      { text: "Lab Names", path: "/labnames", icon: <PublicIcon /> },
    ],
  },
  {
    text: "Company",
    icon: <ApartmentIcon />,
    subItems: [
      { text: "Partner", path: "/partner_list", icon: <GroupWorkIcon /> },
      { text: "Products", path: "/product_list", icon: <ShoppingCartIcon /> },
      { text: "Event", path: "/event_list", icon: <EventIcon /> },
      { text: "Company News", path: "/company_news_list", icon: <EventIcon /> },
      { text: "NewsLetter", path: "/newsletter_list", icon: <MailIcon /> },
      { text: "Slider", path: "/slider_list", icon: <SlideshowIcon /> },
      {
        text: "Career Planner",
        path: "/career_planner_list",
        icon: <SlideshowIcon />,
      },
    ],
  },
  {
    text: "Assign Courses",
    route: "/assign_courses_list",
    icon: <SlideshowIcon />,
  },
];

const buttonPaths = ["/courses_list", "/uploadunit"];

const buttonLabels = [
  "course",
  "Content",
  // "Assessment",
  "Project",
  "Quiz",
  "Progress Card",
  "Tickets",
  "Task",
  "Settings",
];

const Layout = () => {
  const [role, setRole] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [openMenus, setOpenMenus] = useState({});
  const [openSubMenus, setOpenSubMenus] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const role = getRole();
    setRole(role);

    const getMenuItemsByRole = (role) => {
      switch (role) {
        case "super_admin":
        case "company":
          return [
            { text: "Dashboard", route: "/dashboard", icon: <DashboardIcon /> },
            {
              text: "Masters",
              icon: <SchoolIcon />,
              subItems: [
                {
                  text: "Partners",
                  icon: <HandshakeIcon />,
                  subItems: [
                    {
                      text: "Category",
                      path: "/partner_category",
                      icon: <CategoryIcon />,
                    },
                    {
                      text: "Subcategory",
                      path: "/sub_category",
                      icon: <SubdirectoryArrowRightIcon />,
                    },
                  ],
                },
                {
                  text: "Department",
                  icon: <BusinessIcon />,
                  subItems: [
                    {
                      text: "Category",
                      path: "/department/category",
                      icon: <CategoryIcon />,
                    },
                    {
                      text: "Subcategory",
                      path: "/department/sub_category",
                      icon: <SubdirectoryArrowRightIcon />,
                    },
                  ],
                },
                {
                  text: "Courses",
                  icon: <MenuBookIcon />,
                  subItems: [
                    {
                      text: "Category",
                      path: "/courses/category",
                      icon: <CategoryIcon />,
                    },
                    {
                      text: "Subcategory",
                      path: "/courses/sub_category",
                      icon: <SubdirectoryArrowRightIcon />,
                    },
                  ],
                },
                {
                  text: "School",
                  icon: <SchoolIcon />,
                  subItems: [
                    {
                      text: "Category",
                      path: "/school_category",
                      icon: <CategoryIcon />,
                    },
                    {
                      text: "Subcategory",
                      path: "/school_sub_category",
                      icon: <SubdirectoryArrowRightIcon />,
                    },
                  ],
                },
                {
                  text: "Teacher",
                  icon: <EmojiPeopleIcon />,
                  subItems: [
                    {
                      text: "Category",
                      path: "/teachers_category",
                      icon: <CategoryIcon />,
                    },
                    {
                      text: "Subcategory",
                      path: "/teachers_sub_category",
                      icon: <SubdirectoryArrowRightIcon />,
                    },
                  ],
                },
                {
                  text: "Student",
                  icon: <EmojiPeopleIcon />,
                  subItems: [
                    {
                      text: "Category",
                      path: "/students_category",
                      icon: <CategoryIcon />,
                    },
                    {
                      text: "Subcategory",
                      path: "/students_sub_category",
                      icon: <SubdirectoryArrowRightIcon />,
                    },
                  ],
                },
                {
                  text: "Product",
                  icon: <ProductionQuantityLimitsIcon />,
                  subItems: [
                    {
                      text: "Category",
                      path: "/product/category",
                      icon: <CategoryIcon />,
                    },
                    {
                      text: "Subcategory",
                      path: "/product/sub_category",
                      icon: <SubdirectoryArrowRightIcon />,
                    },
                  ],
                },
                {
                  text: "Project",
                  icon: <AssignmentIcon />,
                  subItems: [
                    {
                      text: "Category",
                      path: "/project/category",
                      icon: <CategoryIcon />,
                    },
                    {
                      text: "Subcategory",
                      path: "/project/sub_category",
                      icon: <SubdirectoryArrowRightIcon />,
                    },
                  ],
                },
              ],
            },
            {
              text: "Country/State",
              icon: <PublicIcon />, // Icon for Country/State menu
              subItems: [
                {
                  text: "Country",
                  path: "/country_list",
                  icon: <PublicIcon />,
                },
                { text: "State", path: "/state_list", icon: <FlagIcon /> },
                {
                  text: "District",
                  path: "/district_list",
                  icon: <LocationCityIcon />,
                },
                { text: "Town", path: "/town_list", icon: <PlaceIcon /> },
                { text: "Place", path: "/place_list", icon: <MapIcon /> },
              ],
            },
            {
              text: "School",
              icon: <BusinessIcon />,
              subItems: [
                // { text: "Grade", path: "", icon: <GradeIcon /> },
                // { text: "Division", path: "", icon: <GroupIcon /> },
                { text: "School List", path: "/school_list", icon: <GroupIcon /> }, 
                { text: "Teachers", path: "/teachlist", icon: <PersonIcon /> },
                // { text: "Resource Person", path: "", icon: <SupervisorAccountIcon /> },
                { text: "Students", path: "/studlist", icon: <SchoolIcon /> },
                {
                  text: "Parents",
                  path: "/parlist",
                  icon: <FamilyRestroomIcon />,
                },
              ],
            },
            {
              text: "Academics",
              icon: <SchoolIcon />,
              subItems: [
                // { text: "School", path: "/school_list", icon: <BusinessIcon /> },
                {
                  text: "Department",
                  path: "/department_list",
                  icon: <ApartmentIcon />,
                },
                {
                  text: "Courses",
                  path: "/courses_list",
                  icon: <MenuBookIcon />,
                },
                {
                  text: "Chapters",
                  path: "/chapters",
                  icon: <GroupWorkIcon />,
                },
                // { text: "Chapters", path: "/chapter_table", icon: <GroupWorkIcon /> },
                { text: "Units", path: "/unitlist", icon: <SlideshowIcon /> },
              
                {
                  text: "Create Units",
                  path: "/uploadunit",
                  icon: <SlideshowIcon />,
                },
             
                {
                  text: "Assessment",
                  path: "/assessment_list",
                  icon: <SlideshowIcon />,
                },
                { text: "Lab Names", path: "/labnames", icon: <GradeIcon /> },
              ],
            },
            {
              text: "Company",
              icon: <ApartmentIcon />,
              subItems: [
                {
                  text: "Partner",
                  path: "/partner_list",
                  icon: <GroupWorkIcon />,
                },
                {
                  text: "Products",
                  path: "/product_list",
                  icon: <ShoppingCartIcon />,
                },
                { text: "Event", path: "/event_list", icon: <EventIcon /> },
                {
                  text: "NewsLetter",
                  path: "/newsletter_list",
                  icon: <MailIcon />,
                },
                {
                  text: "Slider",
                  path: "/slider_list",
                  icon: <SlideshowIcon />,
                },
                {
                  text: "Career Planner",
                  path: "/career_planner_list",
                  icon: <SlideshowIcon />,
                },
                {
                  text: "Company News",
                  path: "/company_news_list",
                  icon: <EventIcon />,
                },
              ],
            },
            { text: "Assign Courses to Partner", route: "/assign_courses_to_partner_list", icon: <SlideshowIcon /> },
          ];
        // ******************************partner**************************
        case "partner":
          return [
            { text: "Dashboard", route: "/partner", icon: <DashboardIcon /> },
            {
              text: "School",
              icon: <BusinessIcon />,
              subItems: [
                // { text: "Grade", path: "", icon: <GradeIcon /> },
                // { text: "Division", path: "", icon: <GroupIcon /> },
                {
                  text: "Teachers",
                  path: "partner/teachlist",
                  icon: <PersonIcon />,
                },
                // { text: "Resource Person", path: "", icon: <SupervisorAccountIcon /> },
                {
                  text: "Students",
                  path: "partner/studlist",
                  icon: <SchoolIcon />,
                },
                {
                  text: "Parents",
                  path: "partner/parlist",
                  icon: <FamilyRestroomIcon />,
                },
              ],
            },
            {
              text: "Academics",
              icon: <SchoolIcon />,
              subItems: [
                {
                  text: "School",
                  path: "partner/school_list",
                  icon: <BusinessIcon />,
                },
                {
                  text: "Assign Courses to School",
                  path: "partner/assign_courses_list",
                  icon: <SlideshowIcon />,
                },
                {
                  text: "Department",
                  path: "partner/department_list",
                  icon: <ApartmentIcon />,
                },
                {
                  text: "Courses",
                  path: "partner/courses_list",
                  icon: <MenuBookIcon />,
                },
                // { text: "Chapters", path: "/chapter_table", icon: <GroupWorkIcon /> },
                {
                  text: "Units",
                  path: "partner/unitlist",
                  icon: <SlideshowIcon />,
                },
                // {
                //   text: "Create Units",
                //   path: "partner/uploadunit",
                //   icon: <SlideshowIcon />,
                // },
                {
                  text: "Chapters",
                  path: "/partner/chapters",
                  icon: <GroupWorkIcon />,
                },
                // {
                //   text: "Assessment",
                //   path: "/partner/assessment_list",
                //   icon: <SchoolIcon />,
                // },
              ],
            },
            {
              text: "Products",
              route: "partner/product_list",
              icon: <ShoppingCartIcon />,
            },
          ];

        default:
          return [
            { text: "Dashboard", route: "/dashboard", icon: <DashboardIcon /> },
          ];
      }
    };

    setMenuItems(getMenuItemsByRole(role));
  }, [role]);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < collapseScreenWidth) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMenuClick = (menu) => {
    if (collapsed) {
      setCollapsed(false);
    }
    setOpenMenus((prev) => ({
      [menu]: !prev[menu],
    }));
  };

  const handleSubMenuClick = (mainMenu, subMenu) => {
    setOpenSubMenus((prev) => ({
      ...prev,
      [mainMenu]: {
        ...prev[mainMenu],
        [subMenu]: !prev[mainMenu]?.[subMenu],
        ...Object.keys(prev[mainMenu] || {}).reduce((acc, key) => {
          if (key !== subMenu) {
            acc[key] = false; // Close all other submenus
          }
          return acc;
        }, {}),
      },
    }));
  };

  const renderSubItems = (subItems = [], mainMenu, level = 0) => {
    return subItems.map((subItem, index) =>
      subItem.subItems ? (
        <React.Fragment key={index}>
          <ListItem
            button
            onClick={() => handleSubMenuClick(mainMenu, subItem.text)}
          >
            <ListItemIcon sx={{ color: "black" }}>{subItem.icon}</ListItemIcon>
            <ListItemText primary={subItem.text} />
            <ExpandMoreIcon />
          </ListItem>
          <Collapse
            in={openSubMenus[mainMenu]?.[subItem.text]}
            timeout="auto"
            unmountOnExit
          >
            <List sx={{ pl: level * 4 }}>
              {renderSubItems(subItem.subItems, mainMenu, level + 1)}
            </List>
          </Collapse>
        </React.Fragment>
      ) : (
        <ListItem
          button
          key={index}
          sx={{ pl: level * 4 }}
          component={Link}
          to={subItem.path}
        >
          <ListItemIcon sx={{ color: "black" }}>{subItem.icon}</ListItemIcon>
          <ListItemText primary={subItem.text} />
        </ListItem>
      )
    );
  };

  const handleLogout = () => {
    removeToken();
    navigate("/");
  };

  return (
    <div style={{ display: "flex" }}>
      {(role === "super_admin" || role === "partner") && (
        <AppBar
          position="fixed"
          style={{
            backgroundColor: "#fff",
            color: "#0000066",
            boxShadow: "none",
            width: `calc(100% - ${collapsed ? collapsedWidth : drawerWidth}px)`,
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="#000"
              aria-label="menu"
              onClick={handleToggle}
              sx={{ fontSize: "13px" }}
            >
              <MenuIcon sx={{ color: "black" }} />
            </IconButton>

            <Box
              className="top-bar-container"
              sx={{
                flexGrow: 1,
                overflowX: "auto",
                whiteSpace: "nowrap",
                display: "block",
                textAlign: "center",
              }}
            >
              {buttonLabels.map((text, index) => (
                <Button
                  key={text}
                  component={Link}
                  to={buttonPaths[index]}
                  // color="inherit"
                  sx={{
                    minWidth: "120px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    backgroundColor: "#fff",
                    fontSize: "13px",

                    marginRight: "10px",
                    borderRadius: "6px",
                    padding: "5px 10px",
                    color: "#00000066 !important",
                  }}
                >
                  <PlayArrowIcon
                    sx={{ marginRight: "3px", fontSize: "18px" }}
                  />
                  {text}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
      )}
      <Drawer
        variant="permanent"
        sx={{
          width: collapsed ? collapsedWidth : drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: collapsed ? collapsedWidth : drawerWidth,
            backgroundColor: "#f1f0f0",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        {!collapsed && (
          <Box sx={{ textAlign: "center", padding: 1 }}>
            <img src={logoFull} alt="Logo" style={{ width: "93%" }} />
          </Box>
        )}
        {collapsed && (
          <Box sx={{ textAlign: "center", padding: 0 }}>
            <img src={logoIcon} alt="Logo" style={{ width: "100%" }} />
          </Box>
        )}

        <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
          <List>
            {menuItems.map(({ text, route, icon, subItems }) => (
              <React.Fragment key={text}>
                {subItems ? (
                  <>
                    <ListItem button onClick={() => handleMenuClick(text)}>
                      <ListItemIcon
                        sx={{ color: "black", fontSize: "18px !important" }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={text}
                        sx={{
                          opacity: collapsed ? 0 : 1,
                          transition: "opacity 0.3s",
                          fontSize: "13px",
                        }}
                      />

                      <ExpandMoreIcon sx={{ fontSize: "18px !important" }} />
                    </ListItem>
                    <Collapse
                      in={!collapsed && openMenus[text]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List sx={{ pl: collapsed ? 0 : 4 }}>
                        {renderSubItems(subItems, 1, text)}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  <ListItem button component={Link} to={route}>
                    <ListItemIcon
                      sx={{ color: "black", fontSize: "18px !important" }}
                    >
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ fontSize: "13px" }} />
                  </ListItem>
                )}
              </React.Fragment>
            ))}
          </List>
        </Box>

        <Box>
          <List>
            <ListItem button onClick={handleLogout}>
              <ListItemIcon sx={{ color: "black" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                sx={{
                  opacity: collapsed ? 0 : 1,
                  transition: "opacity 0.3s",
                  fontSize: "13px",
                }}
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          mt: 8,
          width: "75%",
        }}
      >
        <Outlet />
      </Box>
    </div>
  );
};

export default Layout;
