import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate, useLocation } from 'react-router-dom';

const AssessmentList = () => {
  const [assessments, setAssessments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [units, setUnits] = useState([]); 
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [assessmentToDelete, setAssessmentToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState('success');
  const [isDeleting, setIsDeleting] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

   // Fetch units function
   const fetchUnits = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/list/allunit`);
      setUnits(response.data.data || []); // Assuming the API returns an array of unit details
    } catch (err) {
      console.error(err);
      setError('Error fetching units');
    }
  };

  const fetchAssessments = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/assessment`, {
        params: { searchTerm, ...location.state },
      });
      setAssessments(response.data.data || []);
      setTotalItems(response.data.pagination.totalItems); // Adjust based on API response

    } catch (err) {
      console.error(err);
      setError('Error fetching assessments');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAssessments();
    fetchUnits();
  }, [searchTerm, location.state]);

  const handleAddAssessment = () => navigate('/assessment/add');
  const handleEditAssessment = (id) => navigate(`/partner/assessment/edit/${id}`);
  const handleViewQuestions = (id) => navigate(`/partner/questions/assessment/${id}`);

  const handleDeleteConfirmation = (assessment) => {
    setAssessmentToDelete(assessment);
    setOpenDialog(true);
  };

  const handleDeleteAssessment = async () => {
    setIsDeleting(true);
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/assessment/${assessmentToDelete._id}`);
      setOpenDialog(false);
      fetchAssessments();
      setSnackbarMessage("Assessment deleted successfully");
      setSnackbarType("success");
      setSnackbarOpen(true);
    } catch {
      setSnackbarMessage("Error deleting assessment");
      setSnackbarType("error");
      setSnackbarOpen(true);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  const getUnitName = (unitId) => {
    const unit = units.find(u => u._id === unitId); // Match unit by ID
    return unit ? unit.unit_name : 'Unknown Unit'; // Return unit name or fallback
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Unit Assessment List
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ width: '300px' }}
        />
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddAssessment}>
        Create
        </Button>
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial No.</TableCell>
                  <TableCell>Assessment Title</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Total Score</TableCell>
                  <TableCell>Expire Time (ms)</TableCell>    
                  <TableCell>Actions</TableCell>
                  <TableCell>View Questions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {assessments.length > 0 ? (
                  assessments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((assessment, index) => (
                    <TableRow key={assessment._id}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell>{assessment.assessment_title}</TableCell>
                      <TableCell>{getUnitName(assessment.unit)}</TableCell>
                      <TableCell>{assessment.total_score}</TableCell>
                      <TableCell>{assessment.expire_time}</TableCell>
                      <TableCell>
                        <IconButton color="primary" onClick={() => handleEditAssessment(assessment._id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => handleDeleteConfirmation(assessment)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton color="default" onClick={() => handleViewQuestions(assessment._id)}>
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">No assessments found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
         {totalItems >= 10 && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
              showFirstButton
              showLastButton
            />
          )}
        </>
      )}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the assessment "{assessmentToDelete?.assessment_title}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">Cancel</Button>
          <Button onClick={handleDeleteAssessment} color="error" disabled={isDeleting}>
            {isDeleting ? <CircularProgress size={24} color="inherit" /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarType}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AssessmentList;
