import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, FormControl, InputLabel, MenuItem, Select, Typography, Container } from '@mui/material';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { getToken } from '../../utils/AuthUtils';

const EditSchoolMain = () => {
  const { id } = useParams(); // Get ID from URL parameters
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    School_id: '',
    school_category: '',
    school_code: '',
    school_sub_category: '',
    countryId: '',
    stateId: '',
    districtId: '',
    townId: '',
    place: '',
    school_official_id: '',
    school_name: '',
    school_address: '',
    school_pin_code: '',
    school_website: '',
    school_email: '',
    principal_name: '',
    principal_contact_no: '',
    principal_email: '',
    principal_photo: '',
    admin_name: '',
    admin_contact_no: '',
    admin_email: '',
    total_number_of_students: '',
   
    school_logo: '',
    social_media_linkedin: '',
    social_media_instagram: '',
    social_media_facebook: '',
    status: 1,
  });
  const [error, setError] = useState('');
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [towns, setTowns] = useState([]);
  const [principalPhoto, setPrincipalPhoto] = useState(null);
  const [schoolLogo, setSchoolLogo] = useState(null);
  const [principalPhotoPreview, setPrincipalPhotoPreview] = useState('');
  const [schoolLogoPreview, setSchoolLogoPreview] = useState([]);
  const [schoolCategories, setSchoolCategories] = useState([]); // For school categories
  const [schoolSubCategories, setSchoolSubCategories] = useState([]); // For school subcategories

  useEffect(() => {
    fetchCountries();
    fetchSchoolCategories();
    // Fetch school categories on mount
    if (id) fetchSchoolData(id);
  }, [id]);

  useEffect(() => {
    if (formData.school_sub_category){
      fetchSchoolSubCategories(formData.school_sub_category);
    }
  }, [formData.school_sub_category]);

  useEffect(() => {
    if (formData.countryId) fetchStates(formData.countryId);
    else {
      setStates([]);
      setFormData(prev => ({ ...prev, stateId: '', districtId: '', townId: '' }));
    }
  }, [formData.countryId]);

  useEffect(() => {
    if (formData.stateId) fetchDistricts(formData.stateId);
    else {
      setDistricts([]);
      setFormData(prev => ({ ...prev, districtId: '', townId: '' }));
    }
  }, [formData.stateId]);

  useEffect(() => {
    if (formData.districtId) fetchTowns(formData.districtId);
    else {
      setTowns([]);
      setFormData(prev => ({ ...prev, townId: '' }));
    }
  }, [formData.districtId]);

  useEffect(() => {
    if (formData.principal_photo) {
      setPrincipalPhotoPreview(`${process.env.REACT_APP_BACKEND}/uploads/${formData.principal_photo}`);
    }
    if (formData.school_logo) {
      setSchoolLogoPreview(`${process.env.REACT_APP_BACKEND}/uploads/${formData.school_logo}`);
    }
  }, [formData.principal_photo, formData.school_logo]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country`);
      setCountries(response.data.data);
    } catch (err) {
      console.error('Failed to fetch countries:', err.response?.data?.message || err.message);
    }
  };

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state/by-country`, {
        params: { countryId }
      });
      setStates(response.data.data);
    } catch (err) {
      console.error('Failed to fetch states:', err.response?.data?.message || err.message);
    }
  };

  const fetchDistricts = async (stateId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/district/by-state`, {
        params: { stateId }
      });
      setDistricts(response.data.data);
    } catch (err) {
      console.error('Failed to fetch districts:', err.response?.data?.message || err.message);
    }
  };

  const fetchTowns = async (districtId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/town/by-district`, {
        params: { districtId }
      });
      setTowns(response.data.data);
    } catch (err) {
      console.error('Failed to fetch towns:', err.response?.data?.message || err.message);
    }
  };

  const fetchSchoolData = async (id) => {
    try {
      const token = getToken();
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setFormData(response.data.data);
      }
    } catch (err) {
      console.error('Failed to fetch school data:', err.response?.data?.message || err.message);
    }
  };

  const fetchSchoolCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school_category`);
      setSchoolCategories(response.data.data);
    } catch (err) {
      console.error('Failed to fetch school categories:', err.response?.data?.message || err.message);
    }
  };

  const fetchSchoolSubCategories = async (categoryId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sub_school_category`, 

        {
        params: { categoryId }
      });
      console.log("subcategory:",response.data.data)
      setSchoolSubCategories(response.data.data);
    } catch (err) {
      console.error('Failed to fetch school subcategories:', err.response?.data?.message || err.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => {
      const updatedFormData = { ...prev, [name]: value };

      // Fetch sub-categories when school category changes
      if (name === 'school_category') {
        fetchSchoolSubCategories(value);
      }

      return updatedFormData;
    });
  };


  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      if (name === 'principal_photo') {
        setPrincipalPhoto(file);
        setPrincipalPhotoPreview(URL.createObjectURL(file));
      } else if (name === 'school_logo') {
        setSchoolLogo(file);
        setSchoolLogoPreview(URL.createObjectURL(file));
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const token = getToken();
      if (!token) {
        alert("Please Login");
        return;
      }

      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      if (principalPhoto) {
        formDataToSend.append('principal_photo', principalPhoto);
      }
      if (schoolLogo) {
        formDataToSend.append('school_logo', schoolLogo);
      }

      await axios.put(`${process.env.REACT_APP_BACKEND}/api/school/${id}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        }
      });
      navigate('/school_list'); // Redirect to the school list page after updating
    } catch (err) {
      setError('Failed to update school');
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" align="center" gutterBottom>
        Edit School
      </Typography>
      <Box display="flex" flexDirection="column" gap={3}>
        <Grid container spacing={2}>
          {/* General Information */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              General Information
            </Typography>
            <Grid container spacing={2}>
              {/* School Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="School Name"
                  name="school_name"
                  value={formData.school_name}
                  onChange={handleChange}
                />
              </Grid>
              {/* School Code */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="School Code"
                  name="school_code"
                  value={formData.school_code}
                  onChange={handleChange}
                />
              </Grid>
              {/* School Category */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>School Category</InputLabel>
                  <Select
                    name="school_category"
                    value={formData.school_category}
                    onChange={handleChange}
                  >
                    {schoolCategories.map((category) => (
                      <MenuItem key={category._id} value={category._id}>
                        {category.school_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* School Sub-Category */}
              <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal" disabled={!formData.school_category}>
            <InputLabel>School Sub-Category</InputLabel>
            <Select
              name="school_sub_category"
              value={formData.school_sub_category}
              onChange={handleChange}
            >
              {schoolSubCategories.map(subCat => (
                <MenuItem key={subCat._id} value={subCat._id}>{subCat.SubCategory_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
              {/* School Address */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="School Address"
                  name="school_address"
                  value={formData.school_address}
                  onChange={handleChange}
                />
              </Grid>
              {/* School Pin Code */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="School Pin Code"
                  name="school_pin_code"
                  value={formData.school_pin_code}
                  onChange={handleChange}
                />
              </Grid>
              {/* School Website */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="School Website"
                  name="school_website"
                  value={formData.school_website}
                  onChange={handleChange}
                />
              </Grid>
              {/* School Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="School Email"
                  name="school_email"
                  value={formData.school_email}
                  onChange={handleChange}
                />
              </Grid>
              {/* Principal Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Principal Name"
                  name="principal_name"
                  value={formData.principal_name}
                  onChange={handleChange}
                />
              </Grid>
              {/* Principal Contact No */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Principal Contact No"
                  name="principal_contact_no"
                  value={formData.principal_contact_no}
                  onChange={handleChange}
                />
              </Grid>
              {/* Principal Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Principal Email"
                  name="principal_email"
                  value={formData.principal_email}
                  onChange={handleChange}
                />
              </Grid>
              {/* Principal Photo */}
              <Grid item xs={12} md={6}>
                <Typography variant="body1" gutterBottom>
                  Principal Photo
                </Typography>
                <Box>
                {formData.principal_photo ? (
                      <img
                        src={formData.principal_photo}
                        alt="Principal Photo"
                        style={{ width: 100, height: 100,marginRight:10, objectFit: 'cover' }}
                      />
                    ) : (
                      'No Photo'
                    )}
                  <Button
                    variant="contained"
                    component="label"
                  >
                    {principalPhoto ? 'Change Principal Photo' : 'Upload Principal Photo'}
                    <input
                      type="file"
                      name="principal_photo"
                      accept="image/*"
                      onChange={handleFileChange}
                      hidden
                    />
                  </Button>
                </Box>
              </Grid>
              {/* Admin Name */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Admin Name"
                  name="admin_name"
                  value={formData.admin_name}
                  onChange={handleChange}
                />
              </Grid>
              {/* Admin Contact No */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Admin Contact No"
                  name="admin_contact_no"
                  value={formData.admin_contact_no}
                  onChange={handleChange}
                />
              </Grid>
              {/* Admin Email */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Admin Email"
                  name="admin_email"
                  value={formData.admin_email}
                  onChange={handleChange}
                />
              </Grid>
              {/* School Logo */}
              <Grid item xs={12} md={6}>
                <Typography variant="body1" gutterBottom>
                  School Logo
                </Typography>
                <Box>
                {formData.school_logo ? (
                      <img
                        src={formData.school_logo}
                        alt="School Logo"
                        style={{ width: 100, height: 100,marginRight:10, objectFit: 'cover' }}
                      />
                    ) : (
                      'No Logo'
                    )}
                  <Button
                    variant="contained"
                    component="label"
                  >
                    {schoolLogo ? 'Change School Logo' : 'Upload School Logo'}
                    <input
                      type="file"
                      name="school_logo"
                      accept="image/*"
                      onChange={handleFileChange}
                      hidden
                    />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Address Information */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Address Information
            </Typography>
            <Grid container spacing={2}>
              {/* Country */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    name="countryId"
                    value={formData.countryId}
                    onChange={handleChange}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country._id} value={country._id}>
                        {country.country_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* State */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>State</InputLabel>
                  <Select
                    name="stateId"
                    value={formData.stateId}
                    onChange={handleChange}
                    disabled={!formData.countryId}
                  >
                    {states.map((state) => (
                      <MenuItem key={state._id} value={state._id}>
                        {state.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* District */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>District</InputLabel>
                  <Select
                    name="districtId"
                    value={formData.districtId}
                    onChange={handleChange}
                    disabled={!formData.stateId}
                  >
                    {districts.map((district) => (
                      <MenuItem key={district._id} value={district._id}>
                        {district.district_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Town */}
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Town</InputLabel>
                  <Select
                    name="townId"
                    value={formData.townId}
                    onChange={handleChange}
                    disabled={!formData.districtId}
                  >
                    {towns.map((town) => (
                      <MenuItem key={town._id} value={town._id}>
                        {town.Town_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* Place */}
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Place"
                  name="place"
                  value={formData.place}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Social Media Links */}
        <Typography variant="h6" gutterBottom>
          Social Media Information
        </Typography>
        <Grid container spacing={2}>
          {/* LinkedIn */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="LinkedIn"
              name="social_media_linkedin"
              value={formData.social_media_linkedin}
              onChange={handleChange}
            />
          </Grid>
          {/* Instagram */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Instagram"
              name="social_media_instagram"
              value={formData.social_media_instagram}
              onChange={handleChange}
            />
          </Grid>
          {/* Facebook */}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Facebook"
              name="social_media_facebook"
              value={formData.social_media_facebook}
              onChange={handleChange}
            />
          </Grid>
        </Grid>

        {/* Submit Button */}
        <Box textAlign="center" mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default EditSchoolMain;
