import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import EditSchoolSub from './EditSchoolSub';
import AddSchoolSub from './AddSchoolSub';

function SchoolSubList() {
  const [subjects, setSubjects] = useState([]);
  const [schoolCategories, setSchoolCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedSubjectId, setSelectedSubjectId] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);

  // Fetch school categories and subjects
  const fetchSchoolCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school_category`);
      setSchoolCategories(response.data.data);
    } catch (err) {
      console.error('Error fetching school categories', err);
    }
  };

  const fetchSubjects = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sub_school_category`, {
        params: { page: page + 1, limit: rowsPerPage }
      });
      setSubjects(response.data.data);
      setTotalItems(response.data.pagination.totalItems);
    } catch (err) {
      setError('Error fetching subjects');
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchSchoolCategories();
    fetchSubjects();
  }, [page, rowsPerPage]);

  // Handle search term change
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  // Handle pagination and rows per page changes
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Snackbar handlers
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // Open/close delete dialog
  const handleOpenDeleteDialog = (subjectId) => {
    setSelectedSubjectId(subjectId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedSubjectId(null);
  };

  // Delete subject
  const handleDeleteSubject = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/sub_school_category/${id}`);
      setSnackbarMessage('Subject deleted successfully');
      setOpenSnackbar(true);
      fetchSubjects();
    } catch (err) {
      setSnackbarMessage('Failed to delete subject');
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  // Open/close edit modal
  const handleOpenEditModal = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sub_school_category/${id}`);
      setSelectedSubject(response.data.data);
      setSelectedSubjectId(id);
      setOpenEditModal(true);
    } catch (err) {
      setError('Error fetching subject details');
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedSubjectId(null);
    setSelectedSubject(null);
  };

  // Open/close add modal
  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  // Add subject
  const handleAddSubject = () => {
    handleCloseAddModal();
    fetchSubjects();
  };

  // Toggle subject status
  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateSubjectStatus(id, newStatus);
  };

  // Update subject status
  const updateSubjectStatus = async (id, status) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/sub_school_category/${id}/status_subschool_category`, {
        status,
      });
      setSnackbarMessage('Subject status updated successfully');
      setOpenSnackbar(true);
      fetchSubjects();
    } catch (err) {
      setError('Failed to update subject status');
      setOpenSnackbar(true);
    }
  };

  // Filter subjects by search term
  const filteredSubjects = subjects.filter(subject =>
    (subject.SubCategory_name || '').toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Map category ID to name
  const getCategoryName = (id) => {
    const category = schoolCategories.find(cat => cat._id === id);
    return category ? category.school_name : 'Unknown';
  };

  return (
    <div>
      <h1>School Sub Category Listing</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
        >
          Add School Sub
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>Sub Category Name</TableCell>
              <TableCell>Category Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSubjects.length > 0 ? (
              filteredSubjects.map((subject, index) => (
                <TableRow key={subject._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{subject.SubCategory_name}</TableCell>
                  <TableCell>{getCategoryName(subject.School_category_id)}</TableCell>
                  <TableCell>
                    <Switch
                      checked={subject.status}
                      onChange={() => handleStatusToggle(subject._id, subject.status)}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenEditModal(subject._id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleOpenDeleteDialog(subject._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No Subcategory found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 5 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <AddSchoolSub open={openAddModal} onClose={handleCloseAddModal} onAdd={handleAddSubject} />
      {selectedSubjectId && (
        <EditSchoolSub
          open={openEditModal}
          onClose={handleCloseEditModal}
          subjectId={selectedSubjectId}
          subject={selectedSubject}
          onUpdate={() => {
            handleCloseEditModal();
            fetchSubjects();
          }}
        />
      )}
      {selectedSubjectId && (
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>Are you sure you want to delete this category?</DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteSubject(selectedSubjectId)}
              color="secondary"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={error ? 'error' : 'success'}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SchoolSubList;
