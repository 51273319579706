import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../../utils/AuthUtils";

const EditChapter = () => {
  const { id } = useParams(); // Get chapter ID from URL
  const [chapterName, setChapterName] = useState("");
  const [chapterDescription, setChapterDescription] = useState("");
  const [courseCategoryId, setCourseCategoryId] = useState("");
  const [courseSubCategoryId, setCourseSubCategoryId] = useState("");
  const [courseId, setCourseId] = useState("");
  const [noOfUnits, setNoOfUnits] = useState("");
  const [status, setStatus] = useState(1);
  const [courseCategories, setCourseCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [gradeId, setGradeId] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate(); // Hook for navigation

  // Fetch course categories on component mount
  useEffect(() => {
    const fetchCourseCategories = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category`);
        const data = response.data.data || [];
        setCourseCategories(data.filter(category => category.status === 1));
      } catch (err) {
        setError("Failed to fetch course categories.");
      } finally {
        setLoading(false);
      }
    };

    fetchCourseCategories();
  }, []);

  // Fetch chapter details when component mounts
  useEffect(() => {
    const fetchChapterDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chapters/${id}`);
        const chapter = response.data.data;
        setChapterName(chapter.chapter_name);
        setChapterDescription(chapter.chapter_description);
        setCourseCategoryId(chapter.course_category_id);
        setCourseSubCategoryId(chapter.course_sub_category_id);
        setCourseId(chapter.course_id);
        setNoOfUnits(chapter.no_of_units);
        setStatus(chapter.status);
        setGradeId(chapter.grade_id || "");
      } catch (err) {
        setError("Failed to fetch chapter details.");
      } finally {
        setLoading(false);
      }
    };

    fetchChapterDetails();
  }, [id]);

  // Fetch sub-categories based on the selected course category
  useEffect(() => {
    if (courseCategoryId) {
      const fetchCourseSubCategories = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses_category_sub`, {
            params: { category_id: courseCategoryId }
          });
          const data = response.data.data || [];
          setSubCategories(data.filter(subCategory => subCategory.status === 1));
        } catch (err) {
          setError("Failed to fetch course sub-categories.");
        } finally {
          setLoading(false);
        }
      };

      fetchCourseSubCategories();
    } else {
      setSubCategories([]);
      setFilteredSubCategories([]);
    }
  }, [courseCategoryId]);

  // Filter sub-categories based on the selected course category
  useEffect(() => {
    if (courseCategoryId) {
      const filtered = subCategories.filter(subcategory =>
        subcategory.course_category_id === courseCategoryId
      );
      setFilteredSubCategories(filtered);
    }
  }, [subCategories, courseCategoryId]);

  // Fetch courses based on the selected course sub-category
  useEffect(() => {
    if (courseSubCategoryId) {
      const fetchCourses = async () => {
        setLoading(true);
        try {
          const token = getToken();
          if (token) {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses`, {
            params: { subcategory_id: courseSubCategoryId },
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          const data = response.data.data || [];
          setCourses(data.filter(course => course.status === 1));
        } }catch (err) {
          setError("Failed to fetch courses.");
        } finally {
          setLoading(false);
        }
      };

      fetchCourses();
    } else {
      setCourses([]);
      setFilteredCourses([]);
    }
  }, [courseSubCategoryId]);

  // Filter courses based on the selected course sub-category
  useEffect(() => {
    if (courseSubCategoryId) {
      const filtered = courses.filter(course =>
        course.course_subcategory_id === courseSubCategoryId
      );
      setFilteredCourses(filtered);
    }
  }, [courses, courseSubCategoryId]);

  // Fetch grade when course is selected
  useEffect(() => {
    if (courseId) {
      const fetchCourseGrade = async () => {
        setLoading(true);
        try {
          const token = getToken();
          if (token) {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses/${courseId}`,{
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          const data = response.data.data || {};
          setGradeId(data.grade_id || "");
        }} catch (err) {
          setError("Failed to fetch grade.");
        } finally {
          setLoading(false);
        }
      };

      fetchCourseGrade();
    } else {
      setGradeId("");
    }
  }, [courseId]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!chapterName || !chapterDescription || !courseCategoryId || !courseSubCategoryId || !courseId || !noOfUnits) {
      setError("Please fill out all required fields.");
      return;
    }

    const chapterData = {
      chapter_name: chapterName,
      chapter_description: chapterDescription,
      course_category_id: courseCategoryId,
      course_sub_category_id: courseSubCategoryId,
      course_id: courseId,
      grade_id: gradeId, // Include the grade ID in the submission
      no_of_units: Number(noOfUnits),
      status,
    };

    setLoading(true);

    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/chapters/${id}`, chapterData);
      if (response.data) {
        setSuccess("Chapter updated successfully!");
        setError(null);
        navigate("/chapters"); // Navigate to the chapter table page
      } else {
        setError(response.data.message || "Failed to update chapter.");
      }
    } catch (err) {
      setError("Failed to update chapter.");
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSuccess(null);
    setError(null);
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        padding: "20px",
        borderRadius: "8px",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Edit Chapter
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Chapter Name"
                value={chapterName}
                onChange={(e) => setChapterName(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Chapter Description"
                value={chapterDescription}
                onChange={(e) => setChapterDescription(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                label="Course Category"
                value={courseCategoryId}
                onChange={(e) => setCourseCategoryId(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                required
              >
                <MenuItem value="">Select Category</MenuItem>
                {courseCategories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.courses_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                label="Course Sub-Category"
                value={courseSubCategoryId}
                onChange={(e) => setCourseSubCategoryId(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                required
              >
                <MenuItem value="">Select Sub-Category</MenuItem>
                {filteredSubCategories.map((subCategory) => (
                  <MenuItem key={subCategory._id} value={subCategory._id}>
                    {subCategory.courses_sub_category_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                label="Course"
                value={courseId}
                onChange={(e) => setCourseId(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                required
              >
                <MenuItem value="">Select Course</MenuItem>
                {filteredCourses.map((course) => (
                  <MenuItem key={course._id} value={course._id}>
                    {course.courses_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="number"
                label="Number of Units"
                value={noOfUnits}
                onChange={(e) => setNoOfUnits(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
              >
                Update Chapter
              </Button>
            </Grid>
          </Grid>
        </form>
      )}

      <Snackbar open={Boolean(success)} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          {success}
        </Alert>
      </Snackbar>

      <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EditChapter;
