import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import EditTown from './EditTown';
import AddTown from './AddTown';

function TownList() {
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
    fetchCountries();
    fetchStates();
    fetchDistricts();
  }, [page, rowsPerPage]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/town`, {
        params: {
          page: page + 1,
          limit: rowsPerPage
        }
      });
      setCategories(response.data.data);
      setTotalItems(response.data.pagination.totalItems);
    } catch (err) {
      setError("Error fetching categories");
      setOpenSnackbar(true);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country`);
      setCountries(response.data.data);
    } catch (err) {
      console.error('Failed to fetch countries');
    }
  };

  const fetchStates = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state`);
      setStates(response.data.data);
    } catch (err) {
      console.error('Failed to fetch states');
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/district`);
      setDistricts(response.data.data);
    } catch (err) {
      console.error('Failed to fetch districts');
    }
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleAddPartner = () => {
    handleCloseAddModal();
    fetchCategories(); // Refresh the list after adding
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (categoryId) => {
    setSelectedCategoryId(categoryId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedCategoryId(null);
  };

  const handleDeleteCategory = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/town/${id}`);
      setSnackbarMessage('Category deleted successfully');
      setOpenSnackbar(true);
      fetchCategories(); // Refresh the list after deleting
    } catch (err) {
      setSnackbarMessage('Failed to delete category');
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenEditModal = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/town/${id}`);
      setSelectedCategory(response.data.data);
      setSelectedCategoryId(id);
      setOpenEditModal(true);
    } catch (err) {
      setError('Error fetching category details');
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedCategoryId(null);
    setSelectedCategory(null);
  };

  const getCountryName = (id) => {
    const country = countries.find((c) => c._id === id);
    return country ? country.country_name : 'Unknown';
  };

  const getStateName = (id) => {
    const state = states.find((s) => s._id === id);
    return state ? state.state_name : 'Unknown';
  };

  const getDistrictName = (id) => {
    const district = districts.find((d) => d._id === id);
    return district ? district.district_name : 'Unknown';
  };

  return (
    <div>
      <h1>Town Listing</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
        >
          Add Town
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>State Name</TableCell>
              <TableCell>District Name</TableCell>
              <TableCell>Town Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.length > 0 ? (
              categories.map((category, index) => (
                <TableRow key={category._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{getCountryName(category.country)}</TableCell>
                  <TableCell>{getStateName(category.state)}</TableCell>
                  <TableCell>{getDistrictName(category.district)}</TableCell>
                  <TableCell>{category.Town_name}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenEditModal(category._id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleOpenDeleteDialog(category._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No towns found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 5 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure want to delete?</DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteCategory(selectedCategoryId)} color="primary">
            Delete
          </Button>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {openEditModal && (
        <EditTown
          open={openEditModal}
          onClose={handleCloseEditModal}
          townId={selectedCategoryId}
          onUpdate={() => {
            fetchCategories();
            handleCloseEditModal();
          }}
        />
      )}
      {openAddModal && (
        <AddTown
          isOpen={openAddModal}
          onClose={handleCloseAddModal}
          onAdd={() => {
            fetchCategories();
            handleCloseAddModal();
          }}
        />
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={error ? 'error' : 'success'}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default TownList;