import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

const EditProducts = () => {
  const [product, setProduct] = useState({
    product_id: '',
    product_name: '',
    product_category_id: '',
    product_sub_category_name: '',
    isDeleted: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const { id } = useParams(); // Product ID from route parameters
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/product/category`);
        if (Array.isArray(response.data.data)) {
          setCategories(response.data.data);
        } else {
          console.error('Unexpected categories data format:', response.data);
          setCategories([]);
        }
      } catch (err) {
        console.error('Failed to fetch categories', err);
        setCategories([]);
      }
    };

    const fetchSubCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/product/category_sub`);
        if (Array.isArray(response.data.data)) {
          setSubCategories(response.data.data);
        } else {
          console.error('Unexpected subcategories data format:', response.data);
          setSubCategories([]);
        }
      } catch (err) {
        console.error('Failed to fetch subcategories', err);
        setSubCategories([]);
      }
    };

    const fetchProduct = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/product/${id}`);
        setProduct(response.data);
        setSelectedCategory(response.data.product_category_id);
      } catch (err) {
        setError('Failed to fetch product details');
        console.error('Error fetching product details:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
    fetchSubCategories();
    fetchProduct();
  }, [id]);

  useEffect(() => {
    if (selectedCategory) {
      const filtered = subCategories.filter(subcategory =>
        subcategory.product_id === selectedCategory
      );
      setFilteredSubCategories(filtered);
    } else {
      setFilteredSubCategories([]);
    }
  }, [selectedCategory, subCategories]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'product_category_id') {
      setSelectedCategory(value);
    }
    setProduct({
      ...product,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validateForm = () => {
    const { product_id, product_name, product_category_id, product_sub_category_name } = product;
    if (!product_id || !product_name || !product_category_id || !product_sub_category_name) {
      setError('All fields are required');
      return false;
    }
    setError(null);
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setSnackbarMessage(error);
      setSnackbarOpen(true);
      return;
    }
    setIsLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/product/${id}`, product);
      setSnackbarMessage('Product updated successfully');
      setSnackbarOpen(true);
      navigate('/product_list');
    } catch (err) {
      setError('Failed to update product');
      setSnackbarMessage('Failed to update product');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box p={3}>
      <h1>Edit Product</h1>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            label="Product ID"
            variant="outlined"
            fullWidth
            margin="normal"
            name="product_id"
            value={product.product_id}
            onChange={handleChange}
            disabled
          />
          <TextField
            label="Product Name"
            variant="outlined"
            fullWidth
            margin="normal"
            name="product_name"
            value={product.product_name}
            onChange={handleChange}
            error={!product.product_name && Boolean(error)}
            helperText={!product.product_name && error}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Product Category</InputLabel>
            <Select
              name="product_category_id"
              value={product.product_category_id}
              onChange={handleChange}
              label="Product Category"
              error={!product.product_category_id && Boolean(error)}
            >
              {categories.map(category => (
                <MenuItem key={category._id} value={category._id}>
                  {category.product_category_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Product Sub Category</InputLabel>
            <Select
              name="product_sub_category_name"
              value={product.product_sub_category_name}
              onChange={handleChange}
              label="Product Sub Category"
              error={!product.product_sub_category_name && Boolean(error)}
            >
              {filteredSubCategories.length > 0 ? (
                filteredSubCategories.map(subCategory => (
                  <MenuItem key={subCategory._id} value={subCategory._id}>
                    {subCategory.product_sub_category_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>No subcategories available</MenuItem>
              )}
            </Select>
          </FormControl>
          <Box mt={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : 'Update Product'}
            </Button>
          </Box>
        </form>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={error ? 'error' : 'success'}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EditProducts;
