import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AddEvent = () => {
  const [event, setEvent] = useState({
    event_title: '',
    event_description: '',
    event_image: '',
    event_coordinator: '',
    file_path: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent({ ...event, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/event`, event);
      navigate('/event_list'); // Redirect after successful creation
    } catch (err) {
      setError('Error adding event');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <IconButton onClick={() => navigate('/events')}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h4">Add Event</Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          label="Title"
          name="event_title"
          value={event.event_title}
          onChange={handleChange}
          fullWidth
          required
          sx={{ mb: 2 }}
        />
        <TextField
          label="Description"
          name="event_description"
          value={event.event_description}
          onChange={handleChange}
          fullWidth
          multiline
          rows={4}
          required
          sx={{ mb: 2 }}
        />
        <TextField
          label="Image URL"
          name="event_image"
          value={event.event_image}
          onChange={handleChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Coordinator"
          name="event_coordinator"
          value={event.event_coordinator}
          onChange={handleChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="File Path"
          name="file_path"
          value={event.file_path}
          onChange={handleChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button type="submit" variant="contained" color="primary">
            Add Event
          </Button>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </Box>
    </div>
  );
};

export default AddEvent;
