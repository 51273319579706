import React, { useState } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

function AddProduct({ open, onClose, onAdd }) {
  const [partnerName, setPartnerName] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handlePartnerChange = (e) => {
    setPartnerName(e.target.value);
  };

  const handlePartnerSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/product/category`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ product_category_name: partnerName }),
      });

      if (response.ok) {
        setSnackbarMessage('Product category added successfully');
        onAdd(); // Notify parent to refresh data
        onClose(); // Close the modal
      } else {
        const errorData = await response.json();
        setSnackbarMessage(`Failed to add Product: ${errorData.message || 'Unknown error'}`);
      }

      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage(`Error occurred: ${error.message}`);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Product Category</DialogTitle>
      <DialogContent>
        <form onSubmit={handlePartnerSubmit}>
          <TextField
            label="Product Category Name"
            value={partnerName}
            onChange={handlePartnerChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
          <Box display="flex" justifyContent="center" marginTop={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Add Product
              </Button>
            )}
          </Box>
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarMessage.includes('success') ? 'success' : 'error'}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddProduct;
