import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
 
const ContentList = () => {
  const [schoolCategories, setSchoolCategories] = useState([]);
  const [schoolSubcategories, setSchoolSubcategories] = useState([]);
  const [grades, setGrades] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [chapters, setChapters] = useState([]);
 
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
 
  const [formData, setFormData] = useState({
    schoolCategory: "",
    schoolSubcategory: "",
    grade: "",
    course: "",
    chapter: "",
  });
 
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
 
  const navigate = useNavigate();


  // Fetch school categories
  useEffect(() => {
    const fetchSchoolCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/school_category`);
        setSchoolCategories(response.data.data);
      } catch (error) {
        console.error("Error fetching school categories:", error);
      }
    };
    
    fetchSchoolCategories();
  }, []);

  // Fetch school subcategories
  useEffect(() => {
    const fetchSchoolSubcategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/sub_school_category`);
        setSchoolSubcategories(response.data.data);
      } catch (error) {
        console.error("Error fetching school subcategories:", error);
      }
    };
    
    fetchSchoolSubcategories();
  }, []);

  // Fetch grades
  useEffect(() => {
    const fetchGrades = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/grade`);
        setGrades(response.data.data);
      } catch (error) {
        console.error("Error fetching grades:", error);
      }
    };
    
    fetchGrades();
  }, []);

  // Fetch courses
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses`);
        setCourseList(response.data.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    
    fetchCourses();
  }, []);

  // Set loading state to false when all fetches are done
  useEffect(() => {
    const checkIfAllDataFetched = async () => {
      if (schoolCategories.length && schoolSubcategories.length && grades.length && courseList.length) {
        setIsLoading(false);
      }
    };

    checkIfAllDataFetched();
  }, [schoolCategories, schoolSubcategories, grades, courseList]);
 

 
  useEffect(() => {
    if (formData.schoolCategory) {
      const filtered = schoolSubcategories.filter(subcategory =>
        subcategory.School_category_id === formData.schoolCategory
      );
      setFilteredSubcategories(filtered);
    } else {
      setFilteredSubcategories([]);
    }
  }, [formData.schoolCategory, schoolSubcategories]);
 
  useEffect(() => {
    if (formData.course) {
      const fetchChapters = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/chapters`, {
            params: { courseId: formData.course },
          });
          setChapters(response.data.data);
        } catch (error) {
          console.error("Error fetching chapters:", error);
        }
      };
 
      fetchChapters();
    } else {
      setChapters([]);
    }
  }, [formData.course]);
 
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
 
  const handleSave = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      // Implement your save logic here
      // Example: await axios.post('/api/save', formData);
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setIsSaving(false);
    }
  };
 
  return (
    <Box padding={2}>
      <Typography variant="h4" gutterBottom>
        Add New Course Details
      </Typography>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSave}>
          <TextField
            label="School Category"
            variant="outlined"
            fullWidth
            margin="normal"
            name="schoolCategory"
            select
            value={formData.schoolCategory}
            onChange={handleInputChange}
            required
          >
            {schoolCategories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.school_name}
              </MenuItem>
            ))}
          </TextField>
 
          <TextField
            label="School Subcategory"
            variant="outlined"
            fullWidth
            margin="normal"
            name="schoolSubcategory"
            select
            value={formData.schoolSubcategory}
            onChange={handleInputChange}
            required
          >
            {filteredSubcategories.length > 0 ? (
              filteredSubcategories.map((subcategory) => (
                <MenuItem key={subcategory._id} value={subcategory._id}>
                  {subcategory.SubCategory_name}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                No Subcategories
              </MenuItem>
            )}
          </TextField>
 
          <TextField
            label="Grade"
            variant="outlined"
            fullWidth
            margin="normal"
            name="grade"
            select
            value={formData.grade}
            onChange={handleInputChange}
            required
          >
            {grades.map((grade) => (
              <MenuItem key={grade._id} value={grade._id}>
                {grade.gradename}
              </MenuItem>
            ))}
          </TextField>
 
          <TextField
            label="Course"
            variant="outlined"
            fullWidth
            margin="normal"
            name="course"
            select
            value={formData.course}
            onChange={handleInputChange}
            required
          >
            {courseList.map((course) => (
              <MenuItem key={course._id} value={course._id}>
                {course.courses_name}
              </MenuItem>
            ))}
          </TextField>
 
          <TextField
            label="Chapter"
            variant="outlined"
            fullWidth
            margin="normal"
            name="chapter"
            select
            value={formData.chapter}
            onChange={handleInputChange}
            required
          >
            {chapters.map((chapter) => (
              <MenuItem key={chapter._id} value={chapter._id}>
                {chapter.chapter_name}
              </MenuItem>
            ))}
          </TextField>
 
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSaving}
            sx={{ mt: 2 }}
          >
            {isSaving ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </Box>
      )}
    </Box>
  );
};
 
export default ContentList;