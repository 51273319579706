import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Paper,
    Button,
    Snackbar,
    Alert,
    TextField,
    Box,
    TablePagination,
    Switch,
    Grid,
    Link,
} from "@mui/material";
// import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
// import { useNavigate } from "react-router-dom";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const RolesListPage = () => {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        fetchRoles();
    }, [page, rowsPerPage, searchTerm]);

    const fetchRoles = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND}/api/roles?page=${page + 1}&limit=${rowsPerPage}&search=${searchTerm}`
            );
            const data = await response.json();
            if (data?.data) {
                setRoles(data.data);
                setTotalItems(data.pagination.totalItems);
            } else {
                setError("No roles found");
            }
        } catch (err) {
            setError("Failed to fetch roles");
        } finally {
            setLoading(false);
        }
    };

    const deleteRole = async (id) => {
        try {
            await fetch(`${process.env.REACT_APP_BACKEND}/api/roles/${id}`, {
                method: "DELETE",
            });
            setSnackbarMessage("Role deleted successfully");
            setOpenSnackbar(true);
            fetchRoles();
        } catch (err) {
            setError("Failed to delete role");
        }
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setPage(0); // Reset to first page on search
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page on rows per page change
    };
    // const handleStatusToggle = (id, currentStatus) => {
    //     const newStatus = !currentStatus;
    //     updateTeacherStatus(id, newStatus);
    // };


    return (
        <Box>
            <h2>Roles</h2>
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" padding={3} gap={1}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        onChange={handleSearch}
                        value={searchTerm}
                        sx={{ width: "350px" }}
                    />
                    <Box flexGrow={1} />
                    <Button
                        component={Link}
                        to="/rolepermission"
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        sx={{ height: "auto", backgroundColor: "#f0f0f0" }}
                    >
                        Add Role
                    </Button>
                </Box>
            </Grid>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <b>ID</b>
                            </TableCell>
                            <TableCell>
                                <b>Role Name</b>
                            </TableCell>
                            <TableCell>
                                <b>Role Code</b>
                            </TableCell>
                            <TableCell>
                                <b>Status</b>
                            </TableCell>
                            <TableCell>
                                <b>Actions</b>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {roles.length > 0 ? (
                            roles.map((role, index) => (
                                <TableRow key={role._id}>
                                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                    <TableCell>{role.role_id}</TableCell>
                                    <TableCell>{role.role_name}</TableCell>
                                    <TableCell>
                                        <Switch
                                            checked={role.status}
                                            // onChange={() => handleStatusToggle(role._id, role.status)}
                                            color="primary"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            color="primary"
                                        // onClick={() => navigate(`/edit-role/${role._id}`)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => deleteRole(role._id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={6}>No roles available</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {totalItems > 10 && (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    padding={2}
                >
                    <TablePagination
                        component="div"
                        count={totalItems}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        labelRowsPerPage=""
                        showFirstButton
                        showLastButton
                    />
                </Box>
            )}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={error ? "error" : "success"}
                >
                    {error || snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default RolesListPage;
