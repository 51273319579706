import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import axios from 'axios';

function EditState({ isOpen, onClose, stateId, onUpdate }) {
  const [state, setState] = useState({ state_name: '', state_code: '' });
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    if (stateId) {
      fetchStateDetails(stateId);
    }
    fetchCountries();
  }, [stateId]);

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country`);
      setCountries(response.data.data);
    } catch (err) {
      console.error('Error fetching countries', err);
    }
  };

  const fetchStateDetails = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state/${id}`);
      setState(response.data.data);
      setSelectedCountry(response.data.data.country); // Set selectedCountry based on fetched state data
    } catch (error) {
      console.error('Failed to fetch state details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleSave = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/state/${stateId}`, {
        ...state,
        country: selectedCountry,
      });
      onUpdate();
    } catch (error) {
      console.error('Failed to update state:', error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Edit State</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="state_name"
          label="State Name"
          type="text"
          fullWidth
          variant="outlined"
          value={state.state_name}
          onChange={handleChange}
          disabled={loading}
        />
        <TextField
          margin="dense"
          name="state_code"
          label="State Code"
          type="text"
          fullWidth
          variant="outlined"
          value={state.state_code}
          onChange={handleChange}
          disabled={loading}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Country</InputLabel>
          <Select
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.target.value)}
            label="Country"
          >
            {countries.map((country) => (
              <MenuItem key={country._id} value={country._id}>
                {country.country_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditState;
