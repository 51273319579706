import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
} from '@mui/material';

const AddPlace = ({ isOpen, onClose, onAdd, countries, states, districts, towns }) => {
  const [placeName, setPlaceName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedTown, setSelectedTown] = useState('');

  const handleAddPlace = async () => {
    if (!placeName.trim() || !selectedCountry || !selectedState || !selectedDistrict || !selectedTown) {
      alert('Please fill in all required fields.');
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND}/api/place`, {
        place_name: placeName.trim(),
        country: selectedCountry,
        state: selectedState,
        district: selectedDistrict,
        town: selectedTown
      });
      onAdd(); // Refresh the list in PlaceList
      onClose(); // Close the modal
    } catch (err) {
      console.error('Failed to add place:', err.response?.data?.message || err.message);
      alert('Failed to add place. Please check your inputs and try again.');
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add New Place</DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Place Name"
          value={placeName}
          onChange={(e) => setPlaceName(e.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="Country"
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
        >
          {countries.map((country) => (
            <MenuItem key={country._id} value={country._id}>
              {country.country_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="State"
          value={selectedState}
          onChange={(e) => setSelectedState(e.target.value)}
          disabled={!selectedCountry}
        >
          {states.map((state) => (
            <MenuItem key={state._id} value={state._id}>
              {state.state_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="District"
          value={selectedDistrict}
          onChange={(e) => setSelectedDistrict(e.target.value)}
          disabled={!selectedState}
        >
          {districts.map((district) => (
            <MenuItem key={district._id} value={district._id}>
              {district.district_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          required
          fullWidth
          select
          label="Town"
          value={selectedTown}
          onChange={(e) => setSelectedTown(e.target.value)}
          disabled={!selectedDistrict}
        >
          {towns.map((town) => (
            <MenuItem key={town._id} value={town._id}>
              {town.Town_name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddPlace} color="primary">
          Add Place
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPlace;
