import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  Grid,
  TableRow,
  Paper,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddDistrict from './AddDistrict'; // Adjust the path as needed
import EditDistrict from './EditDistrict'; // Adjust the path as needed

const DistrictList = () => {
  const [districts, setDistricts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);

  useEffect(() => {
    fetchDistricts();
    fetchCountries();
    fetchStates();
  }, [page, rowsPerPage, searchTerm]);


  const fetchDistricts = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/district`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          searchTerm
        }
      });
      setDistricts(response.data.data);
      setTotalItems(response.data.pagination.totalItems);
    } catch (err) {
      setError('Error fetching districts');
      setOpenSnackbar(true);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/country`);
      setCountries(response.data.data);
    } catch (err) {
      setError('Error fetching countries');
      setOpenSnackbar(true);
    }
  };

  
  const fetchStates = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/state/without_pagination`);
      setStates(response.data.data);
      console.log("state:",response.data.data);
      
    } catch (err) {
      console.error('Failed to fetch states');
    }
  };

  const getCountryNameById = (id) => {
    const country = countries.find((country) => country._id === id);
    return country ? country.country_name : 'Unknown';
  };

  const getStateNameById = (id) => {
    const state = states.find((state) => state._id === id);
    return state ? state.state_name : 'Unknown';
  };

  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);

  const handleAddDistrict = () => {
    handleCloseAddModal();
    fetchDistricts();
  };
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleOpenEditModal = (id) => {
    setSelectedDistrictId(id);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => setOpenEditModal(false);

  const handleOpenDeleteDialog = (id) => {
    setSelectedDistrictId(id);
    setOpenDeleteDialog(true);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const handleDeleteDistrict = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/district/${id}`);
      setOpenDeleteDialog(false);
      fetchDistricts();
    } catch (err) {
      setError('Failed to delete district');
      setOpenSnackbar(true);
    }
  };

  return (
    <div>
      <h1>District Listing</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
        >
         Create
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>State Name</TableCell>
              <TableCell>District Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {districts.length > 0 ? (
              districts.map((district, index) => (
                <TableRow key={district._id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{getCountryNameById(district.country)}</TableCell>
                  <TableCell>{getStateNameById(district.state)}</TableCell>
                  <TableCell>{district.district_name}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => handleOpenEditModal(district._id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => handleOpenDeleteDialog(district._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No districts found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 0 && ( // Show pagination if there are any items
      <Grid item xs={12} sx={{ marginTop: 2 }}>
        <TablePagination
          component="div"
          count={totalItems}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage=""
          showFirstButton
          showLastButton
        />
     
        </Grid>
      )}
      {openEditModal && (
        <EditDistrict
          isOpen={openEditModal}
          onClose={handleCloseEditModal}
          onUpdate={fetchDistricts} // Correct prop name
          districtId={selectedDistrictId}
        />
      )}
      {openAddModal && (
        <AddDistrict
          isOpen={openAddModal}
          onClose={handleCloseAddModal}
          onAdd={handleAddDistrict}
        />
      )}
      {openDeleteDialog && (
        <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this district?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteDistrict(selectedDistrictId)}
              color="secondary"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DistrictList;
