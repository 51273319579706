import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, CircularProgress, Snackbar, Alert } from '@mui/material';
import axios from 'axios';

function EditProduct({ open, onClose, category, onUpdate }) {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (category) {
      setName(category.product_category_name);
    }
  }, [category]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/product/category/${category._id}`, { product_category_name: name });
      onUpdate(); // Callback to refresh the list
    } catch (err) {
      setError('Failed to update category');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Category</DialogTitle>
      <DialogContent>
        {error && (
          <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={() => setError(null)}>
            <Alert onClose={() => setError(null)} severity="error">
              {error}
            </Alert>
          </Snackbar>
        )}
        <TextField
          autoFocus
          margin="dense"
          label="Product Category Name"
          fullWidth
          variant="outlined"
          value={name}
          required
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditProduct;
