import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Button,
  Snackbar,
  Alert,
  TextField,
  Box,
  TablePagination,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import EditSubCategoryModal from './EditSubCategoryModal';
import AddSubCategoryModal from './AddSubCategoryModal';

function SubCategoryList() {
  const [subCategories, setSubCategories] = useState([]);
  const [partners, setPartners] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  useEffect(() => {
    fetchSubCategories();
  }, [page, rowsPerPage]);

  const fetchSubCategories = async () => {
    try {
      // Fetch subcategories
      const subCategoriesResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/sub_category`, {
        params: {
          page: page + 1,
          limit: rowsPerPage
        }
      });

      // Fetch partners
      const partnersResponse = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/category`);

      // Set partners and subcategories
      setPartners(partnersResponse.data.data);
      setSubCategories(subCategoriesResponse.data.data);
      setTotalItems(subCategoriesResponse.data.pagination.totalItems);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Error fetching data");
      setOpenSnackbar(true);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleOpenDeleteDialog = (id) => {
    setSelectedSubCategoryId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setSelectedSubCategoryId(null);
  };

  const handleDeleteSubCategory = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/partner/sub_category/${id}`);
      setSnackbarMessage('SubCategory deleted successfully');
      setOpenSnackbar(true);
      fetchSubCategories(); // Refresh the list after deleting
    } catch (err) {
      setSnackbarMessage('Failed to delete subcategory');
      setOpenSnackbar(true);
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleOpenEditModal = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/partner/sub_category/${id}`);
      setSelectedSubCategory(response.data.data);
      setSelectedSubCategoryId(id);
      setOpenEditModal(true);
    } catch (err) {
      setError('Error fetching subcategory details');
      setOpenSnackbar(true);
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedSubCategoryId(null);
    setSelectedSubCategory(null);
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleAddSubCategory = () => {
    handleCloseAddModal();
    fetchSubCategories(); // Refresh the list after adding
  };

  const handleStatusToggle = (id, currentStatus) => {
    const newStatus = !currentStatus;
    updateSubCategoryStatus(id, newStatus); // Update status in the backend
  };

  const updateSubCategoryStatus = async (id, status) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/partner/sub_category/${id}/status_subcategorypartner`, {
        status
      });
      setSnackbarMessage('SubCategory status updated successfully');
      setOpenSnackbar(true);
      fetchSubCategories(); // Refresh the list after status change
    } catch (err) {
      setError('Failed to update SubCategory status');
      setOpenSnackbar(true);
    }
  };

  return (
    <div>
      <h1>Partner SubCategory Listing</h1>
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleOpenAddModal}
        >
          Add SubCategory
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Serial No.</TableCell>
              <TableCell>SubCategory Name</TableCell>
              <TableCell>Category Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subCategories.length > 0 ? (
              subCategories
                .filter(subCategory => subCategory.SubCategory_name?.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((subCategory, index) => {
                  // Find partner name using partner_id
                  const partner = partners.find(p => p._id === subCategory.partner_id);
                  const partnerName = partner ? partner.partner_name : 'No Partner'; // Fallback if partner not found

                  return (
                    <TableRow key={subCategory._id}>
                      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                      <TableCell>{subCategory.SubCategory_name}</TableCell>
                      <TableCell>{partnerName}</TableCell> {/* Display partner name */}
                      <TableCell>
                        <Switch
                          checked={subCategory.status}
                          onChange={() => handleStatusToggle(subCategory._id, subCategory.status)}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleOpenEditModal(subCategory._id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => handleOpenDeleteDialog(subCategory._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No subcategories found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 5 && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Grid>
      )}
      <AddSubCategoryModal open={openAddModal} onClose={handleCloseAddModal} onAdd={handleAddSubCategory} />
      {selectedSubCategoryId && (
        <EditSubCategoryModal
          open={openEditModal}
          onClose={handleCloseEditModal}
          category={selectedSubCategory}
          onUpdate={() => {
            handleCloseEditModal();
            fetchSubCategories(); // Refresh the list after updating
          }}
        />
      )}
      {selectedSubCategoryId && (
        <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this subcategory?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteSubCategory(selectedSubCategoryId)}
              color="secondary"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={error ? "error" : "success"}>
          {error || snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SubCategoryList;
