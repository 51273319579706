import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
  IconButton, Switch, CircularProgress, Container, Typography, Box, MenuItem, Grid,
  TablePagination
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import axios from 'axios';

const TeacherSubCategoryList = () => {
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentSubCategory, setCurrentSubCategory] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [teacherCategories, setTeacherCategories] = useState([]);

  // Fetch teacher categories
  const fetchTeacherCategories = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/teachers/category`);
      setTeacherCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching teacher categories", error);
    }
  }, []);

  // Fetch sub-categories
  const fetchSubCategories = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/teachers/sub_category`, {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          search: searchTerm,
        }
      });
      setSubCategories(response.data.data);
      setTotalItems(response.data.pagination.totalItems);
    } catch (error) {
      console.error("Error fetching sub-categories", error);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, searchTerm]);

  useEffect(() => {
    fetchTeacherCategories();
    fetchSubCategories();
  }, [page, rowsPerPage, searchTerm]);

  // Handlers for dialog and form operations
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleSave = async () => {
    try {
      if (isEdit) {
        await axios.put(`${process.env.REACT_APP_BACKEND}/api/teachers/sub_category/${currentSubCategory._id}`, currentSubCategory);
      } else {
        await axios.post(`${process.env.REACT_APP_BACKEND}/api/teachers/sub_category`, currentSubCategory);
      }
      fetchSubCategories();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving sub-category", error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND}/api/teachers/sub_category/${deleteId}`);
      fetchSubCategories();
      handleCloseDeleteDialog();
    } catch (error) {
      console.error("Error deleting sub-category", error);
    }
  };

  const handleToggleStatus = async (id, status) => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/teachers/sub_category/${id}`, { status: status === 1 ? 0 : 1 });
      fetchSubCategories();
    } catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const handleOpenDialog = (subCategory) => {
    setCurrentSubCategory(subCategory || {});
    setIsEdit(!!subCategory);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setCurrentSubCategory({});
    setOpenDialog(false);
  };

  const handleOpenDeleteDialog = (id) => {
    setDeleteId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteId(null);
    setOpenDeleteDialog(false);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Memoize the filtered subCategories to avoid unnecessary re-renders
  const filteredSubCategories = useMemo(() => 
    subCategories.filter(subCategory => subCategory.teacher_name.toLowerCase().includes(searchTerm.toLowerCase())),
    [subCategories, searchTerm]
  );

  // Filter teacher categories to include only those with status === 1
  const activeTeacherCategories = useMemo(() => 
    teacherCategories.filter(category => category.status === 1),
    [teacherCategories]
  );

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4" gutterBottom>
          Teacher Sub Categories
        </Typography>
        <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
          Add Teacher Sub Category
        </Button>
      </Box>

      <Box mb={3}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          onChange={handleSearch}
          value={searchTerm}
          sx={{ width: '300px' }}
        />
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial No.</TableCell>
                <TableCell>Teacher Sub Category</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSubCategories.length > 0 ? (
                filteredSubCategories.map((subCategory, index) => (
                  <TableRow key={subCategory._id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{subCategory.teacher_name}</TableCell>
                    <TableCell>{activeTeacherCategories.find(cat => cat._id === subCategory.teacher_category_id)?.teacher_name || 'Unknown'}</TableCell>
                    <TableCell>
                      <Switch
                        checked={subCategory.status === 1}
                        onChange={() => handleToggleStatus(subCategory._id, subCategory.status)}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenDialog(subCategory)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => handleOpenDeleteDialog(subCategory._id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No sub-categories found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {totalItems > rowsPerPage && (
        <Grid item xs={12} sx={{ marginTop: 2 }}>
          <TablePagination
            component="div"
            count={totalItems}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rows per page"
            showFirstButton
            showLastButton
          />
        </Grid>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{isEdit ? 'Edit Teacher Sub Category' : 'Add Teacher Sub Category'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Teacher Sub Category Name"
            fullWidth
            margin="normal"
            value={currentSubCategory.teacher_name || ''}
            onChange={(e) =>
              setCurrentSubCategory({ ...currentSubCategory, teacher_name: e.target.value })
            }
          />
          <TextField
            select
            label="Select Category"
            fullWidth
            margin="normal"
            value={currentSubCategory.teacher_category_id || ''}
            onChange={(e) =>
              setCurrentSubCategory({ ...currentSubCategory, teacher_category_id: e.target.value })
            }
          >
            {activeTeacherCategories.map((category) => (
              <MenuItem key={category._id} value={category._id}>
                {category.teacher_name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            {isEdit ? 'Update' : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this teacher sub category?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TeacherSubCategoryList;
