import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Snackbar,
  Alert,
  Container,
  Grid,
  Typography,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { getToken } from '../../../utils/AuthUtils';

const AssessmentForm = () => {
  const [courseCategories, setCourseCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [courses, setCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [filteredChapters, setFilteredChapters] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedChapter, setSelectedChapter] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');
  const [units, setUnits] = useState([]); // State for units
const [filteredUnits, setFilteredUnits] = useState([]); // State for filtered units

  const [unit, setUnit] = useState('');
  const [assessmentTitle, setAssessmentTitle] = useState('');
  const [totalScore, setTotalScore] = useState('');
  const [expireTime, setExpireTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [questions, setQuestions] = useState([{ 
    question_text: '', 
    question_type: 'mcq', 
    points: '', 
    options: ['', '', '', ''], 
    answer: '' 
  }]);

  const navigate = useNavigate();

  const fetchData = async (url, params = {}, headers = {}) => {
    setLoading(true);
    try {
      const response = await axios.get(url, { params, headers });
      return response.data.data || [];
    } catch (err) {
      setError(`Failed to fetch data from ${url}`);
      return [];
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    const fetchCourseCategories = async () => {
      const data = await fetchData(`${process.env.REACT_APP_BACKEND}/api/courses_category`);
      setCourseCategories(data.filter(category => category.status === 1));
    };

    fetchCourseCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchData(`${process.env.REACT_APP_BACKEND}/api/courses_category_sub`, { category_id: selectedCategory })
        .then(data => {
          const filtered = data.filter(subCategory => subCategory.status === 1);
          setSubCategories(filtered);
          setFilteredSubCategories(filtered); // Set filtered subcategories
        });
    } else {
      setSubCategories([]);
      setFilteredSubCategories([]);
    }
  }, [selectedCategory]);
  
  // const token = getToken();
  // console.log("Token:", token);
  useEffect(() => {
    const fetchCourses = async () => {
      if (selectedSubcategory) {
        
  
        try {
          const token = getToken();
          if (token) {
            const data = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses/with_out_paginatioin`, {
              headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json',
              },
              params: {
                  subcategory_id: selectedSubcategory
              }
          });
          
          setCourses(data.data.data);
          console.log("courses fetched:",data)
        } }catch (error) {
          console.error("Error fetching courses:", error);
          setError("Failed to fetch courses.");
        }
      }
    };
  
    fetchCourses();
  }, [selectedSubcategory]);
  

  useEffect(() => {
    if (selectedCourse) {
      fetchData(`${process.env.REACT_APP_BACKEND}/api/chapters`, { course_id: selectedCourse })
        .then(data => {
          const filtered = data.filter(chapter => chapter.status === 1);
          setChapters(filtered);
          setFilteredChapters(filtered);
        });
    } else {
      setChapters([]);
      setFilteredChapters([]);
    }
  }, [selectedCourse]);

  // Fetch Units
useEffect(() => {
  const fetchUnits = async () => {
    if (selectedChapter) {
      const data = await fetchData(`${process.env.REACT_APP_BACKEND}/api/list/chapter/${selectedChapter}`);
      const filtered = data
      setUnits(data);
      setFilteredUnits(filtered); // Set filtered units
    } else {
      setUnits([]);
      setFilteredUnits([]);
    }
  };

  fetchUnits();
}, [selectedChapter]);

  // Filter subcategories based on the selected category
  useEffect(() => {
    if (selectedCategory) {
      const filtered = subCategories.filter(subcategory =>
        subcategory.course_category_id === selectedCategory
      );
      setFilteredSubCategories(filtered);
    }
  }, [selectedCategory, subCategories]);

  // Filter courses based on the selected subcategory
  useEffect(() => {
    if (selectedSubcategory) {
      const filtered = courses.filter(course =>
        course.course_subcategory_id === selectedSubcategory
      );
      setFilteredCourses(filtered);
    }
  }, [selectedSubcategory, courses]);
    // Filter chapters based on the selected courses
    useEffect(() => {
      if (selectedCourse) {
        const filtered = chapters.filter(chapter =>
          chapter.course_id === selectedCourse
        );
        setFilteredChapters(filtered);
      }
    }, [selectedCourse, chapters]);
 // Filter Unit based on the selected courses
 useEffect(() => {
  if (selectedUnit) {
    const filtered = units.filter(unit =>
      unit.unitDetails.chapter_id === selectedChapter
    );
    setFilteredUnits(filtered);
  }
}, [selectedCourse, units]);

  // useEffect(() => {
  //   if (selectedCourse) {
  //     fetchData(`${process.env.REACT_APP_BACKEND}/api/chapters`, { course_id: selectedCourse })
  //       .then(data => setChapters(data));
  //   } else {
  //     setChapters([]);
  //   }
  // }, [selectedCourse]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation for main fields
    if (!assessmentTitle || !selectedCategory || !selectedSubcategory || !selectedCourse || !selectedChapter || !units || !totalScore) {
      setError("Please fill out all required fields.");
      return;
    }

    // Validation for questions
    if (questions.length === 0 || questions.some(question => !question.question_text || !question.question_type || !question.points )) {
      setError("Please add at least one complete question with all required fields.");
      return;
    }

    const assessmentData = {
      category_id: selectedCategory,
      subcategory_id: selectedSubcategory,
      course_id: selectedCourse,
      chapter_id: selectedChapter,
      unit: selectedUnit, 
      assessment_title: assessmentTitle,
      total_score: totalScore,
      expire_time: expireTime,
    };

    setLoading(true);

    try {
      const assessmentResponse = await axios.post(`${process.env.REACT_APP_BACKEND}/api/assessment`, assessmentData);
      if (assessmentResponse.data) {
        const assessmentId = assessmentResponse.data.data._id; 
        
        const questionsData = questions.map(question => ({
          assessment_id: assessmentId, 
          question_text: question.question_text.trim(), 
          question_type: question.question_type,
          points: Number(question.points),
          options: question.question_type === 'yes/no' ? [question.options.yes ? 'Yes' : '', question.options.no ? 'No' : ''] : question.options.map(option => option.trim()),
          answer: question.answer.trim() 
        }));

        // Check for empty fields in questions data
        const hasEmptyFields = questionsData.some(q => !q.question_text || !q.question_type || !q.points );
        
        if (hasEmptyFields) {
          setError("Some questions have missing required fields. Please fill them out completely.");
          return; 
        }
        
        await axios.post(`${process.env.REACT_APP_BACKEND}/api/assessment_question`, questionsData);
        
        setSuccess("Assessment and questions added successfully!");
        resetForm();
        navigate("/partner/assessment_list");
      } else {
        setError(assessmentResponse.data.message || "Failed to add assessment.");
      }
    } catch (err) {
      console.error(err);
      setError("Failed to add assessment or questions.");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setAssessmentTitle("");
    setSelectedCategory("");
    setSelectedSubcategory("");
    setSelectedCourse("");
    setSelectedChapter("");
    setUnit("");
    setTotalScore("");
    setQuestions([{ question_text: '', question_type: 'mcq', points: '', options: ['', '', '', ''], answer: '' }]);
  };

  const handleSnackbarClose = () => {
    setSuccess(null);
    setError(null);
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newQuestions = [...questions];
    
    if (name.startsWith('option_')) {
      const optionIndex = parseInt(name.split('_')[1]) - 1;
      newQuestions[index].options[optionIndex] = value;
    } else if (name === 'question_type') {
      newQuestions[index][name] = value;

      // Reset options for "yes/no" question type
      if (value === 'yes/no') {
        newQuestions[index].options = { yes: false, no: false }; // Reset to boolean
      } else {
        newQuestions[index].options = ['', '', '', '']; // Reset for other types
      }
    } else {
      newQuestions[index][name] = value;
    }
    
    setQuestions(newQuestions);
  };

  const handleYesNoChange = (index, option, checked) => {
    const newQuestions = [...questions];
    newQuestions[index].options[option] = checked;
    setQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { question_text: '', question_type: 'mcq', points: '', options: ['', '', '', ''], answer: '' }]);
  };

  const handleRemoveQuestion = (index) => {
    if (questions.length > 1) {
      setQuestions(questions.filter((_, i) => i !== index));
    }
  };

  return (
    <Container maxWidth="xl" sx={{ padding: '20px', borderRadius: '8px' }}>
      <Typography variant="h4" gutterBottom>
        Create Assessment
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Course Category</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  label="Course Category"
                  required
                >
                  <MenuItem value="">Select Category</MenuItem>
                  {courseCategories.map(category => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.courses_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Course Sub-Category</InputLabel>
                <Select
                  value={selectedSubcategory}
                  onChange={(e) => setSelectedSubcategory(e.target.value)}
                  label="Course Sub-Category"
                  required
                  disabled={!selectedCategory}
                >
                  <MenuItem value="">Select Sub-Category</MenuItem>
                  {filteredSubCategories.map(subCategory => (
                    <MenuItem key={subCategory._id} value={subCategory._id}>
                      {subCategory.courses_sub_category_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Course</InputLabel>
                <Select
                  value={selectedCourse}
                  onChange={(e) => setSelectedCourse(e.target.value)}
                  label="Course"
                  required
                  disabled={!selectedSubcategory}
                >
                  <MenuItem value="">Select Course</MenuItem>
                  {filteredCourses.map(course => (
                    <MenuItem key={course._id} value={course._id}>
                      {course.courses_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <InputLabel>Chapter</InputLabel>
                <Select
                  value={selectedChapter}
                  onChange={(e) => setSelectedChapter(e.target.value)}
                  label="Chapter"
                  required
                  disabled={!selectedCourse}
                >
                  <MenuItem value="">Select Chapter</MenuItem>
                  {filteredChapters.map(chapter => (
                    <MenuItem key={chapter._id} value={chapter._id}>
                      {chapter.chapter_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

          <Grid item xs={12}>
  <FormControl fullWidth variant="outlined" margin="normal">
    <InputLabel>Unit</InputLabel>
    <Select
      value={selectedUnit}
      onChange={(e) => setSelectedUnit(e.target.value)}
      label="Unit"
      required
      disabled={!selectedChapter} // Only enable if a chapter is selected
    >
      <MenuItem value="">Select Unit</MenuItem>
      {units.map(unit => (
        <MenuItem key={unit._id} value={unit._id}>
          {unit.unit_name} {/* Adjust to the correct property for unit name */}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Assessment Title"
                value={assessmentTitle}
                onChange={(e) => setAssessmentTitle(e.target.value)}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Total Score"
                type="number"
                value={totalScore}
                onChange={(e) => setTotalScore(e.target.value)}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Expire Time"
                type="number"
                value={expireTime}
                onChange={(e) => setExpireTime(e.target.value)}
                variant="outlined"
                fullWidth
                required
              />
            </Grid>
          </Grid>

          <Typography variant="h5" gutterBottom>
            Questions
          </Typography>

          {questions.map((question, index) => (
            <Box key={index} sx={{ border: '1px solid #ccc', padding: '16px', marginBottom: '16px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    name="question_text"
                    label="Question Text"
                    value={question.question_text}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="question_type"
                    label="Question Type"
                    select
                    value={question.question_type}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                    required
                  >
                    <MenuItem value="mcq">MCQ</MenuItem>
                    <MenuItem value="descriptive">Text</MenuItem>
                    <MenuItem value="yes/no">Yes/No</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="points"
                    label="Points"
                    type="number"
                    value={question.points}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                    required
                  />
                </Grid>

                {question.question_type === 'mcq' && question.options.length > 0 ? (
        question.options.map((option, idx) => (
          <Grid item xs={12} key={idx}>
            <TextField
              name={`option_${idx + 1}`} 
              label={`Option ${idx + 1}`} 
              variant="outlined"
              value={option}
              onChange={(e) => handleChange(index, e)}
              fullWidth
              required={idx < 2} 
            />
           </Grid>
          
        ))
        
      ) : (
        <Grid item xs={12}>
          {/* <Typography variant="body1">Options: null</Typography> */}
        </Grid>
      )}
        
{question.question_type === 'yes/no' && (
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Answer</InputLabel>
            <Select
              name="answer"
              value={question.answer}
              onChange={(e) => handleChange(index, { target: { name: 'answer', value: e.target.value } })}
              label="Answer"
            >
              <MenuItem value="">Select Answer</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}


             {( question.question_type != 'yes/no' && <Grid item xs={12}>
                  <TextField
                    name="answer"
                    label="Answer"
                    value={question.answer}
                    onChange={(e) => handleChange(index, e)}
                    fullWidth
                  />
                </Grid> )}

                <Grid item xs={12}>
                  <IconButton onClick={() => handleRemoveQuestion(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          ))}

          <Button onClick={handleAddQuestion} variant="contained" color="primary">
            Add Question
          </Button>

          <Button type="submit" variant="contained" color="primary" sx={{ marginTop: '20px' }}>
            Submit
          </Button>
        </form>
      )}

      <Snackbar open={!!success} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AssessmentForm;
