import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';

function EditCategoryModal({ open, onClose, category, onUpdate }) {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'

  useEffect(() => {
    if (category) {
      setName(category.partner_name);
    }
  }, [category]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND}/api/partner/category/${category._id}`, { partner_name: name });
      if (response.status === 200) {
        onUpdate(); // Callback to refresh the list
        setSnackbarMessage('Category updated successfully');
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage('Failed to update category');
        setSnackbarSeverity('error');
      }
    } catch (err) {
      console.error(err); // Log the error for debugging
      setSnackbarMessage('Failed to update category');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setOpenSnackbar(true); // Open the Snackbar
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    setSnackbarMessage(''); // Reset the message on close
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Edit Category</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Category Name"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default EditCategoryModal;
