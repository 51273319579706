import React, { useState, useEffect } from 'react'; 
import axios from 'axios';
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Switch
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/AuthUtils';

const CourseListMain = () => {
  const [courses, setCourses] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [courseToDelete, setCourseToDelete] = useState(null);
  const [expandedCourseId, setExpandedCourseId] = useState(null); // Track expanded course

  const navigate = useNavigate();

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department`);
        setDepartments(response.data.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    const fetchCourses = async () => {
      setIsLoading(true);
      try {
        const token = getToken();
        if (token) {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/assign_course_to_partner/getassigned_courses_by_partner`, {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            searchTerm,
          },
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setCourses(response.data.data);
        console.log("courses with current user:",response.data.data);
        
        setTotalItems(response.data.pagination.totalItems);
      }
     } catch (err) {
        console.error('Error fetching courses:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDepartments();
    fetchCourses();
  }, [page, rowsPerPage, searchTerm]);

  const handleToggleStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    try {
      const token = getToken();
      if (token) {
      await axios.put(`${process.env.REACT_APP_BACKEND}/api/courses/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        status: newStatus });
      setCourses(prevCourses => prevCourses.map(course =>
        course._id === id ? { ...course, status: newStatus } : course
        
      ));
    } }catch (error) {
      console.error("Error toggling status", error);
    }
  };

  const getDepartmentName = (departmentId) => {
    const department = departments.find(dep => dep._id === departmentId);
    return department ? department.department_name : 'Unknown';
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const handleAddCourse = () => {
    navigate('/partner/courses/add');
  };

  const handleEditCourse = (id) => {
    navigate(`/partner/courses/edit/${id}`);
  };

  const handleDeleteCourse = async () => {
    if (courseToDelete) {
      try {
        const token = getToken();
      if (token) {
        await axios.delete(`${process.env.REACT_APP_BACKEND}/api/courses/${courseToDelete}`,{
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setCourseToDelete(null);
        setIsDialogOpen(false);
        // Refresh the list after deletion
        const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/courses/current-user`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          params: {
            page: page + 1,
            limit: rowsPerPage,
            searchTerm,
          },
        });
        setCourses(response.data.data);
        setTotalItems(response.data.pagination.totalItems);
      } }catch (err) {
        console.error('Error deleting course:', err);
      }
    }
  };

  const openDeleteDialog = (id) => {
    setCourseToDelete(id);
    setIsDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDialogOpen(false);
    setCourseToDelete(null);
  };

  const toggleExpand = (id) => {
    setExpandedCourseId(expandedCourseId === id ? null : id);
  };

  return (
    <div>
      <h1>Courses</h1>
      <Box display="flex" flexDirection="column" padding={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={handleSearch}
            value={searchTerm}
            sx={{ width: '300px' }}
          />
          {/* <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddCourse}
          >
          Create
          </Button> */}
        </Box>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial No.</TableCell>
                  <TableCell>Course Name</TableCell>
                  <TableCell>Course Thumbnail</TableCell>
                  <TableCell>Course Image</TableCell>
                  <TableCell>Details</TableCell>
                  <TableCell>No. Of Chapters</TableCell>
                  <TableCell>Department</TableCell>
                  {/* <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {courses.length > 0 ? (
                  courses
                    .filter(course => course.course_id.courses_name.toLowerCase().includes(searchTerm.toLowerCase()))
                    .map((course, index) => (
                      <TableRow key={course.course_id._id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{course.course_id.courses_name}</TableCell>
                        <TableCell>
                          {course.course_id.course_thumbnail ? (
                            <img
                              src={course.course_id.course_thumbnail}
                              alt="Course Thumbnail"
                              style={{ width: 50, height: 50, objectFit: 'cover' }}
                            />
                          ) : (
                            'No image'
                          )}
                        </TableCell>
                        <TableCell>
                          {course.course_id.course_image ? (
                            <img
                              src={course.course_id.course_image}
                              alt="Course Image"
                              style={{ width: 50, height: 50, objectFit: 'cover' }}
                            />
                          ) : (
                            'No Photo'
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {expandedCourseId === course._id ? (
                              <span>
                                {course.course_id.course_details}
                                <Button onClick={() => toggleExpand(course._id)} size="small">
                                  Show Less
                                </Button>
                              </span>
                            ) : (
                              <span>
                                {course.course_id.course_details.length > 100
                                  ? `${course.course_id.course_details.slice(0, 100)}...`
                                  : course.course_id.course_details}
                                {course.course_id.course_details.length > 100 && (
                                  <Button onClick={() => toggleExpand(course._id)} size="small">
                                    Read More
                                  </Button>
                                )}
                              </span>
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>{course.course_id.no_of_chapters}</TableCell>
                        <TableCell>{getDepartmentName(course.course_id.department_id)}</TableCell>
                        {/* <TableCell>
                          <Switch
                            checked={course.status === 1}
                            onChange={() => handleToggleStatus(course._id, course.status)}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton color="primary" onClick={() => handleEditCourse(course._id)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton color="error" onClick={() => openDeleteDialog(course._id)}>
                            <DeleteIcon />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No courses found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
       {totalItems >= 10 &&( <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />)}
      </Box>

      {/* Confirmation Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={closeDeleteDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this course?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button
            color="error"
            onClick={handleDeleteCourse}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CourseListMain;
