import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';

function AddStudentSub({ open, onClose, onAdd }) {
  const [partnerName, setPartnerName] = useState('');
  const [categories, setCategories] = useState([]); // Should be for storing category options
  const [selectedCategory, setSelectedCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/student/category`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        console.log("Fetched categories:", result.data); // Log data to check its structure

        // Ensure data is an array and set it to the state
        if (Array.isArray(result.data)) {
          setCategories(result.data);
        } else {
          console.error('Unexpected data format:', result.data);
        }
      } catch (error) {
        console.error('Failed to fetch categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handlePartnerChange = (e) => {
    setPartnerName(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handlePartnerSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/api/student/category_sub`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          student_sub_category_name: partnerName,
          student_id: selectedCategory // Send the MongoDB ID here
        }),
      });

      if (response.ok) {
        setSnackbarMessage('Student added successfully');
        onAdd(); // Notify parent to refresh data
        onClose(); // Close the modal
      } else {
        setSnackbarMessage('Failed to add Student');
      }

      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage('Error occurred');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Sub-Category</DialogTitle>
      <DialogContent>
        <form onSubmit={handlePartnerSubmit}>
        <FormControl fullWidth margin="normal" variant="outlined" required>
            <InputLabel>Category</InputLabel>
            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              label="Category"
            >
              {categories.filter(category => category.status === 1).length > 0 ? (
                categories
                  .filter(category => category.status === 1) // Filter categories with status 1
                  .map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.Student_category_name} {/* Ensure this property matches your API response */}
                    </MenuItem>
                  ))
              ) : (
                <MenuItem disabled>No Categories available</MenuItem>
              )}
            </Select>
          </FormControl>
          <TextField
            label="Student Sub Category Name"
            value={partnerName}
            onChange={handlePartnerChange}
            fullWidth
            margin="normal"
            variant="outlined"
            required
          />
      
          <Box display="flex" justifyContent="center" marginTop={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary">
                Add
              </Button>
            )}
          </Box>
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarMessage === 'Student added successfully' ? 'success' : 'error'}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddStudentSub;
