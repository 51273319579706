import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
 
const PdfList = () => {
  const [pdfs, setPdfs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [openDialog, setOpenDialog] = useState(false);
  const [unitToDelete, setUnitToDelete] = useState(null);
  const navigate = useNavigate();
 
  const fetchPdfs = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/list/unit/?page=${currentPage}&limit=${itemsPerPage}`);
      setPdfs(response.data.data);
      setTotalPages(response.data.pagination.totalPages);
    } catch (err) {
      setError('Error fetching PDFs');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
 
  useEffect(() => {
   
    fetchPdfs();
  }, [currentPage, itemsPerPage]);
 
  const handleDeleteConfirmation = (unit) => {
    setUnitToDelete(unit);
    setOpenDialog(true);
  };
 
  const handleDelete = async () => {
    if (!unitToDelete) return;
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND}/unitupload/deleteunit`, {
        unit_id: unitToDelete.units_id,
        id: unitToDelete._id,
      });
      setOpenDialog(false);
      fetchPdfs();
    } catch (err) {
      setError('Failed to delete Unit');
      console.error(err);
    }
  };
 
  const handleView = async (id) => {
    navigate(`/unitinterface/${id}`);
  };
  const handleEdit = async (id) => {
    navigate(`/editunit/${id}`);
  };
 
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
 
  return (
    <div>
      <h1>Unit List</h1>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Serial No.</TableCell>
                  <TableCell>Unit Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pdfs.length > 0 ? (
                  pdfs.map((pdf, index) => (
                    <TableRow key={pdf._id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{pdf.unitDetails ? pdf.unitDetails.unit_name : 'N/A'}</TableCell>
                      <TableCell>
                        <IconButton color="error" onClick={() => handleView(pdf.units_id)}>
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => handleDeleteConfirmation(pdf)}>
                          <DeleteIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => handleEdit(pdf.units_id)}>
                        <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">No PDFs found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {pdfs.length >=25  && (
            <TablePagination
              rowsPerPageOptions={[25, 50, 75, 100]}
              component="div"
              count={pdfs.length}
              rowsPerPage={itemsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      )}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the unit "{unitToDelete?.unitDetails?.unit_name}"? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">Cancel</Button>
          <Button onClick={handleDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
 
export default PdfList;
 
 