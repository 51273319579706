import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    TablePagination,
    CircularProgress,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Switch,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const LabnamesList = () => {
    const [Labnames, setLabnames] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);
    const navigate = useNavigate();

    // Fetch departments from the backend
    const fetchDepartments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/department`);
            if (response.status === 200 && response.data) {
                setDepartments(response.data.data);
            } else {
                setError('No departments available');
            }
        } catch (err) {
            setError('Failed to fetch departments.');
            console.error(err);
        }
    };

    // Fetch lab names
    const fetchLabnames = async () => {
        setLoading(true);
        setError(''); // Clear previous error messages
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND}/api/labnames`,{
                params:{
                    page: page + 1,
                    limit: rowsPerPage,
                    search: searchTerm
                  }
            });
            if (response.status === 200 && response.data) {
                setLabnames(response.data.data);
                setTotalItems(response.data.data.length);
            } else {
                setError('No lab names available');
            }
        } catch (err) {
            setError('Failed to fetch lab names.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDepartments();  // Fetch departments when the component mounts
        fetchLabnames();
    }, [page, rowsPerPage, searchTerm]);

    const handleAddCategory = () => {
        navigate('/labnames/add');
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setPage(0); // Reset to first page when searching
      };
    const handleStatusToggle = (id, currentStatus) => {
        const newStatus = !currentStatus;
        updateLabnameStatus(id, newStatus); // Update status in the backend
    };

    const updateLabnameStatus = async (id, status) => {
        try {
            await axios.put(`${process.env.REACT_APP_BACKEND}/api/labnames/${id}/status`, {
                status,
            });
            fetchLabnames(); // Refresh the list after status change
        } catch (err) {
            setError('Failed to update lab name status');
            console.error(err);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleEditCategory = (id) => {
        navigate(`/labnames/edit/${id}`);
    };

    const handleDeleteClick = (id) => {
        setCategoryIdToDelete(id);
        setOpen(true);
    };

    const handleDeleteCategory = async () => {
        if (categoryIdToDelete) {
            try {
                await axios.delete(`${process.env.REACT_APP_BACKEND}/api/labnames/${categoryIdToDelete}`);
                fetchLabnames(); // Refresh the list after deletion
            } catch (err) {
                setError('Error deleting lab name');
                console.error(err);
            } finally {
                setOpen(false); // Close the dialog
                setCategoryIdToDelete(null); // Clear the ID
            }
        }
    };

    const handleClose = () => {
        setOpen(false);
        setCategoryIdToDelete(null); // Clear the ID
    };

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <div>
            <h1>Lab Names</h1>
            <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
                <TextField
                    label="Search"
                    variant="outlined"
                    size="small"
                    onChange={handleSearch}
                    value={searchTerm}
                    sx={{ width: '300px' }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddCategory}
                >
                    Create
                </Button>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Serial No.</TableCell>
                            <TableCell>Lab Name</TableCell>
                            <TableCell>Department</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Labnames.length > 0 ? (
                            Labnames
                            .filter(labname => labname.lab_name.toLowerCase().includes(searchTerm.toLowerCase()))

                            .map((labname, index) => {
                                const department = departments.find(dept => dept._id === labname.department);
                                return (
                                    <TableRow key={labname._id}>
                                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                        <TableCell>{labname.lab_name}</TableCell>
                                        <TableCell>{department ? department.department_name : 'N/A'}</TableCell>
                                        <TableCell>
                                            <Switch
                                                checked={labname.status}
                                                onChange={() => handleStatusToggle(labname._id, labname.status)}
                                                color="primary"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton color="primary" onClick={() => handleEditCategory(labname._id)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton color="error" onClick={() => handleDeleteClick(labname._id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    No lab names found
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {totalItems >= 10 && (
                <TablePagination
                    component="div"
                    count={totalItems}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Rows per page"
                    showFirstButton
                    showLastButton
                />
            )}
            {/* Confirmation Dialog */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this lab name? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteCategory} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default LabnamesList;
